import { ApiFile } from "ts/types/file"

export enum GlobalEvents {
  CONVERTER_FILE_ANON_UPLOADED = 'pdfmaster:converter:anon:file:uploaded',
  CONVERTER_FILE_ANON_UPLOAD_ERROR = 'pdfmaster:converter:anon:file:upload-error',

  CONVERTER_FILE_USER_UPLOADED = 'pdfmaster:converter:user:file:uploaded',
  CONVERTER_FILE_USER_UPLOAD_ERROR = 'pdfmaster:converter:user:file:upload-error',

  DELETE_FILE_SUCCESS = 'pdfmaster:file:delete:success',
  DELETE_FILE_ERROR = 'pdfmaster:file:delete:error',

  EDIT_FILE_ANON_UPLOADED = 'pdfmaster:edit:anon:file:uploaded',
  EDIT_FILE_ANON_UPLOAD_ERROR = 'pdfmaster:edit:anon:file:upload-error',

  EDIT_FILE_USER_UPLOADED = 'pdfmaster:edit:user:file:uploaded',
  EDIT_FILE_USER_UPLOAD_ERROR = 'pdfmaster:edit:user:file:upload-error',

  OPTIMIZE_FILE_ANON_UPLOADED = 'pdfmaster:optimize:anon:file:uploaded',
  OPTIMIZE_FILE_ANON_UPLOAD_ERROR = 'pdfmaster:optimize:anon:file:upload-error',

  OPTIMIZE_FILE_USER_UPLOADED = 'pdfmaster:optimize:user:file:uploaded',
  OPTIMIZE_FILE_USER_UPLOAD_ERROR = 'pdfmaster:optimize:user:file:upload-error',

  SPLIT_FILE_ANON_UPLOADED = 'pdfmaster:split:anon:file:uploaded',
  SPLIT_FILE_ANON_UPLOAD_ERROR = 'pdfmaster:split:anon:file:upload-error',

  SPLIT_FILE_USER_UPLOADED = 'pdfmaster:split:user:file:uploaded',
  SPLIT_FILE_USER_UPLOAD_ERROR = 'pdfmaster:split:user:file:upload-error',

  MERGE_FILE_ANON_UPLOADED = 'pdfmaster:merge:anon:file:uploaded',
  MERGE_FILE_ANON_UPLOAD_ERROR = 'pdfmaster:merge:anon:file:upload-error',

  MERGE_FILE_USER_UPLOADED = 'pdfmaster:merge:user:file:uploaded',
  MERGE_FILE_USER_UPLOAD_ERROR = 'pdfmaster:merge:user:file:upload-error',

  FILE_ANON_UPLOADED = 'pdfmaster:merge:anon:file:uploaded',
  FILE_ANON_UPLOAD_ERROR = 'pdfmaster:merge:anon:file:upload-error',
}

// Anon convert
export const anonConvertFileSuccess = () => {
  return new CustomEvent(GlobalEvents.CONVERTER_FILE_ANON_UPLOADED)
}
export const anonConvertFileError = (message: string, status: number) => {
  return new CustomEvent(GlobalEvents.CONVERTER_FILE_ANON_UPLOAD_ERROR, {
    detail: { message, status },
  })
}

// User convert
export const authorizedConvertFileSuccess = (fileId: ApiFile['id']) => {
  return new CustomEvent(GlobalEvents.CONVERTER_FILE_USER_UPLOADED, {
    detail: { fileId },
  })
}
export const authorizedConvertFileError = (message: string, status: number) => {
  return new CustomEvent(GlobalEvents.CONVERTER_FILE_USER_UPLOAD_ERROR, {
    detail: { message, status },
  })
}

// Anon edit
export const anonEditFileSuccess = () => {
  return new CustomEvent(GlobalEvents.EDIT_FILE_ANON_UPLOADED)
}
export const anonEditFileError = (message: string) => {
  return new CustomEvent(GlobalEvents.EDIT_FILE_ANON_UPLOAD_ERROR, {
    detail: { message },
  })
}

// User edit
export const authorizedEditFileSuccess = () => {
  return new CustomEvent(GlobalEvents.EDIT_FILE_USER_UPLOADED)
}
export const authorizedEditFileError = (message: string) => {
  return new CustomEvent(GlobalEvents.EDIT_FILE_USER_UPLOAD_ERROR, {
    detail: { message },
  })
}

// Delete file
export const deleteFileSuccess = (fileId: ApiFile['id']) => {
  return new CustomEvent(GlobalEvents.DELETE_FILE_SUCCESS, {
    detail: { fileId },
  })
}
export const deleteFileError = (message: string) => {
  return new CustomEvent(GlobalEvents.DELETE_FILE_ERROR, {
    detail: { message },
  })
}

// Anon optimize
export const anonOptimizeFileSuccess = () => {
  return new CustomEvent(GlobalEvents.OPTIMIZE_FILE_ANON_UPLOADED)
}
export const anonOptimizeFileError = (message: string, status: number) => {
  return new CustomEvent(GlobalEvents.OPTIMIZE_FILE_ANON_UPLOAD_ERROR, {
    detail: { message, status },
  })
}

// User optimize
export const authorizedOptimizeFileSuccess = (fileId: ApiFile['id']) => {
  return new CustomEvent(GlobalEvents.OPTIMIZE_FILE_USER_UPLOADED, {
    detail: { fileId },
  })
}
export const authorizedOptimizeFileError = (
  message: string,
  status: number
) => {
  return new CustomEvent(GlobalEvents.OPTIMIZE_FILE_USER_UPLOAD_ERROR, {
    detail: { message, status },
  })
}

// Anon split
export const anonSplitFileSuccess = () => {
  return new CustomEvent(GlobalEvents.SPLIT_FILE_ANON_UPLOADED)
}
export const anonSplitFileError = (message: string, status: number) => {
  return new CustomEvent(GlobalEvents.SPLIT_FILE_ANON_UPLOAD_ERROR, {
    detail: { message, status },
  })
}

// User split
export const authorizedSplitFileSuccess = (fileId: ApiFile['id']) => {
  return new CustomEvent(GlobalEvents.SPLIT_FILE_USER_UPLOADED, {
    detail: { fileId },
  })
}
export const authorizedSplitFileError = (message: string, status: number) => {
  return new CustomEvent(GlobalEvents.SPLIT_FILE_USER_UPLOAD_ERROR, {
    detail: { message, status },
  })
}

// Anon merge
export const anonMergeFileSuccess = () => {
  return new CustomEvent(GlobalEvents.MERGE_FILE_ANON_UPLOADED)
}
export const anonMergeFileError = (message: string, status: number) => {
  return new CustomEvent(GlobalEvents.MERGE_FILE_ANON_UPLOAD_ERROR, {
    detail: { message, status },
  })
}

// User merge
export const authorizedMergeFileSuccess = (fileId: ApiFile['id']) => {
  return new CustomEvent(GlobalEvents.MERGE_FILE_USER_UPLOADED, {
    detail: { fileId },
  })
}
export const authorizedMergeFileError = (message: string, status: number) => {
  return new CustomEvent(GlobalEvents.MERGE_FILE_USER_UPLOAD_ERROR, {
    detail: { message, status },
  })
}

// Anon upload
export const anonUploadFileSuccess = () => {
  return new CustomEvent(GlobalEvents.FILE_ANON_UPLOADED)
}
export const anonUploadFileError = (message: string, status: number) => {
  return new CustomEvent(GlobalEvents.FILE_ANON_UPLOAD_ERROR, {
    detail: { message, status },
  })
}
