import bgImgMob from 'assets/img/forms/background-cms-1500-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-cms-1500-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';


import formCMS1500 from 'assets/forms/cms-1500.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const formCMS1500Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-cms-1500',
    filePath: formCMS1500,
    fileName: 'cms-1500.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_cms1500.title'),
      description: t('meta.form_cms1500.description'),
    },
    getForm: {
      headline1: '',
      headline2: 'Form',
      formName: 'CMS-1500',
      subHeadline: '2025 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline: 'How to use our CMS-1500 form filler',
      description: [
        {
          icon: icon_1,
          //@ts-ignore
          iconMob: icon_mob_1,
          title: 'Start with a blank form',
          text: 'Click "GET FORM" to unlock a blank CMS-1500 on PDF Guru. No downloads, no confusion - our platform provides an intuitive and user-friendly interface.',
        },
        {
          icon: icon_2,
          //@ts-ignore
          iconMob: icon_mob_2,
          title: 'Enter required information',
          text: 'Follow CMS-1500 instructions to fill in patient, provider, service, and billing details accurately. It’s important to review the form thoroughly before downloading.',
        },
        {
          icon: icon_3,
          //@ts-ignore
          iconMob: icon_mob_3,
          title: 'Download and use',
          text: 'After filling, download a competed and printable CMS-1500 form PDF for submission. You can save a copy for record-keeping, printing, or electronic submission.',
        },
      ],
    },
    whatIsForm: {
      headline: 'What is a CMS-1500 form?',
      text_1: [],
      text_2: [],
      text_3: [
        "Submitting a CMS-1500 claim form is crucial for timely reimbursement from Medicare and Medicaid. It's the primary tool used to communicate a patient's medical and treatment information to insurance providers.",
        'Timely submission of the CMS-1500 form ensures accurate and timely processing of medical claims, helping providers receive payment for the services they render. The form is essential for healthcare providers to maintain their financial stability and continue providing quality care to their patients.',
      ],
      text_4: [],
      text_5: [
        'Healthcare professionals, such as physicians, dentists, chiropractors, and other medical practitioners, use the paper CMS-1500 form to submit claims for reimbursement of medical services provided to patients. The form is widely used in the healthcare industry to facilitate the billing process and ensure that providers are reimbursed for their services.',
      ],
      title_1: '',
      title_2: 'Why is the CMS-1500 form important?',
      title_3: 'Who uses the paper CMS-1500 form?',
      subTitle: 'The form is divided into several sections, such as:',
      //@ts-ignore
      sectionImage: section_img,
      //@ts-ignore
      sectionImageTab: section_img_tab,
      description: [
        'The CMS-1500 form is a standard claim form used by healthcare professionals to bill Medicare and Medicaid. It includes various sections for patient and provider information, as well as details about the services provided.',
      ],                  
      bullets: [], 
      orangeList: [
        { 
          strong: 'Patient Information: ', 
          text: 'This section includes the patient\'s name, address, date of birth, and other relevant details.' 
        },
        { 
          strong: 'Provider Information: ', 
          text: 'This section includes the healthcare provider\'s name, address, and National Provider Identifier (NPI).' 
        },
        { 
          strong: 'Service Information: ', 
          text: 'This section includes details about the medical services provided, such as the date of service, procedure codes, and diagnosis codes.' 
        },
        { 
          strong: 'Payment Information: ', 
          text: 'This section includes details about the payment, such as the amount billed, the amount paid, and the amount due.' 
        },
      ],          
    },
    differenceBetween1: {
      headline: 'The difference between UB-04 and CMS-1500',
      text: "The CMS-1500 and UB-04 forms both serve to bill for medical services, but they're used by different providers. The CMS-1500 is typically used by individual doctors or group practices, while hospitals or larger medical facilities use the UB-04 form. The latter is more complex and requires more detailed information about the services provided, while the CMS-1500 form is simpler and focuses on the patient and provider information.",
    },
    howToFill: {
      headline: 'How to fill out a CMS-1500 form',
      //@ts-ignore
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Open CMS-1500 on PDF Guru: ',
          text: 'Click \'Fill Form\' to access a blank CMS-1500 form.',
        },
        {
          icon: blue_number_icon_2,
          strong: 'Input patient details: ',
          text: 'Accurately input patient\'s information, health insurance details and the data required by the national uniform claim committee.',
        },
        {
          icon: blue_number_icon_3,
          strong: 'Detail medical services: ',
          text: 'Include dates, procedure codes, and diagnosis codes for the provided services.',
        },
        {
          icon: blue_number_icon_4,
          strong: 'Add signature: ',
          text: 'Ensure the form is signed where required, as this is crucial for processing.',
        },
        {
          icon: blue_number_icon_5,
          strong: 'Review the form: ',
          text: 'Double-check all information for accuracy and completeness.',
        },
        {
          icon: blue_number_icon_6,
          strong: 'Download and save: ',
          text: 'After verification, download and save the form for your records.',
        },
      ],
    },
    blockWithBlueTitle2: {
      title: 'Common error that delays CMS-1500 claims processing',
      description: [
        'Incomplete or inaccurate information, missing signatures, and non-compliance with coding guidelines are common errors that can delay the processing of CMS-1500 claims. To avoid these errors, it is essential to review the completed form carefully and ensure that all information is accurate and complete before submitting it for processing.',
      ],
      orangeList: [],
    },
    fillFormFree: {
      headline: 'Making CMS-1500 Forms Simple and Accessible',
      subtitle: 'Effortless editing for seamless submissions',
      buttonLabel: 'Fill Form',
    },
    bannerQuickNEasy: {
      headline: 'Get a Flawless CMS-1500 with PDF Guru',
      subtitle: 'Navigate forms easily',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'What is the diagnosis pointer on a CMS-1500?',
          answer: [
            'The diagnosis pointer on a CMS-1500 claim form refers to the section where healthcare providers indicate the diagnosis code associated with the treatment provided. It is crucial for linking the service to the patient\'s condition, ensuring accurate billing.',
          ],
        },
        {
          question: 'How many diagnosis codes may be reported on each CMS-1500 claim?',
          answer: [
            'Up to 10 diagnosis codes may be reported on each CMS-1500 claim form, allowing healthcare providers to provide comprehensive information about the patient\'s condition and the services rendered. The diagnosis codes should be relevant to the services provided and should be reported in the correct order.',
          ],
        },
        {
          question: 'In which block is AOB signed in CMS-1500?',
          answer: [
            'The Assignment of Benefits (AOB) is signed in Block 13 of the CMS-1500 form, where the patient or the insured individual authorizes the healthcare provider to receive payment directly from the insurance company. The AOB is an essential part of the claim form, as it determines who will be responsible for paying the healthcare provider for the services rendered.',
          ],
        },
        {
          question: 'Where does CLIA number go on CMS-1500?',
          answer: [
            'For lab testing claims, enter the Clinical Laboratory Improvement Amendments (CLIA) number in Block 23 of the CMS-1500 form. This number is used to identify the laboratory that performed the tests or procedures mentioned in the claim. The CLIA number is required to ensure that the laboratory meets the necessary standards for quality and accuracy in the testing and analysis of patient samples.',
          ],
        },
      ],
    },            
    fag: {
      question: 'How to fill out Form 1065?',
      answer:
        "1. Open CMS-1500 on PDF Guru: Click 'Fill Form' to access a blank CMS-1500 form. 2. Input patient details: Accurately input patient's information, health insurance details and the data required by the national uniform claim committee. 3. Detail medical services: Include dates, procedure codes, and diagnosis codes for the provided services. 4. Add signature: Ensure the form is signed where required, as this is crucial for processing. 5. Review the form: Double-check all information for accuracy and completeness. 6. Download and save: After verification, download and save the form for your records.",
    },
  };
}