/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import chathovered from 'assets/ai-summarizer/chat-hovered.svg';
import chat from 'assets/ai-summarizer/chat.svg';
import classNames from 'classnames';

import apiEndpoints from 'use-cases/ai-summarizer/api/api';
import { buildThreadMessage } from 'use-cases/ai-summarizer/build-thread-message';

import { Analytics } from 'services/analytics';

import {
  removeCurrentThreadData,
  setCurrentFileUrl,
  setCurrentThreadId,
  setFileId,
  setFileName,
  setMobileMenuOpen,
} from 'data/actions/aiSummarizer';
import { triggerLoadingAction } from 'data/actions/loading';
import { chatHistorySelector, currentThreadIdSelector } from 'data/selectors/aiSummarizer';
import { getLoadingSelector } from 'data/selectors/loading';

export const ChatHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentThreadId = useSelector(currentThreadIdSelector);

  const chatsHistory = useSelector(chatHistorySelector);
  const loadingChatsHistory = useSelector(getLoadingSelector('chatsHistory'));
  const [hasInitiallyLoaded] = useState(false);

  const highlightedID = useMemo(() => currentThreadId, [currentThreadId, chatsHistory]);

  const handleThreadClick = async (item: Record<string, any>) => {
    const missingAnyID = !currentThreadId || !item.id;
    const clickOnCurrentThread = currentThreadId === item.id;

    if ((missingAnyID || clickOnCurrentThread) && currentThreadId !== null) {
      return;
    }

    Analytics.sendEvent({
      event: 'click_view_chat_tap',
    });

    dispatch(setCurrentThreadId(item.id));
    dispatch(setFileId(item.fileId));
    dispatch(setCurrentFileUrl(item.file_url));
    dispatch(setFileName(item.file_name));
    dispatch(setMobileMenuOpen(false));
    dispatch(removeCurrentThreadData());

    try {
      dispatch(triggerLoadingAction(['threadHistory'], true));

      const response = await apiEndpoints.getAllMessages(item.id);
      const threadId = response.chatId;

      if (response.data) {
        const sortedMessages = response.data.sort(
          (a: Record<string, any>, b: Record<string, any>) => a.created_at - b.created_at
        );

        buildThreadMessage(sortedMessages, threadId, dispatch);
      }

      dispatch(triggerLoadingAction(['threadHistory'], false));
    } catch (error) {}
  };

  return (
    <div className="h-full">
      <h2 className="uppercase mb-3 text-[#575757] font-semibold text-sm">
        {t('ai_summarizer.history')}
      </h2>

      <div className="max-h-[calc(100vh-375px)] overflow-y-auto [direction:rtl] pl-2">
        <ul className="[direction:ltr] overflow-hidden flex flex-col gap-2">
          {loadingChatsHistory ? (
            <div className="flex flex-col gap-4 px-4 py-2">
              {Array.from({
                length: Math.floor((window.innerHeight - 430) / 48),
              }).map((_, i) => (
                <div key={i} className="animate-pulse flex gap-2">
                  <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
                  <div className="flex-1">
                    <div
                      className={`h-4 bg-gray-200 rounded w-${Math.floor(
                        Math.random() * 25 + 50
                      )}% mb-2`}
                    ></div>
                    <div
                      className={`h-3 bg-gray-200 rounded w-${Math.floor(
                        Math.random() * 15 + 20
                      )}%`}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {chatsHistory &&
                chatsHistory
                  .sort(
                    (a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
                  )
                  .map((item, index) => (
                    <li
                      className={classNames(
                        'list-none hover:bg-[#F6F7FD]/80 transition-all duration-100 rounded-lg',
                        {
                          'hover:scale-[1.02]': highlightedID !== item.id,
                        }
                      )}
                      key={item.id}
                    >
                      <button
                        className={classNames(
                          'px-4 py-2 flex gap-1 rounded-lg w-full',
                          {
                            'bg-[#F6F7FD]': highlightedID === item.id,
                            'cursor-default': highlightedID === item.id,
                          },
                          {
                            'animate-[slideIn_0.5s_ease-out] opacity-0 [animation-fill-mode:forwards] [animation-delay:0.2s]':
                              index === 0,
                          }
                        )}
                        key={item.id}
                        onClick={() => handleThreadClick(item)}
                        data-testid={`ai-summarizer-chat-history-item-${index}`}
                      >
                        <img
                          src={highlightedID === item.id ? chathovered : chat}
                          alt="chat"
                          height={20}
                          width={20}
                          className={classNames('h-5 w-5 transition-all duration-300', {
                            'animate-[spin_0.5s_ease-out] [animation-delay:0.7s]': index === 0,
                          })}
                        />
                        <div className="flex flex-col gap-1 w-full overflow-hidden">
                          <span
                            className={classNames(
                              'text-[16px] font-[600] leading-[125%] transition-colors duration-300 text-left truncate max-w-full',
                              {
                                'text-[#3E4FE0]': highlightedID === item.id,
                              },
                              {
                                'animate-[fadeIn_0.5s_ease-out] opacity-0 [animation-fill-mode:forwards] [animation-delay:0.9s]':
                                  index === 0,
                              }
                            )}
                          >
                            {item.chatName?.replace(/\.pdf$/i, '') || item.chatName}
                          </span>
                          <span
                            className={classNames(
                              'text-[13px] text-[#575757] leading-[135%] truncate max-w-min',
                              {
                                'animate-[fadeIn_0.5s_ease-out] opacity-0 [animation-fill-mode:forwards] [animation-delay:1.1s]':
                                  index === 0,
                              }
                            )}
                          >
                            {item.updated_at
                              ? new Date(item?.updated_at).toLocaleDateString()
                              : new Date().toLocaleDateString()}
                          </span>
                        </div>
                      </button>
                    </li>
                  ))}

              {!chatsHistory.length && !loadingChatsHistory && hasInitiallyLoaded && (
                <div className="text-[#575757] text-base py-4">
                  {t('ai_summarizer.messages.no_history_message')}
                </div>
              )}
            </>
          )}
        </ul>
      </div>

      <style>{`
        @keyframes slideIn {
          from {
            transform: translateX(-100%);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        ::-webkit-scrollbar {
          width: 4px;
        }

        ::-webkit-scrollbar-track {
          background: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background: #e5e5e5;
          border-radius: 4px;
        }

        ::-webkit-scrollbar-thumb:hover {
          background: #d5d5d5;
        }
      `}</style>
    </div>
  );
};
