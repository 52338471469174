import classNames from 'classnames';
import { config } from 'configuration';
import React, { ChangeEventHandler, DragEventHandler, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cross_btn from 'sections/upload-section/assets/cross.svg';
import { Analytics } from 'services/analytics';
import { MainColors } from 'ts/constants/general';
import { InternalFileType, InternalType } from 'ts/types/file';
import { getFileFormatFromFileList } from 'utils/getFileFormatFromFileList';
import { Logger } from 'utils/logger';
import { validateSelectedFile } from 'utils/validation';

import { useLocation } from 'react-router-dom';
import { EServiceType, IService } from 'ts/interfaces/services/service';
import s from './drag-n-drop-new-design.module.scss';

interface IProps {
  format: InternalFileType | InternalFileType[];
  onSelectFile: (file: File) => void;
  setError: (value: string | null) => void;
  onClick?: (method: string) => void;
  color?: MainColors;
  service?: IService;
}

export const DragNDrop: React.FC<IProps> = ({
  format,
  onSelectFile,
  setError,
  onClick,
  color = 'red',
  service,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const logger = useMemo(() => new Logger('DragNDrop'), []);

  const isPdfConverterPage = location.pathname.includes('/pdf-converter');

  const processFile = useCallback(
    (files: FileList | null) => {
      if (!inputFileRef.current) return;

      const validation = validateSelectedFile({
        files: files,
        type: format,
        translator: t,
        isPDFConverter: isPdfConverterPage,
      });

      if (!validation.valid && validation.error) {
        setError(validation.error);
        logger.error('validation_error', { files: JSON.stringify(files) });

        // reset input value
        inputFileRef.current.value = '';

        const eventData: Record<string, any> = {
          status: 'fail',
          place: 'main',
          errorCode: validation.code,
        };

        eventData.file_format = getFileFormatFromFileList(files);
        eventData.is_validation_error = true;

        void Analytics.sendEvent({
          event: 'file_upload_status',
          data: eventData,
        });
        return;
      }

      if (validation.valid) {
        setError(null);
        onSelectFile(validation.file);
        inputFileRef.current.value = '';
        return;
      }
    },
    [format, t, isPdfConverterPage, setError, logger, onSelectFile]
  );

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.target;
    if (files && files.length > 0) {
      // Handle the selected file
      const selectedFiles = files;
      processFile(selectedFiles);
      // Clear the input value
      // @ts-ignore
      inputFileRef.current.value = '';
    }
  };

  const onBtnClick = React.useCallback(() => {
    if (!inputFileRef.current) return;

    Analytics.sendEvent({
      event: 'features_tap',
      data: {
        method: 'click',
      },
    });

    if (onClick) {
      onClick('button_click');
    }

    inputFileRef.current.click();
  }, [onClick]);

  const onDrop: DragEventHandler<HTMLDivElement> = (e) => {
    Analytics.sendEvent({
      event: 'features_tap',
      data: {
        method: 'drag_and_drop',
      },
    });
    e.preventDefault();
    if (onClick) {
      onClick('drag_and_drop');
    }
    if (!e.dataTransfer || e.dataTransfer.files.length === 0) return;

    processFile(e.dataTransfer.files);
  };

  const acceptedFormats = Array.isArray(format)
    ? InternalType.toMultipleMimetypes(format).join(', ')
    : InternalType.toMimeType(format);

  const classesDragNDrop = {
    [s.dragNDrop]: true,
    [s.orange]: color === 'orange',
    [s.violet]: color === 'violet',
    [s.berry]: color === 'berry',
    [s.darkblue]: color === 'dark-blue',
    [s.green]: color === 'green',
    [s.peach]: color === 'peach',
    [s.oceanblue]: color === 'ocean-blue',
  };

  const classesDropZone = {
    [s.dropZone]: true,
    [s.orange]: color === 'orange',
    [s.violet]: color === 'violet',
    [s.berry]: color === 'berry',
    [s.darkblue]: color === 'dark-blue',
    [s.green]: color === 'green',
    [s.peach]: color === 'peach',
    [s.oceanblue]: color === 'ocean-blue',
  };

  return (
    <div className={classNames(classesDragNDrop)}>
      <div
        className={classNames(classesDropZone)}
        onClick={onBtnClick}
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
        data-testid="drag-n-drop-container"
      >
        <div className={s.content}>
          <div className={s.uploadButton}>
            <img src={cross_btn} width="32px" height="33px" alt="cross_btn" />
          </div>
          <span className="font-[700] small-desktop:block hidden">
            {t('upload_section.widget.upload')}
          </span>
          <span className="font-[700] small-desktop:hidden block w-full mx-auto max-w-[400px]">
            {t('upload_section.upload_from_device')}
          </span>
        </div>
        <div
          className="text-[13px] leading-[17px] text-[#F5F5F5] font-[500]
          text-center tablet:mb-6 mb-8 mx-8 mobile:mx-0 mobile:block hidden"
        >
          {service?.serviceType === EServiceType.OCR
            ? t('upload_section.widget.limit_ocr')
            : t('upload_section.widget.limit')}
        </div>
        <div
          className="text-[16px] leading-[22px] text-[#F5F5F5] font-[600]
          text-center py-6 mobile:hidden block"
        >
          {t('upload_section.click_to_upload')}
        </div>
      </div>

      <input
        type="file"
        ref={inputFileRef}
        accept={isPdfConverterPage ? '*' : acceptedFormats}
        onChange={onFileChange}
        size={
          service?.serviceType === EServiceType.OCR
            ? 30 * 1024 * 1024
            : config.api.maxFileSizeMb * 1024 * 1024
        }
        multiple={true}
        data-testid="choose-file-button"
      />
    </div>
  );
};
