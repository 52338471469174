import classNames from 'classnames';
import { FC } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Analytics } from 'services/analytics';
import radio_active from '../assets/radio_active.svg';
import radio_inactive from '../assets/radio_inactive.svg';
import { Language } from '../localesFull';

interface LanguageOptionProps {
  item: Language;
  isSelected: boolean;
  onSelect: (item: Language) => void;
  analyticsType: 'from' | 'to';
}

export const LanguageOption: FC<LanguageOptionProps> = ({
  item,
  isSelected,
  onSelect,
  analyticsType,
}) => {
  const handleClick = () => {
    onSelect(item);
    Analytics.sendEvent({
      event: 'click_language_tap',
      data: {
        language: item.code,
        type: analyticsType,
        place: 'top',
      },
    });
  };

  return (
    <div
      className={classNames(
        'h-[57px] p-4 bg-[#F6F6F6] rounded-xl text-white flex items-center gap-2 cursor-pointer',
        { '!bg-[#5F30E2]': isSelected }
      )}
      onClick={handleClick}
    >
      <LazyLoadImage src={isSelected ? radio_active : radio_inactive} alt={item.language} />
      <img className="w-6" src={item.link} alt="language icon" />
      <p className={classNames({ 'text-black': !isSelected })}>{item.language}</p>
    </div>
  );
};
