import bgImgMob from 'assets/img/forms/background-i-9-mob.webp';
import bgImgTab from 'assets/img/forms/background-i-9-tab.webp';
import bgImgWeb from 'assets/img/forms/background-i-9-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';

import formI9 from 'assets/forms/i-9.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';
import { Description } from './parts/form-i9/description';

export const formI9Service = (t: any): IFormService => {
  return {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-i9',
      filePath: formI9,
      fileName: 'i-9.pdf',
      from: 'PDF',
      to: 'PDF',
      meta: {
        title: t('meta.form_i9.title'),
        description: t('meta.form_i9.description'),
      },
      getForm: {
        headline1: 'USCIS',
        headline2: 'Form',
        formName: 'I-9',
        subHeadline: '2025 Printable Template',
        backgroundImageWeb: bgImgWeb,
        backgroundImageTab: bgImgTab,
        backgroundImageMob: bgImgMob,
        buttonLabel: 'GET FORM',
      },
      easilyComplete: {
        headline: 'Easily complete an I-9 employment eligibility verification form',
        description: [
          {
            icon: icon_1,
            iconMob: icon_mob_1,
            title: 'Fill out Section 1',
            text: 'Use our PDF tool to add your personal information and contact info to a fillable form I-9.',
          },
          {
            icon: icon_2,
            iconMob: icon_mob_2,
            title: 'Sign and date it',
            text: 'Add your electronic signature to the form along with the current date, right from your device.',
          },
          {
            icon: icon_3,
            iconMob: icon_mob_3,
            title: 'Share it with your employer',
            text: 'Download, print, or email your completed I-9 form for your employer to review & verify.',
          },
        ],
      },
      whatIsForm: {
        headline: 'What is an I-9 form used for?',
        text_1: [],
        text_2: [],
        text_3: [
          'In the United States, the law makes the I-9 form fillable for all employees. It applies to both citizens and non-citizens who are working or will be working in the US. Employers are responsible for ensuring that all new hires complete this form within three days of starting employment. Failure to comply with the I 9 requirements can result in penalties for both employers and employees. It is crucial for individuals to understand the importance of completing this form accurately and promptly to avoid any legal complications in their employment status.',
        ],
        text_4: [
          "As we've mentioned earlier, according to I-9 requirements, employees must prove both their identity and employment authorization. This means that they must provide either one document from List A or one document from List B, and one from List C.",
          "Obviously, if you're using PDF Guru to fill and sign the I9 form online, you would probably like to cut the paperwork. If so, it is more convenient to provide one A document with your fillable employment verification form instead of collecting two. This can be a US passport or passport card, a permanent resident card (Green Card), or a foreign Passport with an I-551 stamp or attached I-94 form. To see the full list of acceptable docs, please read the I9 form instructions.",
          'All these papers have specific requirements and limitations, and employers must carefully review them to ensure compliance with the I-9 verification process. By ensuring that the documents are presented and verified, employers can fulfill their legal obligations and maintain a workforce that is authorized to work in the US.',
        ],
        orangeList: [],
        title_1: 'List of documents for I-9 verification',
        title_2: 'Who needs to fill out an I-9?',
        subTitle:
          'There are three groups of acceptable documents that can be used to accompany a fillable I-9 form (2023 version):',
        //@ts-ignore
        sectionImage: section_img,
        //@ts-ignore
        sectionImageTab: section_img_tab,
        description: [],
        description2: Description,
        bullets: [
            {
                strong: "List A — Documents that establish both identity and employment authorization.",
                text: ""
            },
            {
                strong: "List B — Documents that establish identity only.",
                text: ""
            },
            {
                strong: "List C — Documents that establish employment authorization only.",
                text: ""
            }
        ],
      },
      howToFill: {
        headline: 'How to fill out I-9 forms correctly',
        //@ts-ignore
        sectionImage: howToFillImage,
        bullets: [
          {
            icon: blue_number_icon_1,
            strong: "",
            text: 'Click the Fill Form button under this section',
          },
          {
            icon: blue_number_icon_2,
            strong: "",
            text: 'In the Editor window that opens, complete the fillable I-9 form (only Section 1 for employees)',
          },
          {
            icon: blue_number_icon_3,
            strong: "",
            text: 'Sign the PDF and insert the current date',
          },
          {
            icon: blue_number_icon_4,
            strong: "",
            text: 'Download and send your employer the form along with acceptable documents',
          },
        ],
      },
      fillFormFree: {
        headline: 'Fill out the I-9 Form in Seconds',
        subtitle: 'It has never been easier or faster',
        buttonLabel: 'Fill Form',
      },
      faqSection: {
        headline: 'Frequently asked questions',
        faqSectionDescription: [
          {
            question: 'Can I sign the I-9 form electronically?',
            answer: [
                `Yes, you can sign and fill out I9 online electronically, as long as you comply with the electronic signature requirements set by the US Department of Homeland Security (DHS). Basically, e-signatures are considered valid as long as they meet certain criteria, such as confirming the identity of the signer and indicating their intent to sign the form.`,
                `Our PDF maker makes the I 9 form fillable and allows you to use the entire Editor toolbox, including e-signature.`
            ]
          },
          {
            question: 'Does I-9 need to be updated yearly?',
            answer: [
                `No, I9 forms do not need to be updated yearly. Once yours is completed and on file with the employer, it generally does not need to be updated.`,
                `Still, there are some cases in which re-verification may be required, including when an employee's work authorization document is expiring, they are rehired after a long break in employment or other specific situations that require re-verification according to USCIS guidelines. To easily deal with this paperwork when you need an I9 form, fill out the file using our PDF maker tool. It will take you less than 5 minutes.`
            ]
          },
          {
            question: 'Who can be an authorized representative for I-9?',
            answer: [
                `An authorized representative for the I-9 tax form can be any individual chosen by an employer to complete the form on their behalf. This representative could be an employee or a third party, such as an attorney or notary public.`,
                `The job of the authorized representative is to examine the employee's original identification documents and complete the employer section of the I9 tax form. Employers should ensure that their chosen representative is trustworthy, reliable, and understands the importance of I 9 requirements.`
            ]
          }
        ],
      },
      fag: {
        question: 'How to fill out I-9 forms correctly?',
        answer:
          '1. Click the Fill Form button under this section. 2. In the Editor window that opens, complete the fillable I-9 form (only Section 1 for employees). 3. Sign the PDF and insert the current date. 4. Download and send your employer the form along with acceptable documents.',
      },
      bannerQuickNEasy: {
        headline: 'The Quickest Way to Fill Out an I-9',
        subtitle: 'Complete your form with just a few clicks!',
        format: InternalFileType.PDF,
        buttonLabel: 'Fill Form',
      },
  };
};
