import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { userEmailSelector, userIdSelector } from 'data/selectors/user';
import { getCookie } from './useSignUpQuery';
import { getJsonFromStorage } from 'helpers/localStorageHelper';
import { IAbTest } from 'ts/interfaces/abTest/abTest';
import { sendAnalyticEvent } from 'data/actions/analytics';

const useClarity = () => {
  const userId = useSelector(userIdSelector);
  const email = useSelector(userEmailSelector) || '';
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.hostname === 'localhost') return;
    clarity.init(process.env.REACT_APP_MICROSOFT_CLARITY_ID || 'p4iufj3yr3');
  }, []);

  useEffect(() => {
    if (userId && clarity.hasStarted()) {
      clarity.identify(userId, { email });
      clarity.setTag('email', email);

      const abTests = localStorage.getItem('abTests') ? getJsonFromStorage('abTests') : null;
      const clarityCookies = getCookie('_clck');

      if (clarityCookies) {
        const clarityId = clarityCookies.split('|')[0];

        dispatch(
          sendAnalyticEvent({
            event: 'clarity_init',
            data: {
              clarityId,
            },
          })
        );
      }

      if (abTests?.length) {
        // set user AB tests tag for clarity
        abTests.forEach((abTest: IAbTest) => {
          if (typeof abTest.variant === 'boolean') {
            return;
          }
          clarity.setTag(abTest.feature, abTest.variant);
        });
      }
    }
  }, [userId, email, dispatch]);
};

export default useClarity;
