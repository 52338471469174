import axios from 'axios';

import {
  CreateThreadAndRunResponse,
  GetMessageByRunIdResponse,
} from 'ts/interfaces/ai-summarizer/api';

// Base API instance for other endpoints
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1/openai`,
  withCredentials: true,
});

// Utility functions for API calls
const get = async <T>(url: string, params = {}) => {
  try {
    const response = await api.get<T>(url, {
      params,
      headers: {
        'OpenAI-Beta': 'assistants=v2',
      },
    });
    return response.data;
  } catch (error) {
    console.error(`GET ${url} failed:`, error);
    throw error;
  }
};

const post = async <T>(url: string, data = {}, headers = {}): Promise<T> => {
  try {
    const response = await api.post<T>(url, data, { headers });
    console.log(`POST ${url} succeeded:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`POST ${url} failed:`, error);
    throw error;
  }
};

// API Endpoints
const apiEndpoints = {
  createThreadAndRun: (openai_file_id: string, fileId: string) =>
    post<CreateThreadAndRunResponse>('/chats', {
      openai_file_id,
      fileId,
    }),
  createChat: ({
    key,
    filename,
    size,
    pagesCount,
  }: {
    key: string;
    filename: string;
    size: number;
    pagesCount: number;
  }) => {
    console.log('Creating chat at:', `${process.env.NEXT_PUBLIC_API_URL}/openai/file-uploaded`);
    return post<any>('/file-uploaded', {
      key,
      filename,
      size,
      pagesCount,
    });
  },
  getMessageByRunId: (chatId: string, runId: string) =>
    get<GetMessageByRunIdResponse>(`/${chatId}/messages?runId=${runId}`),
  sendMessage: (chatId: string, content: string) => post<any>(`/${chatId}/messages`, { content }),
  getAllMessages: (chatId: string) => get<any>(`/${chatId}/messages`),
  getAllChats: () => get<any>('/chats'),
};

export default apiEndpoints;
