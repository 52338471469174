import React from 'react'

import { PdfguruLogo } from '../logo'
import { useTranslation } from 'react-i18next'
import { LoadingDot } from './loading-dot'

type AnalyzingLoaderProps = {
  type: 'messages' | 'file' | 'new-file' | 'chat-history'
  message?: string
}

export const AnalyzingLoader: React.FC<AnalyzingLoaderProps> = ({
  type,
  message,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex gap-4">
      <PdfguruLogo />

      <div className="flex gap-2 items-center">
        <div className="flex gap-1 items-center">
          <LoadingDot delay="0s" />
          <LoadingDot delay="0.2s" />
          <LoadingDot delay="0.4s" />
        </div>

        <span className="text-sm text-[#192CD2] font-bold animate-[pulse_1.4s_ease-in-out_infinite] leading-[100%]">
          {(() => {
            switch (type) {
              case 'new-file':
                return t('ai_summarizer.messages.analyzing_file')
              case 'messages':
                return message
              case 'file':
                return t('ai_summarizer.messages.uploading_document')
              case 'chat-history':
                return t('ai_summarizer.messages.loading_history')
              default:
                return t('ai_summarizer.messages.loading')
            }
          })()}
        </span>
      </div>
    </div>
  )
}
