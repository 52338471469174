import React from 'react';

import close from 'assets/img/icons/24px/close.svg';
import nav from 'assets/img/icons/24px/mobile_nav.svg';
import s from './nav-toggler.module.scss';

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}
export const NavToggler: React.FC<IProps> = ({ isOpen, toggle }) => {
  return (
    <div onClick={toggle} className={s.navToggler}>
      {isOpen ? <img src={close} alt="Close icon" /> : <img src={nav} alt="Mobile nav icon" />}
    </div>
  );
};
