/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ChatWithAI } from 'components/ai-summarizer/chat-with-ai';
import { PDFViewer } from 'components/ai-summarizer/pdf-viewer';
import { Sidebar } from 'components/ai-summarizer/sidebar';

import apiEndpoints from 'use-cases/ai-summarizer/api/api';
import { buildThreadMessage } from 'use-cases/ai-summarizer/build-thread-message';
import { getDefaultChat } from 'use-cases/ai-summarizer/get-default-chat';
import { redirectToChoosePlan } from 'use-cases/ai-summarizer/redirect-to-choose-plan';
import { resetChat } from 'use-cases/ai-summarizer/reset-chat';
import { setCurrentChat } from 'use-cases/ai-summarizer/set-current-chat';

import { Analytics } from 'services/analytics';

import {
  setChatsHistory,
  setCurrentFileUrl,
  setCurrentThreadId,
  setFileId,
  setFileName,
  setNewFileData,
} from 'data/actions/aiSummarizer';
import { getDocumentById } from 'data/actions/documents';
import { triggerLoadingAction } from 'data/actions/loading';
import { toggleModal } from 'data/actions/modals';
import {
  chatHistorySelector,
  currentThreadIdSelector,
  fileIdSelector,
} from 'data/selectors/aiSummarizer';
import { userDataSelector } from 'data/selectors/user';

import { getFileKeyFromAWSLink } from 'utils/getFileKeyFromAWSLink';
import { Logger } from 'utils/logger';
import { savePrevPath } from 'utils/prevPath';

import { EModalTypes } from 'ts/enums/modal.types';

import { AISummarizerHeader as Header } from '../../components/ai-summarizer/header';
import { Subheader } from '../../components/ai-summarizer/subheader';

export const AISummarizerRouter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(userDataSelector);
  const currentThreadId = useSelector(currentThreadIdSelector);
  const chats = useSelector(chatHistorySelector) || null;
  const savedFileId = useSelector(fileIdSelector);

  const [isFileAlreadyDownloaded, setIsFileAlreadyDownloaded] = useState(false);

  const navigate = useNavigate();

  const logger = new Logger('AISummarizerPage');

  const createChat = async (data: any) => {
    if (data?.key) {
      try {
        const chat = await apiEndpoints.createChat(data);
        const latestChats = await apiEndpoints.getAllChats();

        dispatch(setChatsHistory(latestChats));
        dispatch(triggerLoadingAction(['currentThread'], true));
        dispatch(setCurrentThreadId(chat.chatId));
        dispatch(setFileName(chat.chatName));

        if (chat.status === 'WAITING' && chat.chatId) {
          const interval = setInterval(async () => {
            try {
              const messages = await apiEndpoints.getAllMessages(chat.chatId);
              console.log('custom messages--->', messages);

              const sortedMessages = messages?.data?.sort(
                (a: any, b: any) => a.created_at - b.created_at
              );

              if (messages.data.length > 1 && sortedMessages[1]?.content[0]?.text?.value) {
                clearInterval(interval);

                await setCurrentChat(chat, messages.data, dispatch);
                dispatch(triggerLoadingAction(['currentThread'], false));
              }

              dispatch(triggerLoadingAction(['threadHistory'], false));
            } catch (err) {}
          }, 2000);
        }
      } catch (err) {
        dispatch(triggerLoadingAction(['currentThread'], false));

        if ((err as any).response?.status === 429) {
          Analytics.sendEvent({
            event: 'pdf_summarizer_daily_limit',
          });

          dispatch(toggleModal({ visible: true, type: EModalTypes.AI_SUMMARIZER_DAILY_LIMIT }));

          const latestChats = await apiEndpoints.getAllChats();

          dispatch(setChatsHistory(latestChats));

          await getDefaultChat(latestChats, dispatch);

          return;
        }

        if ((err as any).response?.status === 400) {
          Analytics.sendEvent({
            event: 'pdf_summarizer_max_size',
          });

          dispatch(toggleModal({ visible: true, type: EModalTypes.AI_SUMMARIZER_MAX_SIZE_FILE }));

          return;
        }

        if ((err as any).response?.status === 402) {
          if (!user?.email) {
            new Logger('USER ON EMAIL MODAL').log('USER ===>', {
              ...user,
            } as any);

            dispatch(toggleModal({ visible: true, type: EModalTypes.ENTER_EMAIL_AI_SUMMARIZER }));

            return;
          }
          redirectToChoosePlan(navigate);
        }
      }
    }
  };

  const validateFileUpload = (fileId: string, chats: any, userData: any) => {
    logger.log('User Data Validate File Upload ===>', { userData });
    logger.log('User Data Validate File Can Use ===>', {
      condition: !userData?.subscription && chats.length >= 1,
    });
    logger.log('1 User Data Validate File Can Use ===>', { condition: !userData?.subscription });
    logger.log('2 User Data Validate File Can Use ===>', { condition: chats.length >= 1 });
    logger.log('2 User Data Validate File Can Use CHATS ===>', { chats });
    logger.log('User Data Validate File Email ===>', { email: userData.email });

    if (!userData?.subscription && chats.length >= 1) {
      if (!userData?.email) {
        setTimeout(() => {
          dispatch(toggleModal({ visible: true, type: EModalTypes.ENTER_EMAIL_AI_SUMMARIZER }));
        }, 300);

        navigate(window.location.pathname, { replace: true });

        return;
      }

      redirectToChoosePlan(navigate);

      return;
    }

    loadFileById(fileId);
    setIsFileAlreadyDownloaded(true);
  };

  const loadFileById = useCallback(
    async (fileId: string) => {
      try {
        resetChat(dispatch);

        const file = await dispatch(getDocumentById(fileId));

        const { url, filename } = file.payload || {};

        if (url && filename) {
          logger.log('File ==>', {
            'File Name': filename,
            'File URL': url,
          });

          const data = {
            filename: filename,
            size: 1,
            key: getFileKeyFromAWSLink(url),
            pagesCount: 1,
          };

          dispatch(setCurrentFileUrl(url));
          dispatch(setFileName(filename));
          dispatch(setNewFileData(data));

          await new Promise((resolve) => setTimeout(resolve, 1000));
          await createChat(data);

          navigate(window.location.pathname, { replace: true });
        }
      } catch (error) {
        dispatch(triggerLoadingAction(['currentThread'], false));

        console.error('Error loading PDF:', error);
      } finally {
      }
    },
    [dispatch, logger, navigate]
  );

  useEffect(() => {
    const handleInitialFileLoad = async () => {
      if (isFileAlreadyDownloaded) {
        return;
      }

      const params = new URLSearchParams(window.location.search);
      const fileId = params.get('file');

      const isUserReady = user.hasOwnProperty('id');
      const areChatsReady = chats !== null;

      logger.log('handle initial CHATS ===>', { chats } as any);
      logger.log('handle initial user ===>', { user });
      logger.log('handle initial user ID ===>', { id: user.hasOwnProperty('id') });
      logger.log('handle initial isUserReady ===>', { isUserReady });
      logger.log('handle initial areChatsReady ===>', { areChatsReady });
      logger.log('handle initial savedFileId ===>', { savedFileId });

      if (fileId && isUserReady && areChatsReady) {
        validateFileUpload(fileId, chats, user);
      } else {
        dispatch(setCurrentFileUrl('missing-file-id'));
        dispatch(setFileName(null));
      }
    };

    handleInitialFileLoad();
  }, [user, chats, isFileAlreadyDownloaded]);

  useEffect(() => {
    const getChats = async () => {
      try {
        dispatch(triggerLoadingAction(['chatsHistory'], true));

        const res = await apiEndpoints.getAllChats();

        dispatch(setChatsHistory(res));

        const params = new URLSearchParams(window.location.search);
        const fileParam = params.get('file');

        if (user.subscription && fileParam) {
          return;
        }

        if (!currentThreadId && res[0]?.id) {
          dispatch(setCurrentThreadId(res[0]?.id));
          dispatch(setFileId(res[0]?.fileId));

          try {
            dispatch(triggerLoadingAction(['threadHistory'], true));

            const response = await apiEndpoints.getAllMessages(res[0]?.id);

            const threadId = response.chatId;

            if (response.data) {
              const sortedMessages = response.data.sort(
                (a: any, b: any) => a.created_at - b.created_at
              );

              buildThreadMessage(sortedMessages, threadId, dispatch);
            }
          } catch (error) {
            if (
              (error as any).response?.data.statusCode === 400 &&
              (error as any).response?.data.message === 'error.openai.chat-not-ready'
            ) {
              return;
            }
          } finally {
            dispatch(triggerLoadingAction(['threadHistory'], false));
          }
        }
      } catch (error) {
        console.error('Error fetching current chat:', error);
      } finally {
        dispatch(triggerLoadingAction(['chatsHistory'], false));
      }
    };
    void getChats();
  }, []);

  // Analytics
  useEffect(() => {
    Analytics.sendEvent({
      event: 'ai_summarizer_page_load',
    });
  }, []);

  //   // Prevent user from leaving the page
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const message = t('ai_summarizer.messages.leave_confirmation');

      if (!window.confirm(message)) {
        e.preventDefault();
        e.returnValue = message;
        return message;
      }

      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    savePrevPath();
  }, []);

  return (
    <div className="flex min-[768px]:mt-0" data-testid="ai-summarizer-page">
      <Sidebar />

      <div className="flex flex-col w-full mt-[-64px] min-[768px]:mt-0">
        <Header />
        <Subheader />

        <div className="flex w-full h-full">
          <PDFViewer data-testid="ai-summarizer-pdf-viewer" />
          <ChatWithAI data-testid="ai-summarizer-chat" />
        </div>
      </div>
    </div>
  );
};
