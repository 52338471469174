import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CrossIcon } from '../icons/cross';

const AppToaster = () => {
  const defaultStyles =
    'max-w-[600px] !w-full mx-auto rounded-lg flex justify-between pt-[16px] pr-[12px] pb-[16px] pl-[20px]';

  return (
    <ToastContainer
      className="z-[100007] mx-auto !w-full desktop:max-w-[600px]"
      hideProgressBar
      closeButton={<CustomCloseButton />}
      icon={false}
      toastClassName={(context) => {
        const type = context?.type;
        switch (type) {
          case 'error':
            return `bg-[#D2294B] ${defaultStyles}`;
          case 'success':
            return `bg-[#0E9948] ${defaultStyles}`;
          default:
            return `bg-gray-400 ${defaultStyles}`;
        }
      }}
    />
  );
};

const CustomCloseButton = ({ closeToast }: any) => (
  <button onClick={closeToast}>
    <CrossIcon />
  </button>
);

export default AppToaster;
