// import { AIModelsSelect } from './components/ai-models-select'
import { Convertsation } from './conversation';
import { Input } from './conversation/input';

export const ChatWithAI = () => {
  return (
    <div className="flex-1 h-[calc(100vh-140px)] small-desktop:h-[calc(100vh-75px)] bg-[#FFF] min-w-[280px] pt-4 pb-[60px] small-desktop:pb-10 flex flex-col justify-between border-l border-[#E9E9E9] overflow-y-auto">
      <div className="flex flex-col gap-[20px] px-6 conversation-container">
        {/* TODO: Add AI model selection feature */}
        {/* <AIModelsSelect /> */}
        <Convertsation />
      </div>

      <Input />
    </div>
  );
};
