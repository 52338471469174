import { toggleModal } from 'data/actions/modals';
import { getAbTestVariant } from 'data/selectors/abTests';
import useFileUploadAndConvert from 'hooks/fileFlows/useFileUploadAndConvert';
import useFileUploadAndEdit from 'hooks/fileFlows/useFileUploadAndEdit';
import useFileUploadAndMerge from 'hooks/fileFlows/useFileUploadAndMerge';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_TYPES_NOT_FOR_EDITOR_FLOW } from 'sections/upload-section';
import { GrowthBookTestType, TestVariants } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';
import { EServiceType, IService } from 'ts/interfaces/services/service';
import { getFileFormatFromFile } from 'utils/getFileFormatFromFile';
import useFileUploadAndConvertNewEditorFlow241 from './ab_new_editor_flow_2_4_1/useFileUploadAndConvert';
import useFileUploadAndSplit from './ab_new_editor_flow_2_4_1/useFileUploadAndSplit';
import useFileUploadAndTranslate from './ab_new_editor_flow_2_4_1/useFileUploadAndTranslate';
import useFileUpload from './useFileUpload';
import useFileUploadConvertAndPrint from './useFileUploadConvertAndPrint';

const useHandleSelectFile = ({ service }: { service?: IService }) => {
  const dispatch = useDispatch();

  const { handleUploadFile } = useFileUploadAndConvert({ service });
  const { handleUploadFile: handleUploadFileEditor } = useFileUploadAndEdit({
    service,
  });
  const { handleUploadFile: handleUploadSplitFile } = useFileUploadAndSplit({
    service,
  });
  const { handleUploadFile: handleUploadTranslateFile } = useFileUploadAndTranslate({ service });
  const { handleMergeDocument } = useFileUploadAndMerge({ service });
  const { uploadFile: uploadFileToBackend } = useFileUpload();

  const { handleUploadFile: handleUploadFileConvertAndPrint } = useFileUploadConvertAndPrint({
    service,
  });

  const importExportEditorVariant = useSelector(
    getAbTestVariant(GrowthBookTestType.ABCD_IMPORT_EXPORT_EDITOR_2_4_4)
  );

  // CONVERTOR EDITOR
  const { handleUploadFile: handleUploadFileNewEditorFlow } =
    useFileUploadAndConvertNewEditorFlow241({ service });

  const uploadFile = (file: File) => {
    if (
      !getFileFormatFromFile(file) ||
      FILE_TYPES_NOT_FOR_EDITOR_FLOW.includes(getFileFormatFromFile(file)!.toLowerCase())
    ) {
      if (importExportEditorVariant === TestVariants.D) {
        const handleSendFileViaEmail = (filename: string) => {
          const fileExtension = getFileFormatFromFile(file)?.toLowerCase();
          const fileToSave = new File([file], `${filename}${fileExtension}`);
          uploadFileToBackend(fileToSave).then((fileData) => {
            const fileToSend = {
              ...fileData.fileData,
            };

            dispatch(
              toggleModal({
                type: EModalTypes.SEND_FILE_VIA_EMAIL,
                visible: true,
                options: { file: fileToSend },
              })
            );
          });
        };

        const handlePrintFile = (filename: string) => {
          const fileExtension = getFileFormatFromFile(file)?.toLowerCase();
          const fileToSave = new File([file], `${filename}${fileExtension}`);
          handleUploadFileConvertAndPrint(fileToSave);
        };

        const handleSaveFile = (filename: string, format: string) => {
          const fileExtension = getFileFormatFromFile(file)?.toLowerCase();
          const fileToSave = new File([file], `${filename}${fileExtension}`);
          handleUploadFile(fileToSave, format);
        };

        if (file) {
          dispatch(
            toggleModal({
              type: EModalTypes.CHOOSE_FORMAT_IMPORT_EXPORT_EDITOR_244,
              visible: true,
              options: {
                handleSaveFile,
                handleSendFileViaEmail,
                handlePrintFile,
                file,
              },
            })
          );
        }
        return;
      }
      return handleUploadFile(file);
    }
    handleUploadFileNewEditorFlow(file);
  };

  const uploadFileConverter = (file: File) => {
    if (
      !getFileFormatFromFile(file) ||
      FILE_TYPES_NOT_FOR_EDITOR_FLOW.includes(getFileFormatFromFile(file)!.toLowerCase())
    ) {
      return dispatch(
        toggleModal({
          visible: true,
          type: EModalTypes.CHOOSE_FORMAT_AND_CONVERT,
          options: { file, service },
        })
      );
    }
    handleUploadFileNewEditorFlow(file);
  };

  const uploadFileCompress = (file: File) => {
    dispatch(
      toggleModal({
        visible: true,
        type: EModalTypes.CHOOSE_COMPRESSION_LEVEL,
        options: { file },
      })
    );
  };

  const handleSelectFile = (file: File) => {
    const dictionaryActions: any = {
      [EServiceType.COMPRESSOR]: uploadFileCompress,
      [EServiceType.EDITOR]: handleUploadFileEditor,
      [EServiceType.SPLITTER]: handleUploadSplitFile,
      [EServiceType.CONVERTOR]: uploadFile,
      [EServiceType.MERGER]: handleMergeDocument,
      [EServiceType.OCR]: () => {
        dispatch(
          toggleModal({
            visible: true,
            type: EModalTypes.CHOOSE_FORMAT_AND_PARSE_TEXT,
            options: { file, service },
          })
        );
      },
      [EServiceType.PDF_CONVERTOR]: () => {
        uploadFileConverter(file);
      },
      [EServiceType.TRANSLATE]: handleUploadTranslateFile,
    };

    const action =
      dictionaryActions[service?.serviceType || EServiceType.CONVERTOR] || handleUploadFile;

    if (action) action(file);
  };

  return { handleSelectFile };
};

export default useHandleSelectFile;
