import { FC } from "react"

export const Answer2: FC = () => {
  return(
    <>
    <span>
      Yes, filing Form 433-D online is a convenient option through the
      IRS's Online Payment Agreement (OPA) system. This digital method
      provides a streamlined process, allowing taxpayers to:
    </span>
    <ul>
      <li>
        - Easily set up payment plans in a user-friendly online
        environment.
      </li>
      <li>
        - Avoid the delays that can come with mailing physical forms.
      </li>
    </ul>
    <span>
      Alternatively, taxpayers can still choose to mail Form 433-D if
      they prefer or if their specific situation requires it. It's
      always good to check the IRS website for the most current
      methods and requirements for submitting this form.
    </span>
  </>
  )
}

export const Answer4: FC = () => {
  return(
    <>
    <span>
      Missing a payment under Form 433-D can have significant
      consequences, potentially jeopardizing your installment
      agreement. If you miss a payment:
    </span>
    <ul>
      <li>
        - The IRS may terminate your payment plan, leading to more
        immediate collection actions.
      </li>
      <li>
        - Penalties and interest on the outstanding debt could
        increase.
      </li>
    </ul>
    <span>
      However, the IRS understands that financial situations can
      change. If you're facing difficulty making a payment, it's
      crucial to:
    </span>
    <ul>
      <li>
        - Contact the IRS as soon as possible to discuss your
        situation.
      </li>
      <li>
        - Explore options like modifying your payment terms or a
        temporary delay in payments.
      </li>
    </ul>
    <span>
      Remember, communication with the IRS is key to maintaining your
      installment agreement and managing your tax obligations
      effectively.
    </span>
  </>
  )
}