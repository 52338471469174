export enum InternalFileType {
  DOC = 'DOC',
  DOCX = 'DOCX',
  JPEG = 'JPEG',
  JPG = 'JPG',
  HEIC = 'HEIC',
  HEIF = 'HEIF',
  PDF = 'PDF',
  PNG = 'PNG',
  PPT = 'PPT',
  PPTX = 'PPTX',
  XLS = 'XLS',
  XLSX = 'XLSX',
  ZIP = 'ZIP',
  BMP = 'BMP',
  EPS = 'EPS',
  GIF = 'GIF',
  SVG = 'SVG',
  TIFF = 'TIFF',
  WEBP = 'WEBP',
  EPUB = 'EPUB',
  HTML = 'HTML',
  TEXT = 'TEXT',
  MOBI = 'MOBI',
  AZW3 = 'AZW3',
  DWG = 'DWG',
  CSV = 'CSV',
  DXF = 'DXF',
  DJVU = 'DJVU',
  IMAGE = 'IMAGE',
  TXT = 'TXT',
}

const toMimeType = (type: InternalFileType): string => {
  switch (type) {
    case InternalFileType.DOC:
      return 'application/msword';
    case InternalFileType.DOCX:
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case InternalFileType.JPEG:
      return 'image/jpeg';
    case InternalFileType.JPG:
      return 'image/jpg';
    case InternalFileType.HEIC:
      return 'image/heic';
    case InternalFileType.HEIF:
      return 'image/heif';
    case InternalFileType.BMP:
      return 'image/bmp';
    case InternalFileType.EPS:
      return 'application/postscript';
    case InternalFileType.GIF:
      return 'image/gif';
    case InternalFileType.SVG:
      return 'image/svg+xml';
    case InternalFileType.TIFF:
      return 'image/tiff';
    case InternalFileType.WEBP:
      return 'image/webp';
    case InternalFileType.PDF:
      return 'application/pdf';
    case InternalFileType.PNG:
      return 'image/png';
    case InternalFileType.PPT:
      return 'application/vnd.ms-powerpoint';
    case InternalFileType.PPTX:
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case InternalFileType.XLS:
      return 'application/vnd.ms-excel';
    case InternalFileType.XLSX:
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case InternalFileType.EPUB:
      return 'application/epub+zip';
    default:
      throw new Error('Unknown mime-type');
  }
};

const toMultipleMimetypes = (types: InternalFileType[]): string[] => {
  return types.map((type) => toMimeType(type));
};

const toTitle = (type: InternalFileType | InternalFileType[]): string => {
  const format = Array.isArray(type) ? type[0] : type;
  if ([InternalFileType.DOC, InternalFileType.DOCX].includes(format)) {
    return 'Word';
  }

  if ([InternalFileType.XLS, InternalFileType.XLSX].includes(format)) {
    return 'Excel';
  }

  return format;
};

export const fromMimetype = (mimetype: string): InternalFileType | null => {
  switch (mimetype) {
    case 'application/msword':
      return InternalFileType.DOC;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return InternalFileType.DOCX;
    case 'image/jpeg':
      return InternalFileType.JPEG;
    case 'image/jpg':
      return InternalFileType.JPG;
    case 'image/heic':
      return InternalFileType.HEIC;
    case 'image/heif':
      return InternalFileType.HEIF;
    case 'image/bmp':
      return InternalFileType.BMP;
    case 'application/postscript':
      return InternalFileType.EPS;
    case 'image/gif':
      return InternalFileType.GIF;
    case 'image/svg+xml':
      return InternalFileType.SVG;
    case 'image/tiff':
      return InternalFileType.TIFF;
    case 'image/webp':
      return InternalFileType.WEBP;
    case 'application/pdf':
      return InternalFileType.PDF;
    case 'image/png':
      return InternalFileType.PNG;
    case 'application/vnd.ms-powerpoint':
      return InternalFileType.PPT;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return InternalFileType.PPTX;
    case 'application/vnd.ms-excel':
      return InternalFileType.XLS;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return InternalFileType.XLSX;
    case 'application/epub+zip':
      return InternalFileType.EPUB;
    default:
      return null;
  }
};

export const InternalType = {
  toMimeType,
  toMultipleMimetypes,
  toTitle,
  fromMimetype,
};
export interface ApiFile {
  id: string;
  size: number;
  filename: string;
  created_at: Date;
  aws_url: string;
  internal_type: InternalFileType;
}
