import pdfguru_logo from 'assets/ai-summarizer/pdfguru-logo.png';

export const PdfguruLogo = () => {
  return (
    <img
      src={pdfguru_logo}
      alt="pdfguru logo"
      height={32}
      width={32}
      className="rounded-[32px] max-h-[32px] max-w-[32px]"
    />
  );
};
