import { CompressionLevel } from 'components/modals/compressModal/compressionLevels';

const compressionRates = [
  { range: [0, 1], high: 54.29, medium: 47.21, low: 40.13 },
  { range: [1, 5], high: 73.11, medium: 63.57, low: 54.04 },
  { range: [5, 10], high: 77.16, medium: 67.1, low: 57.03 },
  { range: [10, 20], high: 85.03, medium: 73.93, low: 62.84 },
  { range: [20, 30], high: 89.01, medium: 77.4, low: 65.79 },
  { range: [30, 40], high: 89.58, medium: 77.9, low: 66.21 },
  { range: [40, 50], high: 89.99, medium: 78.25, low: 66.51 },
  { range: [50, 100], high: 94.35, medium: 82.04, low: 69.73 },
  { range: [100, Infinity], high: 68.64, medium: 59.69, low: 50.74 },
];

export function getCompressedSize(fileSizeBytes: number, compressionLevel: CompressionLevel) {
  if (fileSizeBytes <= 0) return { compressedSize: '0', percentageDecrease: 0, sizeUnit: 'KB' };

  const fileSizeMB = fileSizeBytes / (1024 * 1024);

  const rate = compressionRates.find(
    (entry) => fileSizeMB >= entry.range[0] && fileSizeMB < entry.range[1]
  );

  if (!rate) return { compressedSize: '0', percentageDecrease: 0, sizeUnit: 'KB' };

  const compressionRate = rate[compressionLevel];
  const compressedSizeMB = fileSizeMB * ((100 - compressionRate) / 100);

  if (fileSizeMB >= 1) {
    return {
      compressedSize: compressedSizeMB.toFixed(2),
      percentageDecrease: compressionRate,
      sizeUnit: 'MB',
    };
  } else {
    return {
      compressedSize: (compressedSizeMB * 1024).toFixed(1),
      percentageDecrease: compressionRate,
      sizeUnit: 'KB',
    };
  }
}
