import styled from 'styled-components';

export const Container = styled.div`
  .mobile {
    display: none;
  }

  .ant-upload-drag {
    border: none !important;
    background: none !important;

    .ant-upload {
      padding: 0 !important;
    }
  }

  .disable-drag-drop .ant-upload-drag {
    display: none;
  }

  @media (max-width: 760px) {
    width: 100%;

    .ant-upload {
      width: 100%;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;
