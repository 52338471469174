import { ParsedUrlQuery } from 'use-cases/get-query-object'

export interface AnalyticsEvent {
  event: string;
  data?: Record<string, any>;
}

export enum FileUploadingModalViewTypes {
  editFunnels = 'edit funnels',
  convertFunnels = 'convert funnels',
  otherFunnels = 'other funnels',
}

export interface PreviewStatusView extends AnalyticsEvent {
  event: 'preview_status_view';
  data: {
    status: 'success' | 'error' | 'impossible';
  };
}

export interface FileUploadStatus {
  event: 'file_upload_status'
  data: {
    place: 'main' | 'additional'
    status: 'success' | 'fail'
    file_format?: string
    size?: string
    accurate_size?: number
    errorCode?: string | number
  }
}

export interface LandingPageView extends AnalyticsEvent {
  event: 'landing_page_view'
  data: ParsedUrlQuery
  new_landings_1_2_8?: string
}

export type CustomAnalyticsEvent = PreviewStatusView | FileUploadStatus | LandingPageView;
