import { Dispatch } from 'react';

import {
  setCurrentThreadAssistantMessage,
  setCurrentThreadUserMessage,
} from 'data/actions/aiSummarizer';

export const buildThreadMessage = (messages: any, chatId: string, dispatch: Dispatch<any>) => {
  messages.forEach((msg: any) => {
    if (msg.content[0].text.value) {
      if (msg.role === 'assistant') {
        dispatch(
          setCurrentThreadAssistantMessage({
            id: msg.run_id,
            threadId: chatId,
            role: 'assistant',
            message: msg.content[0].text.value,
          })
        );
      } else if (msg.role === 'user') {
        dispatch(setCurrentThreadUserMessage(msg.content[0].text.value));
      }
    }
  });
};
