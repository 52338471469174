import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Analytics } from 'services/analytics';
import { PrimaryButton } from '../../buttons/primary-button';
import { shareFileLink } from 'data/actions/documents';
import { modalOptionsSelector } from 'data/selectors/modals';
import { useDispatch, useSelector } from 'react-redux';
import ModalLayout from 'components/modals/baseModal/base';

export const ShareFileLinkModal = () => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const dispatch = useDispatch();
  const { file } = useSelector(modalOptionsSelector);

  useEffect(() => {
    Analytics.sendEvent({
      event: 'share_file_modal_view',
      data: {
        place: 'my_dashboard',
      },
    });

    if (file) {
      dispatch(
        shareFileLink(file.id, (response) => {
          setShareLink(response?.url || '');
        })
      );
    }
  }, [dispatch, file]);

  const handleCopyLink = () => {
    Analytics.sendEvent({
      event: 'copy_link_file_tap',
      data: {
        place: 'my_dashboard',
      },
    });

    if (!shareLink) return;
      navigator.clipboard.writeText(shareLink).then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      });
  };
  
  return (
    <ModalLayout canClose>
      <div
        className="bg-white rounded-2xl tablet:pt-8 tablet:pb-10 tablet:px-[44px] pt-[44px] pb-4 px-4
        max-w-[657px] w-full relative text-[#000000] flex flex-col"
      >
        <h2 className="text-[25px] text-center font-extrabold leading-[32.5px] mt-6 tablet:mt-6">
          {t('account_page.actions.share_link')}
        </h2>
        <div className="flex flex-col mt-4 tablet:mt-8">
          <div className="mb-2 text-[16px] font-semibold">
            {t('popups.share_link_popup.filename')} {file.filename}
          </div>
          <label htmlFor="fileName" className="right-0">
            {t('popups.share_link_popup.link')}
          </label>
          <div className="flex items-center w-full space-x-4">
            <input
              type="text"
              id="shareLink"
              name="shareLink"
              readOnly
              value={shareLink}
              className="flex-1 rounded-md border border-[#C7C7C7] py-3 px-4 bg-gray-100 cursor-not-allowed text-[16px] w-[400px]"
            />
            <PrimaryButton
              onClick={handleCopyLink}
              className="min-w-[150px] bg-[#D2294B] hover:bg-[#f4476ae0] text-[16px] py-3"
            >
              {isCopied ? t('account_page.actions.copied') : t('account_page.actions.create_copy')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
