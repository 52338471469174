import { FC } from 'react';
import { getCompressedSize } from 'utils/getCompressedSize';
import radio_off from '../assets/radio-off.svg';
import radio_on from '../assets/radio-on.svg';
import classNames from 'classnames';
import { useIsRtl } from 'hooks/isRtl';
import { useTranslation } from 'react-i18next';

interface CompressSelectProps {
  isCompressed: boolean;
  setIsCompressed: (isCompressed: boolean) => void;
  fileSize: number;
  className?: string;
}

export const CompressSelect: FC<CompressSelectProps> = ({
  isCompressed,
  setIsCompressed,
  fileSize,
  className = '',
}) => {
  const { t } = useTranslation();
  const isRtl = useIsRtl();
  const { compressedSize, sizeUnit } = getCompressedSize(fileSize, 'medium');

  const fileSizeMB =
    fileSize / (1024 * 1024) > +1
      ? `${(fileSize / (1024 * 1024)).toFixed(2)} MB`
      : `${(fileSize / 1024).toFixed(2)} KB`;

  return (
    <div
      className={`w-full ${className} flex items-center justify-between hover:cursor-pointer border-[1px] border-[#C7C7C7] rounded-xl p-4`}
      onClick={() => setIsCompressed(!isCompressed)}
    >
      <div>
        <div className="text-[20px] font-bold">{t('popups.choose_format_modal_import_export_editor_244_b.compress.title')}</div>
        <div className="text-[16px] mt-4">{t('popups.choose_format_modal_import_export_editor_244_b.compress.original_size', { size: fileSizeMB })}</div>
      </div>

      <div className="flex flex-col items-end">
        {isCompressed ? (
          <img
            src={radio_on}
            alt="radio-off"
            className={classNames('w-5 tablet:w-7 cursor-pointer', {
              'ml-2': isRtl,
              'mr-2': !isRtl,
            })}
          />
        ) : (
          <img
            src={radio_off}
            alt="radio-off"
            className={classNames('w-5 tablet:w-7 cursor-pointer', {
              'ml-2': isRtl,
              'mr-2': !isRtl,
            })}
          />
        )}
        <div className="flex text-[16px] mt-4">
          {t('popups.choose_format_modal_import_export_editor_244_b.compress.compressed_size')} <div className="font-bold">{' '} ~ {compressedSize}</div>
          <div className="font-bold">{sizeUnit}</div>
        </div>
      </div>
    </div>
  );
};
