import { AreYouSureLeavePageModal } from 'components/modals/areYouSureLeavePage';
import CancelPlanModal from 'components/modals/cancelPlanModal';
import CheckYourEmailModal from 'components/modals/checkYourEmailModal';
import ChooseFormatAndConvertModal from 'components/modals/chooseFormatAndConvertModal';
import ChooseFormatAndParseTextModal from 'components/modals/chooseFormatAndParseTextModal';
import ChooseFormatImportExportEditor244Modal from 'components/modals/chooseFormatImportExportEditor244BModal';
import ChooseFormatModal from 'components/modals/chooseFormatModal';
import ChoseTranslateFormatModal from 'components/modals/choseTranslateFormatModal';
import { CompressModal } from 'components/modals/compressModal';
import DeleteDocumentModal from 'components/modals/deleteDocumentModal';
import DeleteFilesModal from 'components/modals/deleteFilesModal';
import EnterEmailAddressModal from 'components/modals/enterEmailAddressModal';
import EnterEmailTranslateModal from 'components/modals/enterEmailTranslateModal';
import FileUploadErrorModal from 'components/modals/fileErrorModal';
import ForgotPasswordModal from 'components/modals/forgotPasswordModal';
import GetFreeAccessModal from 'components/modals/getFreeAccessModal';
import MergeFiles from 'components/modals/mergeFiles';
import { MergePdfFilePreviewModal } from 'components/modals/mergePdfFilePreviewModal';
import PaymentErrorModal from 'components/modals/paymentErrorModal';
import ProgressEditFileModal from 'components/modals/progressEditFileModal';
import ProgressFileModal from 'components/modals/progressFileModal';
import ProgressModifyFileModal from 'components/modals/progressModifyFileModal';
import ProcessingTranslateModal from 'components/modals/progressTranslateModal';
import ThankYouPaymentModal from 'components/modals/thankYouPaymentModal';
import TrustPilotModal from 'components/modals/trustPilotModal';
import UnderDevelopmentModal from 'components/modals/underDevelopmentModal';
import UploadModalABImportExportEditor244 from 'components/modals/uploadModalABImportExportEditor244';
import { getAbTestVariant } from 'data/selectors/abTests';
import { modalsSelector } from 'data/selectors/modals';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GrowthBookTestType, TestVariants } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';
import RenameFile from '../modals/renameFile';
import { SendFileViaEmail } from '../modals/sendFileViaEmail';
import { ShareFileLinkModal } from '../modals/shareLink';
import { DailyLimitModal } from '../modals/aiSummarizer/dailyLimitModal';
import { MaxSizeFileModal } from '../modals/aiSummarizer/maxFileSizeModal';
import EnterEmailAiSummarizerModal from 'components/modals/enterEmailAiSummarizerModal';

const ModalsController = () => {
  const { type } = useSelector(modalsSelector);

  const importExportVariant = useSelector(
    getAbTestVariant(GrowthBookTestType.ABCD_IMPORT_EXPORT_EDITOR_2_4_4)
  );

  const chooseFormatModalVariant = useMemo(
    () => ({
      [TestVariants.A]: <ChooseFormatModal />,
      [TestVariants.B]: <ChooseFormatModal />,
      [TestVariants.C]: <ChooseFormatModal />,
      [TestVariants.D]: <ChooseFormatImportExportEditor244Modal />,
    }),
    []
  );

  switch (type) {
    case EModalTypes.PROGRESS_FILE:
      return <ProgressFileModal />;

    case EModalTypes.PROGRESS_EDIT_FILE:
      return <ProgressEditFileModal />;

    case EModalTypes.ENTER_EMAIL_ADDRESS:
      return <EnterEmailAddressModal />;

    case EModalTypes.ENTER_EMAIL_AI_SUMMARIZER:
      return <EnterEmailAiSummarizerModal />;

    case EModalTypes.DELETE_DOCUMENT:
      return <DeleteDocumentModal />;

    case EModalTypes.CHECK_YOUR_EMAIL:
      return <CheckYourEmailModal />;

    case EModalTypes.PAYMENT_ERROR:
      return <PaymentErrorModal />;

    case EModalTypes.FILE_UPLOAD_ERROR:
      return <FileUploadErrorModal />;

    case EModalTypes.THANK_YOU_PAYMENT:
      return <ThankYouPaymentModal />;

    case EModalTypes.CHOOSE_FORMAT:
      return chooseFormatModalVariant[importExportVariant];

    case EModalTypes.CHOOSE_FORMAT_IMPORT_EXPORT_EDITOR_244:
      return <ChooseFormatImportExportEditor244Modal />;

    case EModalTypes.CHOOSE_FORMAT_AND_CONVERT:
      return <ChooseFormatAndConvertModal />;

    case EModalTypes.PROCESSING_COMPRESS:
      return <ProgressModifyFileModal type="compress" />;

    case EModalTypes.PROCESSING_SPLIT:
      return <ProgressModifyFileModal type="split" />;

    case EModalTypes.TRUST_PILOT:
      return <TrustPilotModal />;

    case EModalTypes.PROCESSING_MERGE:
      return <ProgressModifyFileModal type="merge" />;

    case EModalTypes.GET_FREE_ACCESS:
      return <GetFreeAccessModal />;

    case EModalTypes.CANCEL_PLAN:
      return <CancelPlanModal />;

    case EModalTypes.FORGOT_PASSWORD:
      return <ForgotPasswordModal />;

    case EModalTypes.CHOOSE_FORMAT_AND_PARSE_TEXT:
      return <ChooseFormatAndParseTextModal />;

    case EModalTypes.CHOSE_TRANSLATE_FORMAT:
      return <ChoseTranslateFormatModal />;

    case EModalTypes.PROGRESS_TRANSLATE_FILE:
      return <ProcessingTranslateModal />;

    case EModalTypes.ENTER_EMAIL_TRANSLATE:
      return <EnterEmailTranslateModal />;

    case EModalTypes.MERGE_FILES:
      return <MergeFiles />;

    case EModalTypes.SHARE_FILE_LINK:
      return <ShareFileLinkModal />;

    case EModalTypes.SEND_FILE_VIA_EMAIL:
      return <SendFileViaEmail />;

    case EModalTypes.UNDER_DEVELOPMENT:
      return <UnderDevelopmentModal />;

    case EModalTypes.RENAME_FILE:
      return <RenameFile />;

    case EModalTypes.DELETE_FILES:
      return <DeleteFilesModal />;

    case EModalTypes.MERGE_PDF_FILE_PREVIEW_MODAL:
      return <MergePdfFilePreviewModal />;

    case EModalTypes.ARE_YOU_SURE_LEAVE_PAGE:
      return <AreYouSureLeavePageModal />;

    case EModalTypes.CHOOSE_COMPRESSION_LEVEL:
      return <CompressModal />;

    case EModalTypes.AI_SUMMARIZER_DAILY_LIMIT:
      return <DailyLimitModal />;

    case EModalTypes.AI_SUMMARIZER_MAX_SIZE_FILE:
      return <MaxSizeFileModal />;
    case EModalTypes.UPLOAD_MODAL_AB_IMPORT_EXPORT_EDITOR_2_4_4:
      return <UploadModalABImportExportEditor244 />;

    default:
      return null;
  }
};

export default ModalsController;
