import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ModalLayout from 'components/modals/baseModal/base';
import { Analytics } from 'services/analytics';
import s from './purchase-failed.module.scss';
import { LoadingAnimation } from 'components/loading-animation';

const FileUploadErrorModal: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    Analytics.sendEvent({ event: 'converting_failed_modal_view' });
  }, []);

  return (
    <ModalLayout canClose>
      <div className={s.convertingPopup}>
        <LoadingAnimation currentState={'error'} className={s.animation} />
        <h2>{t('popups.converting_failed.title')}</h2>
      </div>
    </ModalLayout>
  );
};

export default FileUploadErrorModal;
