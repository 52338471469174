/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import classNames from 'classnames';
import { IS_MOBILE_KEY } from 'providers/abTests';
import s from 'sections/header-for-upload-section/header-for-upload-section.module.scss';
import { useHeaderUploadSectionInteractor } from 'sections/header-for-upload-section/interactor';

import { PrimaryButton } from 'components/buttons/primary-button';
import LocalizedLink from 'components/localized-link';
import { LogoNewDesign } from 'components/logo-new-design';

import { PAGE_LINKS } from 'ts/constants/page-links';

export const AISummarizerHeader = () => {
  const interactor = useHeaderUploadSectionInteractor();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const isMobile = searchParams.get(IS_MOBILE_KEY) === 'true';

  const renderActionButtons = useCallback(() => {
    if (interactor.isAuthorized) {
      return (
        <>
          <PrimaryButton
            onClick={interactor.onDashboard}
            className={classNames(
              {
                '!border-[2px] !py-2 !px-4 !min-w-0 !rounded-[8px]': isMobile,
              },
              s.dashboardButton,
              { 'bg-[#F6F6F6]': interactor.convertDropDown.toggle },
              {
                '!bg-[#FFFFFF] !text-[#1D1D1D]': interactor.convertDropDown.isOpen,
              }
            )}
          >
            <LocalizedLink
              href={PAGE_LINKS.DASHBOARD}
              className={classNames({
                'text-base leading-[114%] !w-max': isMobile,
              })}
              data-testid="ai-summarizer-dashboard-link"
            >
              {t('header.dashboard')}
            </LocalizedLink>
          </PrimaryButton>
        </>
      );
    }

    return null;
  }, [
    interactor.convertDropDown.isOpen,
    interactor.convertDropDown.toggle,
    interactor.isAuthorized,
    interactor.onDashboard,
    isMobile,
    t,
  ]);

  return (
    <>
      <div
        className={classNames(
          'py-5 px-4 bg-[#F6F6F6] shadow-[1px_1px_2px_0px_rgba(29,29,29,0.08)] flex justify-between items-center small-desktop:hidden'
        )}
      >
        <a href={PAGE_LINKS.MAIN_SEO} data-testid="ai-summarizer-header-home-link">
          <LogoNewDesign />
        </a>

        {renderActionButtons()}
      </div>

      <div
        className={classNames(
          'justify-between px-6 py-3 border-b border-l border-[#E9E9E9] hidden small-desktop:flex'
        )}
      >
        <h1 className="text-[25px] w-max py-2 px-6 font-bold">{t('ai_summarizer.title')}</h1>

        <div className={s.desktopNav}>{renderActionButtons()}</div>
      </div>
    </>
  );
};
