import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  downloadDocument,
  getMergedFile,
  getUploadLink,
  mergeDocuments,
  setConvertDocumentData,
  setMergeDocumentData,
  updateDownloadProgress,
} from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';

// Selectors
import {
  isUserAuthenticated,
  userEmailSelector,
  userSubscriptionSelector,
} from 'data/selectors/user';

// Helpers
import { countPdfPages } from 'helpers/countPdfPages';
import { getFileKeyFromAWSLink } from 'helpers/getFileKeyFromAWSLink';
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';
import { Analytics } from 'services/analytics';

// Typescript Types
import { PAGE_LINKS } from 'ts/constants/page-links';
import { EModalTypes } from 'ts/enums/modal.types';
import { IMergeDocumentDto } from 'ts/interfaces/documents/document';

const useFileUploadAndMerge = ({ service }: { service?: any }) => {
  const navigate = useLocaleNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector(isUserAuthenticated);
  const userSubscription = useSelector(userSubscriptionSelector);
  const email = useSelector(userEmailSelector);

  const analyticsEventUpload = ({
    success,
    size,
    errorCode,
    fileCounter,
  }: {
    success: boolean;
    size: number;
    errorCode?: number;
    fileCounter?: number;
  }) => {
    void Analytics.sendEvent({
      event: 'file_upload_status',
      data: {
        status: success ? 'success' : 'fail',
        place: 'additional',
        errorCode,
        accurate_size: size / 1000000,
        fileCounter: fileCounter || 1,
        file_format: '.pdf',
        is_validation_error: success ? 'false' : 'true',
      },
    });
  };

  const handleMergeDocument = (
    dataToMerge: IMergeDocumentDto[],
    signUp?: boolean,
    subscription?: any
  ) => {
    if (!userSubscription && !subscription?.id) {
      dispatch(updateDownloadProgress(100));
      return setTimeout(
        () => {
          dispatch(mergeDocuments(dataToMerge));
          dispatch(toggleModal({ visible: false }));
          navigate(PAGE_LINKS.CHOOSING_PLAN);
          window.scrollTo(0, 0); // Scrolls to the top of the page
        },
        signUp ? 0 : 1000
      );
    }

    const onSuccess = (res: any) => {
      let countRetries = 0;
      const interval = setInterval(() => {
        const onSuccessCallback = (res: any) => {
          clearInterval(interval);

          // awaiting the downloading animation
          setTimeout(() => {
            if (res?.processing_status === 'FAILED')
              return dispatch(
                toggleModal({
                  type: EModalTypes.FILE_UPLOAD_ERROR,
                  visible: true,
                })
              );

            dispatch(toggleModal({ visible: false }));

            dispatch(downloadDocument(res?.id));
          }, 1000);
        };
        countRetries += 1;
        if (countRetries < 45) dispatch(getMergedFile(res?.fileId, onSuccessCallback));
        else clearInterval(interval);
      }, 1500);
    };

    const onFailed = () => {
      dispatch(updateDownloadProgress(100));

      setTimeout(() => {
        navigate(PAGE_LINKS.CHOOSING_PLAN);
        window.scrollTo(0, 0); // Scrolls to the top of the page
      }, 1000);
    };

    dispatch(mergeDocuments(dataToMerge, onSuccess, onFailed));
  };

  const handleMergeFiles = (dataToMerge: IMergeDocumentDto[]) => {
    if (!isAuth) {
      setTimeout(() => {
        dispatch(updateDownloadProgress(100));
      }, 500);
      return setTimeout(() => {
        dispatch(
          toggleModal({
            type: EModalTypes.ENTER_EMAIL_ADDRESS,
            visible: true,
            options: {
              handleConvertDocument: (subscription: any) =>
                handleMergeDocument(dataToMerge, true, subscription),
              signUp: true,
              servicePath: service?.path,
            },
          })
        );
      }, 1000);
    }
    handleMergeDocument(dataToMerge);
  };

  const handleUploadFilesByLinkToS3 = async (files: File[], uploadLinks: string[]) => {
    Analytics.sendEvent({
      event: 'upload_link_received',
    });
    try {
      dispatch(toggleModal({ type: EModalTypes.PROGRESS_EDIT_FILE, visible: true }));
      const resData: any = await Promise.allSettled(
        uploadLinks.map((link, index) => axios.put(link, files[index], { headers: {} }))
      );

      const fulfilledResponses = resData
        .filter((result: any) => result.status === 'fulfilled')
        .map((result: any) => result.value);

      if (files.length !== fulfilledResponses.length) {
        console.error('Error uploading file:----->');
      }

      const keys = fulfilledResponses.map((item: any) =>
        getFileKeyFromAWSLink(item?.request?.responseURL)
      );

      const pagesCount = await Promise.all(
        files.map(async (file) => (await countPdfPages(file)) || 1)
      );

      const dataToMerge: IMergeDocumentDto[] = files.map((file, index) => ({
        filename: file?.name,
        size: file.size,
        key: keys[index],
        pagesCount: pagesCount[index],
        orderIndex: index,
      }));
      // awaiting the downloading animation
      setTimeout(() => {
        dispatch(
          setConvertDocumentData({
            serviceType: service?.serviceType,
            filename: 'merged.pdf',
            size: files[0]?.size,
            key: keys[0],
            pagesCount: 1,
            from: 'PDF',
            to: 'PDF',
            url: '',
          })
        );

        // set converted data to localStorage and use this data for google auth
        localStorage.setItem(
          'dataToConvert',
          JSON.stringify({
            file: dataToMerge,
            service: service?.path,
            email: email,
            serviceType: service?.serviceType,
          })
        );

        analyticsEventUpload({ size: files[0]?.size, success: true });
        handleMergeFiles(dataToMerge);
      }, 5000);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // upload to s3 bucket and convert file
  const handleUploadFiles = (files: File[]) => {
    if (!files?.length) return;

    void Analytics.sendEvent({
      event: 'file_from_provider_chosen',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        method: 'click',
      },
    });

    dispatch(
      getUploadLink({
        filename: files?.map((file) => file?.name),
        onSuccess: (res: any) => {
          handleUploadFilesByLinkToS3(
            files,
            res.map((item: any) => item?.url)
          );
        },
        onFailed: () => {
          Analytics.sendEvent({ event: 'complete_merge_error' });
        },
        service,
      })
    );
  };

  const handleSetMergeFilesList = useCallback(
    (filesList: { file: File; thumbnail?: string | null }[]) => {
      dispatch(setMergeDocumentData(filesList));
    },
    [dispatch]
  );

  return {
    handleSetMergeFilesList,
    handleUploadFiles,
    handleMergeDocument,
  };
};

export default useFileUploadAndMerge;
