export const getABTestsFormatted = () => {
  // Try localStorage first
  try {
    const isAutoTest = !!localStorage.getItem('isAutoTest');
    if (isAutoTest) {
      return {
        abTests: [],
      };
    }

    const stored = localStorage.getItem('abTests');

    if (stored) {
      const storedAbTests = JSON.parse(stored);

      // @NOTE: old format, delete after dashboard-test, abcdefgh-sales-test and new-editor-flow-test are finished
      if (Array.isArray(storedAbTests) && storedAbTests.length > 0) {
        // Check if first item is a string (old format)
        if (typeof storedAbTests[0] === 'string') {
          // Convert from "feature_variant" strings to {feature, variant} objects
          const formattedTests = storedAbTests.map((test) => {
            const variant = test.slice(-1);
            const feature = test.slice(0, -2);
            return {
              feature,
              variant,
            };
          });
          return {
            abTests: formattedTests,
          };
        }
      }
      return {
        abTests: storedAbTests,
      };
    }
  } catch {
    return {
      abTests: [],
    };
  }

  return {
    abTests: [],
  };
};
