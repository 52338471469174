import how_to_img from 'assets/img/how-to-section/how-to-new-design-merge-pdf.svg';
import icon_1 from 'assets/img/how-to-section/icon_1.svg';
import icon_2 from 'assets/img/how-to-section/icon_2.svg';
import icon_3 from 'assets/img/how-to-section/icon_3.svg';
import { FEATURE_IDS } from 'ts/constants/general';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';
import merge_pdf from './assets/merge-pdf.svg';

export const mergePageService = (t: any) => {
  return {
    serviceType: EServiceType.MERGER,
    path: 'merge-pdf',
    mainColor: 'berry',
    uploadSectionImage: merge_pdf,
    uploadSection: {
      from: InternalFileType.PDF,
      headline: t('landing.merge_pdf.h1.title'),
      subtitle_1: t('landing.merge_pdf.h1.subtitle'),
      to: InternalFileType.PDF,
    },
    howTo: {
      headline_1: t('landing.merge_pdf.how_to.h2'),
      img: how_to_img,
      description: [
        {
          icon: icon_1,
          text: t('landing.merge_pdf.how_to.first'),
        },
        {
          icon: icon_2,
          text: t('landing.merge_pdf.how_to.second'),
        },
        {
          icon: icon_3,
          text: t('landing.merge_pdf.how_to.third'),
        },
      ],
    },
    name: t('meta.merge_pdf.title'),
    nameKey: 'merge_pdf.merge_pdf.name',
    title: t('meta.merge_pdf.title'),
    description: t('meta.merge_pdf.description'),
    featureId: FEATURE_IDS.MERGE,
    fag: {
      question: 'How to organize multiple PDFs documents?',
      answer:
        '1. Upload PDF files you would like to be combined. 2. Arrange files in the desired order, delete unnecessary. 3. Download or share your merged PDF file.',
    },
    from: 'PDF',
    to: 'DOCX',
    availableFormats: '.pdf',
  };
};
