import { TestVariants } from 'ts/enums/growthbook';

export enum SolidTrialPaymentAction {
  'charge' = 'charge',
  'charge_refund' = 'charge_refund',
  'auth_void' = 'auth_void',
  'auth_settle' = 'auth_settle',
}

export enum PaymentPlanId {
  MONTHLY = 'pdf_guru_monthly_49_99_in_all_countries_with_trial_0_99_local_new_PLN',
  MONTHLY_FULL = 'pdf_guru_monthly_49_99_in_all_countries_with_trial_1_99_local_new_PLN',
  ANNUAL = 'pdf_guru_yearly_299_00_no_trial_V2',
  MONTHLY_CANCEL_DISCOUNT = 'pdf_guru_monthly_49_99_70%_discount_local_V2',

  MONTHLY_GERMANY = 'pdf_guru_monthly_49_99_GER_with_trial_0_99',
  MONTHLY_FREE_GERMANY = 'pdf_guru_monthly_49_99_GER_with_trial_0_99_FREE',
  MONTHLY_FULL_GERMANY = 'pdf_guru_monthly_49_99_GER_with_trial_1_99',
  ANNUAL_GERMANY = 'pdf_guru_yearly_299_00_no_trial_GER',

  MONTHLY_JP = 'pdf_guru_monthly_49_99_in_Japan_with_trial_0_99_JPY_V2',
  MONTHLY_FULL_JP = 'pdf_guru_monthly_49_99_in_Japan_with_trial_1_99_JPY_V2',
  ANNUAL_JP = 'pdf_guru_yearly_299_00_in_Japan_no_trial_JPY',
  MONTHLY_CANCEL_DISCOUNT_JP = 'pdf_guru_monthly_49_99_70%_discount_in_Japan_JPY_V2',

  MONTHLY_LATAM = 'pdf_guru_monthly_29_99_LatAm_with_trial_0_99',
  MONTHLY_FULL_LATAM = 'pdf_guru_monthly_29_99_LatAm_with_trial_1_99',
  ANNUAL_LATAM = 'pdf_guru_yearly_159_00_no_trial_LatAm',
  MONTHLY_CANCEL_DISCOUNT_LATAM = 'pdf_guru_monthly_29_99_70%_discount_LatAm',

  MONTHLY_B_242 = 'pdf_guru_monthly_49_99_in_all_countries_with_trial_0_99_local_3days_trial',
  MONTHLY_FULL_C_242 = 'pdf_guru_monthly_49_99_in_all_countries_with_trial_1_99_local_14days_trial',
  MONTHLY_D_242 = 'pdf_guru_monthly_49_99_in_all_countries_with_trial_0_99_local_14days_trial',

  MONTHLY_FULL_JP_C_242 = 'pdf_guru_monthly_49_99_in_Japan_with_trial_1_99_JPY_14_days_trial',
  MONTHLY_JP_D_242 = 'pdf_guru_monthly_49_99_in_Japan_with_trial_0_99_JPY_14_days_trial',

  MONTHLY_FULL_LATAM_C_242 = 'pdf_guru_monthly_29_99_LatAm_with_trial_1_99_14_days_trial',
  MONTHLY_LATAM_D_242 = 'pdf_guru_monthly_29_99_LatAm_with_trial_0_99_14_days_trial',

  MONTHLY_THIRD_EMAIL = 'pdf_guru_monthly_49_99_50%_discount_with_trial_free',
  MONTHLY_FULL_THIRD_EMAIL = 'pdf_guru_monthly_49_99_50%_discount_with_trial_free_full',
  MONTHLY_SECOND_EMAIL = 'pdf_guru_monthly_49_99_50%_discount_with_trial_0_99',
  MONTHLY_FULL_SECOND_EMAIL = 'pdf_guru_monthly_49_99_50%_discount_with_trial_0_99_full',

  MONTHLY_SECOND_EMAIL_JP = 'pdf_guru_monthly_49_99_50%_discount_with_trial_0_99_Japan_JPY',
  MONTHLY_FULL_SECOND_EMAIL_JP = 'pdf_guru_monthly_49_99_50%_discount_with_trial_0_99_full_Japan_JPY',
}

export const usualPlansKeys: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY,
  PaymentPlanId.MONTHLY_FULL,
  PaymentPlanId.ANNUAL,
];

export const japanPlansKeys: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_JP,
  PaymentPlanId.MONTHLY_FULL_JP,
  PaymentPlanId.ANNUAL_JP,
];

export const PlansKeysJapanC242: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_JP,
  PaymentPlanId.MONTHLY_FULL_JP_C_242,
  PaymentPlanId.ANNUAL_JP,
];

export const PlansKeysJapanD242: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_JP_D_242,
  PaymentPlanId.MONTHLY_FULL_JP_C_242,
  PaymentPlanId.ANNUAL_JP,
];

export const latamPlansKeys: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_LATAM,
  PaymentPlanId.MONTHLY_FULL_LATAM,
  PaymentPlanId.ANNUAL_LATAM,
];

export const PlansKeysLatamC242: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_LATAM,
  PaymentPlanId.MONTHLY_FULL_LATAM_C_242,
  PaymentPlanId.ANNUAL_LATAM,
];

export const PlansKeysLatamD242: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_LATAM_D_242,
  PaymentPlanId.MONTHLY_FULL_LATAM_C_242,
  PaymentPlanId.ANNUAL_LATAM,
];

export const PlansKeysGermany: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_GERMANY,
  PaymentPlanId.MONTHLY_FULL_GERMANY,
  PaymentPlanId.ANNUAL_GERMANY,
];

export const PlansKeysGermanyBFreeTrial: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_FREE_GERMANY,
  PaymentPlanId.MONTHLY_FULL_GERMANY,
  PaymentPlanId.ANNUAL_GERMANY,
];

export const PlansKeysB242: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_B_242,
  PaymentPlanId.MONTHLY_FULL,
  PaymentPlanId.ANNUAL,
];

export const PlansKeysC242: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY,
  PaymentPlanId.MONTHLY_FULL_C_242,
  PaymentPlanId.ANNUAL,
];

export const PlansKeysD242: PaymentPlanId[] = [
  PaymentPlanId.MONTHLY_D_242,
  PaymentPlanId.MONTHLY_FULL_C_242,
  PaymentPlanId.ANNUAL,
];

type Price = {
  country: string | null;
  currency: string;
  price: number;
  trial_price: number;
};

export type Product = {
  id: string;
  name: PaymentPlanId;
  trial_payment_action: SolidTrialPaymentAction | null;
  price: Price;
};
interface IGetSubscriptionProducts {
  plans: Product[];
  cancelPlan: Product | null;
}

export const latamCountries = [
  'BR',
  'MX',
  'CO',
  'AR',
  'PE',
  'VE',
  'CL',
  'GT',
  'EC',
  'BO',
  'HT',
  'CU',
  'DO',
  'HN',
  'PY',
  'SV',
  'NI',
  'CR',
  'PA',
  'UY',
  'PR',
  'GP',
  'MQ',
  'GF',
  'MF',
  'BL',
  'JM',
];

export const getSubscriptionProducts = (
  userCountry: string,
  solidProducts: any,
  newSalesVariant: string,
  germanyFreeTrial: string
): IGetSubscriptionProducts => {
  let plans = [];
  let cancelPlan = null;
  let productsKeys: PaymentPlanId[] = usualPlansKeys;
  let cancelSubscriptionKey: PaymentPlanId = PaymentPlanId.MONTHLY_CANCEL_DISCOUNT;

  if (userCountry === 'JP') {
    if (newSalesVariant === TestVariants.G) {
      productsKeys = PlansKeysJapanC242;
    } else if (newSalesVariant === TestVariants.H) {
      productsKeys = PlansKeysJapanD242;
    } else {
      productsKeys = japanPlansKeys;
    }
    cancelSubscriptionKey = PaymentPlanId.MONTHLY_CANCEL_DISCOUNT_JP;
  } else if (latamCountries.includes(userCountry)) {
    if (newSalesVariant === TestVariants.G) {
      productsKeys = PlansKeysLatamC242;
    } else if (newSalesVariant === TestVariants.H) {
      productsKeys = PlansKeysLatamD242;
    } else {
      productsKeys = latamPlansKeys;
    }
    cancelSubscriptionKey = PaymentPlanId.MONTHLY_CANCEL_DISCOUNT_LATAM;
  } else if (userCountry === 'DE') {
    if (germanyFreeTrial === TestVariants.B) {
      productsKeys = PlansKeysGermanyBFreeTrial;
    } else {
      productsKeys = PlansKeysGermany;
    }
    cancelSubscriptionKey = PaymentPlanId.MONTHLY_CANCEL_DISCOUNT;
  } else if (newSalesVariant === TestVariants.F) {
    productsKeys = PlansKeysB242;
  } else if (newSalesVariant === TestVariants.G) {
    productsKeys = PlansKeysC242;
  } else if (newSalesVariant === TestVariants.H) {
    productsKeys = PlansKeysD242;
  }

  const productsToSort = solidProducts?.filter((item: any) =>
    [...productsKeys, cancelSubscriptionKey].includes(item.name)
  );

  plans = productsKeys.map(
    (key) => productsToSort.find((item: Product) => item.name === key) || null
  );
  cancelPlan = productsToSort.find((item: Product) => item.name === cancelSubscriptionKey) || null;

  return {
    plans,
    cancelPlan,
  };
};
