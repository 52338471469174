import { AnyAction } from 'redux';
import { EReduxTypes } from 'ts/enums/redux.types';
import { IAbTest } from 'ts/interfaces/abTest/abTest';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const setAbTests = (data: IAbTest[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_AB_TESTS,
    data,
  };
  return action;
};
