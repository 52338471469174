import userIcon from 'assets/ai-summarizer/user.svg';

interface UserResponseProps {
  message: string;
}

export const UserResponse = ({ message }: UserResponseProps) => {
  return (
    <div className="text-base leading-[130%] bg-[#F6F7FD] rounded-[16px] flex justify-between py-3 px-4">
      <span>{message}</span>
      <img src={userIcon} alt="user" width={24} height={24} />
    </div>
  );
};
