import { useCallback } from 'react';

import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { LogoNewDesign } from 'components/logo-new-design';

import { isMobileMenuOpenSelector } from 'data/selectors/aiSummarizer';

import { PAGE_LINKS } from 'ts/constants/page-links';

import { OpenMenuSubheader } from '../subheader/open-menu-subheader';
import { Account } from './components/account';
import { ChatHistory } from './components/chat-history';
import { NewChatButton } from './components/new-chat-button';

export const Sidebar = () => {
  const isMobileMenuOpen = useSelector(isMobileMenuOpenSelector);

  const renderSidebarContent = useCallback(
    () => (
      <div className="flex flex-col gap-10 h-full">
        <NewChatButton />
        <ChatHistory />
      </div>
    ),
    []
  );

  const mobileMenuClasses = classNames(
    'h-full w-full p-4 pb-[60px] small-desktop:pb-0 justify-between flex flex-col fixed right-0 transition-all duration-300 z-[1000] bg-white shadow-lg small-desktop:hidden top-0',
    {
      'translate-x-[0]': isMobileMenuOpen,
      'translate-x-[100%]': !isMobileMenuOpen,
    }
  );

  return (
    <>
      <div className="h-screen w-1/5 min-w-[280px] p-6 justify-between hidden small-desktop:flex small-desktop:flex-col border-r border-[#E9E9E9]">
        <div className="flex flex-col gap-10 h-full">
          <a href={PAGE_LINKS.MAIN_SEO} data-testid="ai-summarizer-home-link">
            <LogoNewDesign />
          </a>
          {renderSidebarContent()}
        </div>
        <Account />
      </div>

      <div className={mobileMenuClasses}>
        <a href={PAGE_LINKS.MAIN_SEO} className="py-5 px-4" data-testid="ai-summarizer-mobile-home-link">
          <LogoNewDesign />
        </a>

        <OpenMenuSubheader />
        {renderSidebarContent()}
        <Account />
      </div>
    </>
  );
};
