import React from 'react';

import classNames from 'classnames';

import styles from './index.module.scss';

export interface IProps {
  progress: number;
  className?: string;
}

export const ProgressBar: React.FC<IProps> = ({ progress, className }) => {
  return (
    <div
      className={classNames(styles.progress, className)}
      style={{
        // @ts-ignore
        ['--progress']: `${progress}%`, // eslint-disable-line
      }}
    />
  );
};
