import { locales } from 'services/analytics';

import { appStorage } from 'utils/appStorage';

const PREV_PATH_KEY = 'prevPath';
// Will be called on landings and account
export const savePrevPath = (pathname?: string) => {
  const path = pathname || window.location.pathname;
  const woSlash = path.replace('/', '').replace('v1/', '');
  const woLocales =
    locales.includes(woSlash.slice(4, 6)) && woSlash.slice(4, 6).length === 2 && woSlash[6] === '/'
      ? `app${woSlash.slice(6)}`
      : woSlash;

  if (path === '/' || path === 'main_page') {
    appStorage.setItem(PREV_PATH_KEY, 'main_page');
    return;
  }

  // converters
  if (path.includes('-to-')) {
    appStorage.setItem(PREV_PATH_KEY, woLocales.replace(/-/g, '_'));
    return;
  }

  const defaultPaths = [
    'account',
    'editor',
    'merge-pdf',
    'split-pdf',
    'compress-pdf',
    'translate-pdf',
    'sign-pdf',
    'password-protect-pdf',
    'pdf-converter',
    'form-w9',
    'form-i9',
    'form-ds11',
    'purchase-success',
    'form-w2',
    'form-w3',
    'form-941',
    'form-1099',
    'form-1099-misc',
    'form-1099-nec',
    'complete-merge',
    'merge-img',
    'edit-pdf',
    'pdf-ocr',
    'fill-pdf',
    'pdf-editor',
    'translate-pdf',
    'ai-summarizer',
  ];

  if (defaultPaths.includes(woLocales?.replace('app/', ''))) {
    appStorage.setItem(PREV_PATH_KEY, woLocales);
    return;
  }
};

export const getPrevPage = () => {
  return appStorage.getItem(PREV_PATH_KEY);
};
