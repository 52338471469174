import { IUser } from 'ts/interfaces/user/user';
import { ICurrentThreadData } from 'data/reducers/aiSummarizer';

export const validateLimit = async (
  user: IUser,
  // currentThreadData: ThreadState['currentThreadData']
  currentThreadData: ICurrentThreadData[]
) => {
  try {
    const hasSubscription = !!user?.subscription;
    const userMessages = currentThreadData?.filter((msg) => msg.role === 'user') || [];
    const userMessageCount = userMessages.length;

    console.log('Limit check ===>', { hasSubscription, userMessageCount });

    const has4OrMoreMessages = userMessageCount >= 4;

    return !hasSubscription && has4OrMoreMessages;
  } catch (error) {
    console.error('Error checking subscription:', error);
    return false;
  }
};
