import { PrimaryButton } from 'components/buttons/primary-button';
import ModalLayout from 'components/modals/baseModal/base';
import { modalOptionsSelector } from 'data/selectors/modals';
import { editorServicesList, EEditorServices } from 'helpers/services/editorServicesList';
import useFileUploadAndCompressNewEditorFlow241, {
  COMPRESSED_SIZE_KEY,
} from 'hooks/fileFlows/ab_new_editor_flow_2_4_1/useFileUploadAndCompress';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Analytics } from 'services/analytics';
import { EModalTypes } from 'ts/enums/modal.types';
import { getCompressedSize } from 'utils/getCompressedSize';
import { CompressionLevel, CompressionLevelsList } from './compressionLevels';

export const CompressModal = () => {
  const { file } = useSelector(modalOptionsSelector);
  const { t } = useTranslation();
  const [currentCompressionLevel, setCurrentCompressionLevel] = useState<CompressionLevel>('high');

  const handleSetCompressionLevel = (level: any) => {
    setCurrentCompressionLevel(level);
    Analytics.sendEvent({
      event: 'file_compress_modal_select_type_tap',
      data: { type: level },
    });
  };

  const { handleUploadFile } = useFileUploadAndCompressNewEditorFlow241({
    service: editorServicesList(t).find((service) => service.key === EEditorServices.COMPRESS_PDF),
    modalType: EModalTypes.PROGRESS_EDIT_FILE,
  });

  useEffect(() => {
    Analytics.sendEvent({
      event: 'file_compress_modal_view',
    });
  }, []);

  const onClosePopup = () => {
    Analytics.sendEvent({
      event: 'compress_select_type_close_tap',
    });
  };

  const formatOriginalSize = (fileSizeBytes: number) => {
    if (fileSizeBytes <= 0) return '0 KB';

    const oneMB = 1024 * 1024;
    const fileSizeMB = fileSizeBytes / oneMB;

    if (fileSizeMB >= 1) {
      return `${fileSizeMB.toFixed(2)} MB`;
    } else {
      const fileSizeKB = fileSizeBytes / 1024;
      return `${fileSizeKB.toFixed(2)} KB`;
    }
  };

  const handleConfirm = () => {
    if (currentCompressionLevel !== null) {
      handleUploadFile(file);
    }

    Analytics.sendEvent({
      event: 'compres_select_type_confirm_tap',
      data: { type: currentCompressionLevel },
    });

    const { compressedSize, percentageDecrease, sizeUnit } = getCompressedSize(
      file.size,
      currentCompressionLevel
    );
    localStorage.setItem(
      COMPRESSED_SIZE_KEY,
      JSON.stringify({
        compressedSize: compressedSize,
        percentage: percentageDecrease,
        originalSize: formatOriginalSize(file.size),
        sizeUnit: sizeUnit,
      })
    );
  };

  return (
    <ModalLayout canClose dataTestId="choose-compression-level-modal" closePopup={onClosePopup}>
      <div className="flex flex-col items-center w-full mobile:min-w-[630px] tablet:w-[887px] pt-10 pb-8 mobile:pt-8 mobile:pb-7 tablet:pt-11 tablet:pb-[48px] px-4 tablet:px-[48px]">
        <h2 className="mb-6 text-[#1D1D1D] font-[900] text-[22px] leading-[30px] tablet:text-[39px] tablet:leading-[54px] text-center">
          {t('popups.processing_compress_popup.title')}
        </h2>

        <div className="w-full">
          <div className="flex justify-between w-full overflow-hidden">
            <span className="text-[#575757]" data-testid="compress-file-name">
              {file?.name}
            </span>
            <span className="text-[#575757]" data-testid="compress-file-size">
              {formatOriginalSize(file.size)}
            </span>
          </div>
          <hr className="w-full border-[#E0E0E0] mt-2" />
          <h2 className="text-[#575757] mb-4 mt-9 font-bold text-[20px]">
            {t('popups.processing_compress_popup.subtitle')}
          </h2>
        </div>

        <div className="flex flex-wrap justify-center gap-1 mobile:gap-4 mb-4 w-full ">
          <CompressionLevelsList
            file={file}
            currentCompressionLevel={currentCompressionLevel}
            handleSetCompressionLevel={handleSetCompressionLevel}
          />
        </div>

        <PrimaryButton
          dataTestId="compress-button"
          onClick={handleConfirm}
          disabled={!file.name || currentCompressionLevel === null}
          className="min-w-[145px] max-w-[390px] w-full border mobile:mt-6 border-solid border-[#D2294B] py-[19px] mobile:py-3 hover:border-[#f4476ae0] bg-[#D2294B] hover:bg-[#f4476ae0] mobile:!text-[20px] mobile:!leading-[30px] mobile:px-[20px]"
        >
          {t('popups.processing_compress_popup.button_label')}
        </PrimaryButton>
      </div>
    </ModalLayout>
  );
};
