import apiEndpoints from 'use-cases/ai-summarizer/api/api';

import { Logger } from 'utils/logger';

import { GetMessageByRunIdResponse } from 'ts/interfaces/ai-summarizer/api';

const logger = new Logger('uploadPDF');

const getThreadContent = (data: GetMessageByRunIdResponse): string | null => {
  if (
    data.data.length > 0 &&
    data.data[0].content.length > 0 &&
    data.data[0].content[0].text.value.length > 0
  ) {
    return data.data[0].content[0].text.value;
  }
  return null;
};

export const waitForThreadCompletion = async (
  chatId: string,
  runId: string
): Promise<{ content: string; chatId: string }> => {
  let status = 'in_progress';
  let tries = 0;
  const maxTries = 30;

  while (status !== 'completed' && tries < maxTries) {
    const data = await apiEndpoints.getMessageByRunId(chatId, runId);
    const content = getThreadContent(data);

    if (content) {
      return { content, chatId };
    }

    if (status === 'failed' || status === 'cancelled') {
      logger.log('Thread run failed or cancelled', { status });
      throw new Error(`Thread run ${status}`);
    }

    if (status !== 'completed') {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      tries++;
    }
  }

  if (tries >= maxTries) {
    throw new Error('Thread completion timed out after 30 tries');
  }

  throw new Error('No content received from thread');
};
