import bgImgMob from 'assets/img/forms/background-1099-nec-mob.webp';
//import bgImgTab from 'assets/img/forms/background-1099-tab.webp';
import bgImgWeb from 'assets/img/forms/background-1099-nec-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';

import form1099 from 'assets/forms/1099-nec.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form1099Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    is1099Form: true,
    pagesCount: 6,
    path: '/form-1099',
    filePath: form1099,
    fileName: '1099-nec.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_1099.title'),
      description: t('meta.form_1099.description'),
    },
    getForm: {
      headline1: 'Fill IRS',
      headline2: 'Information Request Form',
      formName: '1099',
      subHeadline: '',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline: 'Filling your 1099 Form is easy with PDF Guru',
      description: [
          {
            icon: icon_1,
            iconMob: icon_mob_1,
            title: 'Fill out the 1099 form',
            text: 'Access our 1099  Form 2023 and provide the required information, such as your name, contact details, etc.',
          },
          {
            icon: icon_2,
            iconMob: icon_mob_2,
            title: 'Save it or print it out',
            text: 'Download your printable 1099  Form 2023 or simply save the filled-out form as a digital file. ',
          },
          {
            icon: icon_3,
            iconMob: icon_mob_3,
            title: 'Submit your tax return',
            text: 'Send your completed form to the IRS electronically or present it to them in person. ',
          },
        ],
    },
    whatIsForm: {
      headline: 'What is Form 1099?',
      text_1: [],
      text_2: [],
      text_3: [],
      text_4: [],
      title_1: 'What is a 1099 tax form used for?',
      title_2: '',
      subTitle:
        'The 1099 Form is used to report non-employee compensation. These could be payments made to freelancers, independent contractors, self-employed people, or sole proprietors. As per the IRS, any payments meeting the following four points must be reported in the 1099  Form 2023:',
      //@ts-ignore
      sectionImage: section_img,
      //@ts-ignore
      sectionImageTab: section_img_tab,
      description: [
        `It’s likely that many people need clarification about the 1099 Form meaning and usage. This is a tax return form aimed at businesses that engage in non-employee compensation to individuals who earned more than $600 during the year. Originally, this form was used until 1982, when it stopped being required. After that year, businesses would instead use the 1099-MISC Form to report payments to non-employees. However, the IRS reintroduced tax form 1099 in 2020 to separate non-employee compensation payments from miscellaneous income reported on the 1099-MISC form.`
      ],
      bullets: [],
      orangeListSmall: [
        'You paid someone who is not employed by your business',
        'The payment was made for a service falling within the course of your business',
        'The receiver is either an individual, an estate, a partnership, or a corporation',
        'The total annual amount of the payment is at least $600',
      ],
      title_list:
      'The compensation to a non-employee can take the following forms:',
      redList: [
        { text: 'Monetary fees' },
        { text: 'Benefits' },
        { text: 'Prizes' },
        { text: 'Commissions' },
        { text: 'Any other form of compensation' },
      ],
    },
    howToFill: {
      headline: 'How to file Form 1099?',
      //@ts-ignore
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: "",
          text: 'Click on the Get Form button',
        },
        {
          icon: blue_number_icon_2,
          strong: "",
          text: 'Fill out the complete form, providing all the required data, such as your name, address, TIN, etc',
        },
        {
          icon: blue_number_icon_3,
          strong: "",
          text: 'Review all the information you’ve given',
        },
        {
          icon: blue_number_icon_4,
          strong: "",
          text: 'Download the completed, printable 1099 Form 2023',
        },
      ],
    },
    fillFormFree: {
      headline: 'Complete Your 1099 Form Hassle-Free',
      subtitle: 'It’ll take a few seconds - no confusion incurred!',
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'I received a 1099. What do I do with it?',
          answer: [
            `As a professional, receiving the form gives you a way to report your income correctly. Be thorough when checking the form filled out by your payer. Make sure all the information, like your name, contact details, TIN, etc, is correct. After verifying everything, you need to report your income and pay any relevant taxes.`
          ]
        },
        {
          question: 'When is Form 1099 due?',
          answer: [
            `Form 1099 is due before the 31st of January each year. It must be completed well in time using our quick and easy form filler, so you may avoid any penalties due to late submission.`
          ]
        },
        {
          question: 'Where do you send 1099 Forms?',
          answer: [
            `To make the taxation process easier, the IRS has created a Taxpayer Portal where tax forms can be uploaded digitally. The tax form 1099 is no different. If you’re more comfortable sending a printed copy, you may physically send it to any IRS processing center near your area.`
          ]
        },
        {
          question: 'How much taxes do you pay on a 1099?',
          answer: [
            `The combined tax rate to be paid is 15.3%. This includes a 12.4% Social Security tax and a 2.9% Medicare tax. This amount is paid by the employer, although in some cases, the employer and the employee may split the tax.`
          ]
        },
        {
          question: 'What is the difference between 1099-MISC and 1099?',
          answer: [
            `While both these forms are used for reporting payments made to non-employees, there is a key difference between them. 1099 is applicable in cases where payments are subject to self-employment taxes. Alternatively, 1099-MISC is reserved for payments that aren’t subject to any self-employment tax.`
          ]
        },
      ],
    },
    fag: {
      question: 'How to file Form 1099?',
      answer:
        '1. Click on the Get Form button. 2. Fill out the complete form, providing all the required data, such as your name, address, TIN, etc 3. Review all the information you’ve given. 4. Download the completed, printable 1099 Form 2023',
    },
    bannerQuickNEasy: {
      headline: 'The Easiest Way to Complete the 1099 Form',
      subtitle: 'File your tax returns without the stress!',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
  };
};
