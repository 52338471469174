import { ArrowDownIcon } from 'components/icons/arrow-down';
import { ArrowUpIcon } from 'components/icons/arrow-up';
import classNames from 'classnames';
import React, { useState } from 'react';

import s from './dropdown-trigger.module.scss';
import { useIsRtl } from 'hooks/isRtl';

interface IProps {
  label: string;
  isActive: boolean;
  onTrigger: () => void;
  triggerRef: React.RefObject<HTMLDivElement>;
  icon?: string;
  hoverIcon?: string;
}

export const DropdownTrigger: React.FC<IProps> = (props) => {
  const { icon, label, isActive, hoverIcon, onTrigger, triggerRef } = props;
  const [isHover, setIsHover] = useState(false);
  const isRtl = useIsRtl();

  const onHover = () => {
    setIsHover(true);
  };
  const onHoverLeave = () => {
    setIsHover(false);
  };

  const renderIcon = React.useCallback(() => {
    if (!icon) {
      return null;
    }

    if (isHover || isActive) {
      return <img src={hoverIcon as string} width="18px" height="19px" alt="Icon" />;
    }

    return <img src={icon} width="18px" height="19px" alt="Icon" />;
  }, [icon, isHover, isActive, hoverIcon]);

  return (
    <div
      className={classNames({
        [s.dropdownTrigger]: true,
        [s.active]: isActive,
        [s.dropdownTriggerRtl]: isRtl,
      })}
      onClick={onTrigger}
      onMouseEnter={onHover}
      onMouseLeave={onHoverLeave}
      ref={triggerRef}
    >
      {renderIcon()}
      <span>{label}</span>
      {isActive ? <ArrowUpIcon /> : <ArrowDownIcon />}
    </div>
  );
};
