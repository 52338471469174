import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';
import { ISubscription } from 'ts/interfaces/subscriptions/subscriptions';

import { getSubscriptionProducts } from 'helpers/getPlans';

const initialState: ISubscription = {
  subscription: null,
  selectedPlan: null,
  plans: [],
  cancelPlan: null,
  order: null,
  invoice: null,
};

const subscriptionsReducer = (state = initialState, action: IReduxAction): ISubscription | {} => {
  switch (action.type) {
    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }

    case EReduxTypes.SET_SELECTED_PLAN_ID: {
      return {
        ...state,
        selectedPlan: action.data.plan,
      };
    }

    case EReduxTypes.SET_ORDER_DATA: {
      return {
        ...state,
        order: action.data,
      };
    }

    case EReduxTypes.GET_PLANS: {
      const { plans, cancelPlan } = getSubscriptionProducts(action.data.userCountry, action.payload, action.data.newSalesVariant, action.data.germanyFreeTrial)

      return {
        ...state,
        plans,
        cancelPlan,
      };
    }

    case EReduxTypes.GET_INVOICE_LINK: {
      return {
        ...state,
        invoice: action.payload,
      };
    }
    default:
      return state;
  }
};

export default subscriptionsReducer;

