import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalLayout from 'components/modals/baseModal/base';
import { getLoadingSelector } from 'data/selectors/loading';
import { Analytics } from 'services/analytics';
import { toggleModal } from 'data/actions/modals';
import { modalOptionsSelector } from 'data/selectors/modals';
import useTranslateFile from 'hooks/fileFlows/useTranslateFile';
import { useLanguageSelection } from 'hooks/useLanguageSelection';
import { LanguageSection } from './components/LanguageSection';
import { LoadingOverlay } from './components/LoadingOverlay';

import source_lang from './assets/source_lang.svg';
import output_lang from './assets/output_lang.svg';
import { languages } from './localesFull';
import cross from 'assets/img/icons/32px/cross-grey.svg';
import MobileModalLayout from './components/MobileModalLayout';
import ActionButtons from './components/ActionsButtons';

const ChoseTranslateFormatModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoading = useSelector(getLoadingSelector('save_edit_document'));
  const { service } = useSelector(modalOptionsSelector);
  const documentForTranslate = useSelector(
    (state: any) => state.documents.documentForTranslate.document
  );

  const { handleUploadFile } = useTranslateFile({ service });
  const {
    sourceLang,
    setSourceLang,
    outputLang,
    setOutputLang,
    sortedSourceDefaultLang,
    sortedOutputDefaultLang,
  } = useLanguageSelection();

  const handleCancel = () => {
    Analytics.sendEvent({ event: 'cancel_translate_file_tap' });
    dispatch(toggleModal({ visible: false }));
  };

  const handleDownload = () => {
    Analytics.sendEvent({
      event: 'confirm_translate_file_tap',
      data: {
        language_from: sourceLang.code,
        language_to: outputLang.code,
      },
    });

    handleUploadFile(documentForTranslate);
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <div className="hidden tablet:!block">
        <ModalLayout
          canClose
          closePopup={() => Analytics.sendEvent({ event: 'cancel_save_edited_file' })}
          dataTestId="choose-format-modal"
        >
          <div className="flex flex-col text-[#1D1D1D] items-center w-full min-w-[630px] p-4">
            <div className="flex justify-end w-full">
              <img
                src={cross}
                alt="close"
                className="w-[22px] h-[22px] cursor-pointer"
                onClick={handleCancel}
              />
            </div>
            <h2 className="max-w-[339px] text-[25px] font-[800] leading-[32.5px] text-center mb-[32.5px]">
              {t('editor_page.translate_pdf_banner.title')}
            </h2>

            <div className="grid grid-cols-2 w-full px-6 gap-4">
              <LanguageSection
                title={t('editor_page.translate_pdf_banner.source_lang')}
                icon={source_lang}
                selectedLang={sourceLang}
                onSelectLang={setSourceLang}
                defaultOptions={sortedSourceDefaultLang}
                searchOptions={languages}
                type="from"
              />

              <LanguageSection
                title={t('editor_page.translate_pdf_banner.target_lang')}
                icon={output_lang}
                selectedLang={outputLang}
                onSelectLang={setOutputLang}
                defaultOptions={sortedOutputDefaultLang}
                searchOptions={languages.filter((item) => item.code !== sourceLang.code)}
                type="to"
              />
            </div>

            <ActionButtons onCancel={handleCancel} onTranslate={handleDownload} />
          </div>
        </ModalLayout>
      </div>

      <MobileModalLayout
        canClose
        closePopup={() => Analytics.sendEvent({ event: 'cancel_save_edited_file' })}
        dataTestId="choose-format-modal-mobile"
        footer={<ActionButtons onCancel={handleCancel} onTranslate={handleDownload} />}
      >
        <div className="flex flex-col text-[#1D1D1D] p-4">
          <div className="flex justify-end">
            <img
              src={cross}
              alt="close"
              className="w-[22px] h-[22px] cursor-pointer"
              onClick={handleCancel}
            />
          </div>
          <div className="w-full flex justify-center">
            <h2 className="text-[25px] font-[800] leading-[32.5px] text-center mb-6 w-[311px]">
              {t('editor_page.translate_pdf_banner.title')}
            </h2>
          </div>

          <div className="flex flex-col space-y-4">
            <LanguageSection
              title={t('editor_page.translate_pdf_banner.source_lang')}
              icon={source_lang}
              selectedLang={sourceLang}
              onSelectLang={setSourceLang}
              defaultOptions={sortedSourceDefaultLang}
              searchOptions={languages}
              type="from"
            />

            <LanguageSection
              title={t('editor_page.translate_pdf_banner.target_lang')}
              icon={output_lang}
              selectedLang={outputLang}
              onSelectLang={setOutputLang}
              defaultOptions={sortedOutputDefaultLang}
              searchOptions={languages.filter((item) => item.code !== sourceLang.code)}
              type="to"
            />
          </div>
        </div>
      </MobileModalLayout>
    </>
  );
};

export default ChoseTranslateFormatModal;
