import { Upload } from 'antd';
import classNames from 'classnames';
import { FC, ReactElement, useState } from 'react';

import { IService } from 'ts/interfaces/services/service';
import showToast from 'utils/toast';

import { sendAnalyticEvent } from 'data/actions/analytics';
import { validateFile } from 'helpers/validation';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import cross_btn from 'sections/upload-section/assets/cross.svg';
import { formatFileSize } from 'utils/formatFileSize';

/** Styles */
import { MAX_FILES_ALLOWED } from 'components/modals/uploadModalABImportExportEditor244';
import { pushEditMultipleDocumentsDataABImportExportEditor244 } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { selectEditMultipleDocuments } from 'data/selectors/documents';
import { Analytics } from 'services/analytics';
import { EModalTypes } from 'ts/enums/modal.types';
import s from '../uploadButtonMerge/drag-n-drop-new-design.module.scss';
import { Container } from '../uploadButtonMerge/styles';

export type FileItem = { file: File; thumbnail?: string | null };

export const AVAILABLE_FORMATS_AB_IMPORT_EXPORT_EDITOR_2_4_4 =
  '.jpg, .jpeg, .png, .gif, .webp, .bmp, .heic, .svg, .pdf, .xlsx, .xls, .doc, .docx, .csv, .epub';

const UploadButtonABImportEditExport244: FC<{
  service?: IService | any;
  buttonText?: string;
  isFooter?: boolean;
  customButton?: ReactElement<any>;
  isDisabled?: boolean;
  dataTestId?: string;
  placeForAnalytics?: string;
}> = ({
  service,
  customButton,
  isDisabled,
  dataTestId,
  placeForAnalytics = '',
}: {
  service?: IService;
  buttonText?: string;
  isFooter?: boolean;
  customButton?: ReactElement<any>;
  isDisabled?: boolean;
  isReversedText?: boolean;
  dataTestId?: string;
  insertAtIndex?: number;
  ignoreEvent?: boolean;
  placeForAnalytics?: string;
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const existingFiles = useSelector(selectEditMultipleDocuments);
  //remove it
  // eslint-disable-next-line

  const [disabled, setDisabled] = useState(false);
  let sendNotification = false;
  const color = service?.mainColor || 'red';

  const classesDragNDrop = {
    [s.dragNDrop]: true,
    [s.orange]: color === 'orange',
    [s.violet]: color === 'violet',
    [s.berry]: color === 'berry',
    [s.darkblue]: color === 'dark-blue',
    [s.berry]: color === 'red',
    [s.green]: color === 'green',
    [s.peach]: color === 'peach',
    [s.oceanblue]: color === 'ocean-blue',
  };

  const classesDropZone = {
    [s.dropZone]: true,
    [s.orange]: color === 'orange',
    [s.violet]: color === 'violet',
    [s.berry]: color === 'berry',
    [s.darkblue]: color === 'dark-blue',
    [s.green]: color === 'green',
    [s.peach]: color === 'peach',
    [s.oceanblue]: color === 'ocean-blue',
  };

  const handleBeforeUpload = (
    file: File & { uid: string },
    FileList: File[] & { uid: string }[]
  ) => {
    let isErrorMessage = validateFile(file, t, AVAILABLE_FORMATS_AB_IMPORT_EXPORT_EDITOR_2_4_4);

    Analytics.sendEvent({
      event: 'file_upload_status',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        type: 'drag_and_drop',
        file_name: file.name,
        file_type: file.type,
      },
    });

    if (existingFiles?.length + FileList?.length > MAX_FILES_ALLOWED)
      isErrorMessage = t('global.upload_up_to', { count: MAX_FILES_ALLOWED });

    if (isErrorMessage) {
      if (!sendNotification) {
        sendNotification = true;
        showToast('error', isErrorMessage, 5000, 'complete_merge_error_message');
        dispatch(sendAnalyticEvent({ event: 'complete_merge_error_message' }));
        dispatch(
          sendAnalyticEvent({
            event: 'file_upload_status',
            data: {
              status: 'fail',
              place: 'main',
              errorCode: (file?.size || 0) > 100 * 1024 * 1024 ? 'max-size' : 'wrong-format',
              size: formatFileSize(file?.size || 0),
              accurate_size: (file?.size || 0) / 1000000,
              fileCounter: 1,
              file_format: `.${file?.name?.split('.')?.pop()}`,
              is_validation_error: 'true',
            },
          })
        );
      }
      return false;
    }
    return true;
  };

  // disable drag & drop
  const handleOnDragOver = () => {
    if (!disabled) setDisabled(true);
  };

  // disable drag & drop
  const handleOnMouseOver = () => {
    if (disabled) setDisabled(false);
  };

  return (
    <Container
      onDragOver={() => handleOnDragOver()}
      onMouseOver={() => handleOnMouseOver()}
      onClick={() =>
        dispatch(
          sendAnalyticEvent({
            event: 'upload_file_tap',
            data: { type: placeForAnalytics || 'button' },
          })
        )
      }
      data-testid={dataTestId || 'drag-n-drop-container'}
    >
      <Upload
        type="select"
        multiple
        maxCount={9}
        disabled={isDisabled || disabled}
        accept={AVAILABLE_FORMATS_AB_IMPORT_EXPORT_EDITOR_2_4_4}
        name="file"
        showUploadList={false}
        customRequest={(options) => {
          const { file, onSuccess } = options;

          dispatch(pushEditMultipleDocumentsDataABImportExportEditor244(existingFiles, [file]));

          dispatch(
            toggleModal({
              type: EModalTypes.UPLOAD_MODAL_AB_IMPORT_EXPORT_EDITOR_2_4_4,
              visible: true,
              options: {
                filesList: [...(Array.isArray(existingFiles) ? existingFiles : []), file],
                service,
              },
            })
          );

          onSuccess?.('ok');
        }}
        beforeUpload={handleBeforeUpload}
      >
        {customButton ? (
          customButton
        ) : (
          <>
            <div className={classNames(classesDragNDrop)}>
              <div className={classNames(classesDropZone)} onDragOver={(e) => e.preventDefault()}>
                <div className={s.content}>
                  <div className={s.uploadButton}>
                    <img src={cross_btn} alt="cross_btn" />
                  </div>
                  <span className="font-[700] small-desktop:block hidden">
                    {t('upload_section.widget.upload')}
                  </span>
                  <span className="font-[700] small-desktop:hidden block w-full mx-auto max-w-[400px]">
                    {t('upload_section.upload_from_device')}
                  </span>
                </div>
                <div
                  className="text-[13px] leading-[17px] text-[#F5F5F5] font-[500]
          text-center tablet:mb-6 mb-8 mx-8 mobile:mx-0 mobile:block hidden"
                >
                  {t('upload_section.widget.limit')}
                </div>
                <div
                  className="text-[16px] leading-[22px] text-[#F5F5F5] font-[600]
          text-center py-6 mobile:hidden block"
                >
                  {t('upload_section.click_to_upload')}
                </div>
              </div>
            </div>
          </>
        )}
      </Upload>
    </Container>
  );
};

export default UploadButtonABImportEditExport244;
