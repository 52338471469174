import how_to_compress_pdf from 'assets/img/how-to-section/how-to-new-design-compress-pdf.svg';
import {
  default as how_to_delete_pages_pdf,
  default as how_to_edit_pdf,
} from 'assets/img/how-to-section/how-to-new-design-edit-pdf.svg';
import {
  default as how_to_main,
  default as how_to_new_design,
} from 'assets/img/how-to-section/how-to-new-design-main.svg';
import how_to_sign_pdf from 'assets/img/how-to-section/how-to-new-design-sign-pdf.svg';

import icon_1 from 'assets/img/how-to-section/icon_1.svg';
import icon_2 from 'assets/img/how-to-section/icon_2.svg';
import icon_3 from 'assets/img/how-to-section/icon_3.svg';

import compress_pdf from './assets/compress_pdf.svg';
import delete_pages from './assets/delete_pages.svg';
import edit_pdf from './assets/edit_pdf_fill.svg';
import pdf_converter from './assets/pdf_converter.svg';
import pdf_ocr from './assets/pdf_ocr.svg';
import sing_pdf from './assets/sign_pdf.svg';
import split_pdf from './assets/split_pdf.svg';

import { EServiceType, IService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export enum EEditorServices {
  EDIT_PDF = 'edit-pdf',
  FILL_PDF = 'fill-pdf',
  DELETE_PAGES_FROM_PDF = 'delete-pages-from-pdf',
  COMPRESS_PDF = 'compress-pdf',
  SIGN_PDF = 'sign-pdf',
  SPLIT_PDF = 'split-pdf',
  CREATE_PDF = 'create-pdf',
  ROTATE_PDF = 'rotate-pdf',
  PDF_CONVERTOR = 'pdf-converter',
  IMAGE_TO_TEXT = 'image-to-text',
  PDF_OCR = 'pdf-ocr',
  TRANSLATE_PDF = 'translate-pdf',
}

export const editorServicesList = (t: any): IService[] | any[] => {
  return [
    {
      key: EEditorServices.EDIT_PDF,
      serviceType: EServiceType.EDITOR,
      path: 'edit-pdf',
      uploadSectionImage: edit_pdf,
      mainColor: 'berry',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.edit_pdf.h1.title'),
        subtitle_1: t('landing.edit_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.edit_pdf.CTA.title'),
        subtitle: t('landing.edit_pdf.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.edit_pdf.how_to.h2'),
        img: how_to_edit_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.edit_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.edit_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.edit_pdf.how_to.third'),
          },
        ],
      },
      title: t('meta.edit_pdf.title'),
      description: t('meta.edit_pdf.description'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      name: t('meta.edit_pdf.title'),
      nameKey: 'landing.edit_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
    {
      key: EEditorServices.TRANSLATE_PDF,
      serviceType: EServiceType.TRANSLATE,
      path: 'translate-pdf',
      mainColor: 'berry',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.translate_pdf.h1.title'),
        subtitle_1: t('landing.translate_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.translate_pdf.CTA.title'),
        subtitle: t('landing.translate_pdf.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.translate_pdf.how_to.h2'),
        img: how_to_edit_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.translate_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.translate_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.translate_pdf.how_to.third'),
          },
        ],
      },
      title: t('landing.translate_pdf.h1.title'),
      description: t('landing.translate_pdf.h1.subtitle'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      name: t('landing.translate_pdf.h1.title'),
      nameKey: 'landing.translate_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      key: EEditorServices.FILL_PDF,
      path: 'fill-pdf',
      uploadSectionImage: edit_pdf,
      mainColor: 'berry',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.fill_pdf.h1.title'),
        subtitle_1: t('landing.fill_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.fill_pdf.CTA.title'),
        subtitle: t('landing.fill_pdf.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.fill_pdf.how_to.h2'),
        img: how_to_edit_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.fill_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.fill_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.fill_pdf.how_to.third'),
          },
        ],
      },
      title: t('meta.fill_pdf.title'),
      description: t('meta.fill_pdf.description'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      name: t('meta.fill_pdf.title'),
      nameKey: 'landing.fill_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      key: EEditorServices.DELETE_PAGES_FROM_PDF,
      path: '/delete-pages-from-pdf',
      uploadSectionImage: delete_pages,
      mainColor: 'berry',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.delete_pages_from_pdf.h1.title'),
        subtitle_1: t('landing.delete_pages_from_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.delete_pages_from_pdf.CTA.title'),
        subtitle: t('landing.delete_pages_from_pdf.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.delete_pages_from_pdf.how_to.h2'),
        img: how_to_delete_pages_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.delete_pages_from_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.delete_pages_from_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.delete_pages_from_pdf.how_to.third'),
          },
        ],
      },
      title: t('meta.delete_pages_from_pdf.title'),
      description: t('meta.delete_pages_from_pdf.description'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      name: t('meta.delete_pages_from_pdf.title'),
      nameKey: 'landing.delete_pages_from_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.PDF_CONVERTOR,
      path: 'pdf-converter',
      key: EEditorServices.PDF_CONVERTOR,
      uploadSectionImage: pdf_converter,
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pptx, .ppt',
      mainColor: 'berry',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.converter_pdf.h1.title'),
        subtitle_1: t('landing.converter_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.converter_pdf.CTA.title'),
        subtitle: t('landing.converter_pdf.CTA.subtitle'),
        defaultFileFormat: InternalFileType.PDF,
        buttonLabel: t('landing.converter_pdf.CTA.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.converter_pdf.how_to.h2'),
        img: how_to_main,
        description: [
          {
            icon: icon_1,
            text: t('landing.converter_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.converter_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.converter_pdf.how_to.third'),
          },
        ],
      },
      description: t('landing.converter_pdf.description'),
      pageTitle: t('landing.edit_pdf.title'),

      fag: {
        question: 'Edit your PDF file online in a few steps!',
        answer:
          '1. Open PDF Guru and drop the PDF file you want to modify. 2. Add elements (text, images, etc.) using the editor. 3. Approve the modified document, download or share it.',
      },
    },
    {
      serviceType: EServiceType.COMPRESSOR,
      path: '/compress-pdf',
      key: EEditorServices.COMPRESS_PDF,
      uploadSectionImage: compress_pdf,
      mainColor: 'berry',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.compress_pdf.h1.title'),
        subtitle_1: t('landing.compress_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.compress_pdf.h1.title'),
        subtitle_1: t('landing.compress_pdf.h1.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.compress_pdf.how_to.h2'),
        img: how_to_compress_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.compress_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.compress_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.compress_pdf.how_to.third'),
          },
        ],
      },
      title: t('meta.compress_pdf.title'),
      description: t('meta.compress_pdf.description'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      name: t('meta.compress_pdf.title'),
      nameKey: 'landing.compress_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      path: '/sign-pdf',
      key: EEditorServices.SIGN_PDF,
      uploadSectionImage: sing_pdf,
      mainColor: 'ocean-blue',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.signature_pdf.h1.title'),
        subtitle_1: t('landing.signature_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.signature_pdf.h1.title'),
        subtitle_1: t('landing.signature_pdf.h1.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.signature_pdf.how_to.h2'),
        img: how_to_sign_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.signature_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.signature_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.signature_pdf.how_to.third'),
          },
        ],
      },
      title: t('meta.signature_pdf.title'),
      description: t('meta.signature_pdf.description'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      name: t('meta.signature_pdf.title'),
      nameKey: 'landing.signature_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
    // mergePageService(t),
    {
      serviceType: EServiceType.SPLITTER,
      path: '/split-pdf',
      key: EEditorServices.SPLIT_PDF,
      uploadSectionImage: split_pdf,
      name: t('meta.split_pdf.title'),
      nameKey: 'landing.split_pdf.name',
      title: t('meta.split_pdf.title'),
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
      mainColor: 'berry',
      uploadSection: {
        defaultFileFormat: InternalFileType.PDF,
        headline: t('landing.split_pdf.h1.title'),
        subtitle_1: t('landing.split_pdf.h1.subtitle'),
      },
      howTo: {
        headline_1: t('landing.split_pdf.how_to.h2'),
        img: how_to_edit_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.split_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.split_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.split_pdf.how_to.third'),
          },
        ],
      },
      description: t('landing.signature_pdf.description'),
      descriptionTwo: '',
      fag: {
        question: 'How to organize multiple PDFs documents?',
        answer:
          '1. Upload PDF files you would like to be combined. 2. Arrange files in the desired order, delete unnecessary. 3. Download or share your merged PDF file.',
      },
    },
    {
      serviceType: EServiceType.OCR,
      mainColor: 'berry',
      path: '/image-to-text',
      key: EEditorServices.IMAGE_TO_TEXT,
      uploadSectionImage: pdf_ocr,
      name: t('meta.image_to_text.title'),
      nameKey: 'landing.image_to_text.name',
      title: t('meta.image_to_text.title'),
      description: t('meta.image_to_text.description'),
      uploadSection: {
        from: [
          InternalFileType.HEIC,
          InternalFileType.SVG,
          InternalFileType.PNG,
          InternalFileType.BMP,
          InternalFileType.EPS,
          InternalFileType.GIF,
          InternalFileType.TIFF,
          InternalFileType.WEBP,
          InternalFileType.JPG,
          InternalFileType.JPEG,
        ],
        to: InternalFileType.PDF,
        headline: t('landing.image_to_text.h1.title'),
        subtitle_1: t('landing.image_to_text.h1.subtitle'),
      },
      banner: {
        headline: t('landing.image_to_text.CTA.title'),
        subtitle: t('landing.image_to_text.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.image_to_text.how_to.h2'),
        img: how_to_new_design,
        description: [
          {
            icon: icon_1,
            text: t('landing.image_to_text.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.image_to_text.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.image_to_text.how_to.third'),
          },
        ],
      },
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      from: [
        InternalFileType.HEIC,
        InternalFileType.SVG,
        InternalFileType.PNG,
        InternalFileType.BMP,
        InternalFileType.EPS,
        InternalFileType.GIF,
        InternalFileType.TIFF,
        InternalFileType.WEBP,
        InternalFileType.JPG,
        InternalFileType.JPEG,
        InternalFileType.PDF,
      ],
      to: 'PDF',
      availableFormats: '.pdf, .png, .jpg, .bmp, .jpeg, .tiff, .gif, .djvu',
    },
    {
      serviceType: EServiceType.OCR,
      path: '/pdf-ocr',
      key: EEditorServices.PDF_OCR,
      uploadSectionImage: pdf_ocr,
      mainColor: 'berry',
      name: t('meta.pdf_ocr.title'),
      nameKey: 'landing.pdf_ocr.name',
      title: t('meta.pdf_ocr.title'),
      description: t('meta.pdf_ocr.description'),
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.pdf_ocr.h1.title'),
        subtitle_1: t('landing.pdf_ocr.h1.subtitle'),
      },
      banner: {
        headline: t('landing.pdf_ocr.CTA.title'),
        subtitle: t('landing.pdf_ocr.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.pdf_ocr.how_to.h2'),
        img: how_to_new_design,
        description: [
          {
            icon: icon_1,
            text: t('landing.pdf_ocr.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.pdf_ocr.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.pdf_ocr.how_to.third'),
          },
        ],
      },
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf, .png, .jpg, .bmp, .jpeg, .tiff, .gif, .djvu',
    },

    {
      serviceType: EServiceType.EDITOR,
      path: 'create-pdf',
      mainColor: 'berry',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.create_pdf.h1.title'),
        subtitle_1: t('landing.create_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.create_pdf.CTA.title'),
        subtitle: t('landing.create_pdf.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('landing.create_pdf.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.create_pdf.how_to.h2'),
        img: how_to_edit_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.create_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.create_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.create_pdf.how_to.third'),
          },
        ],
      },
      title: t('meta.create_pdf.title'),
      description: t('meta.create_pdf.description'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      name: t('meta.create_pdf.title'),
      nameKey: 'landing.create_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
    {
      serviceType: EServiceType.EDITOR,
      path: 'rotate-pdf',
      key: EEditorServices.ROTATE_PDF,
      mainColor: 'berry',
      uploadSection: {
        from: InternalFileType.PDF,
        to: InternalFileType.PDF,
        headline: t('landing.rotate_pdf.h1.title'),
        subtitle_1: t('landing.rotate_pdf.h1.subtitle'),
      },
      banner: {
        headline: t('landing.rotate_pdf.CTA.title'),
        subtitle: t('landing.rotate_pdf.CTA.subtitle'),
        format: InternalFileType.PDF,
        buttonLabel: t('main_page.buttonLabel'),
      },
      howTo: {
        headline_1: t('landing.rotate_pdf.how_to.h2'),
        img: how_to_edit_pdf,
        description: [
          {
            icon: icon_1,
            text: t('landing.rotate_pdf.how_to.first'),
          },
          {
            icon: icon_2,
            text: t('landing.rotate_pdf.how_to.second'),
          },
          {
            icon: icon_3,
            text: t('landing.rotate_pdf.how_to.third'),
          },
        ],
      },
      title: t('meta.rotate_pdf.title'),
      description: t('meta.rotate_pdf.description'),
      fag: {
        question: 'Convert a PDF file to Word online in a few steps!',
        answer:
          '1. To convert a PDF file, upload it using the button "Upload" above or Drag&Drop. 2. Edit your PDF document using our tools and convert it to Word with one click. 3. Download or share the Word file.',
      },
      name: t('meta.rotate_pdf.title'),
      nameKey: 'rotate_pdf.edit_pdf.name',
      from: 'PDF',
      to: 'PDF',
      availableFormats: '.pdf',
    },
  ];
};
