import bgImgMob from 'assets/img/forms/background-1065-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-1065-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';
import blue_number_icon_7 from 'assets/img/forms/7-step-blue-icon.svg';

import form1065 from 'assets/forms/1065.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form1065Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-1065',
    filePath: form1065,
    fileName: '1065.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_1065.title'),
      description: t('meta.form_1065.description'),
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '1065',
      subHeadline: '2020-2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline: 'How to fill out the 1065 Form',
      description: [
        {
          icon: icon_1,
          //@ts-ignore
          iconMob: icon_mob_1,
          title: 'Access the form',
          text: 'Begin by logging in to PDF Guru and clicking "GET FORM". You will access our intuitive PDF editor, preloaded with the latest version of the IRS 1065 tax form.',
        },
        {
          icon: icon_2,
          //@ts-ignore
          iconMob: icon_mob_2,
          title: 'Edit and review',
          text: 'Use our wide range of editing tools to fill in all the necessary details. Ensure every detail is captured correctly.',
        },
        {
          icon: icon_3,
          //@ts-ignore
          iconMob: icon_mob_3,
          title: 'Download and use',
          text: "Once you've filled out the form, save your progress and download the file. It should be formatted correctly and ready for submission according to IRS requirements.",
        },
      ],
    },
    whatIsForm: {
      headline: 'What is Form 1065?',
      text_1: [],
      text_2: [
        "Primarily, tax Form 1065 is used to ensure that each partner in a business partnership is taxed correctly on their share of the partnership income. This form offers a comprehensive view of the partnership's financial status, playing a pivotal role in maintaining financial transparency and integrity within business partnerships.",
        "While its primary function is to report the financial activities of a partnership, tax Form 1065 also serves as a document for internal record-keeping. It allows partners to have a clear understanding of the partnership's financial health, aiding in making informed business decisions. This dual role underscores the importance of a tool that simplifies and streamlines the process of filling out this form.",
      ],
      text_3: [],
      text_4: [],
      title_1: 'What is Form 1065 used for?',
      title_2: '',
      subTitle: '',
      //@ts-ignore
      sectionImage: section_img,
      //@ts-ignore
      sectionImageTab: section_img_tab,
      description: [
        "IRS Form 1065 is an essential document for partnership tax reporting. As a mandatory IRS form, it is used to declare the profits, losses, tax deductions, and credits of a business partnership. In the digital age, efficiently managing this form is crucial for accuracy and compliance.",
        "Given its role in the financial reporting of partnerships, accuracy in completing tax Form 1065 cannot be overstated. It provides the IRS with a detailed view of the partnership's financial status, including income, deductions, credits, and losses. Mistakes or inaccuracies in Form 1065 can lead to complications with tax liabilities and audits, making a reliable tool like ours invaluable."
      ],
      bullets: [], 
      orangeList: [],              
    },
    blockWithBlueTitle1: {
      title: 'Who files Form 1065?',
      description: [
        'Every partnership operating in the United States must file tax Form 1065, regardless of its size or nature. This includes:',
      ],
      orangeList: [
        { text: 'Limited partnerships (LPs)' },
        { text: 'Limited liability partnerships (LLPs)' },
        { text: 'Other similar business entities' },
      ],
      textAfterList: [
        'The requirement to file Form 1065 applies even if the partnership did not earn any income or incur any expenses during the tax year. The range of entities required to file tax Form 1065 is broad, covering everything from small local partnerships to large, multinational joint ventures. This diversity underscores the need for a versatile and easy-to-use platform like PDF Guru, capable of accommodating the varying needs of different partnership structures.',
      ],
    },    
    howToFill: {
      headline: 'How to fill out Form 1065?',
      //@ts-ignore
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          text: 'Access PDF Guru and choose the blank form 1065 (U.S. Return of Partnership Income).',
        },
        {
          icon: blue_number_icon_2,
          text: 'At the top of the form, fill in your partnership\'s name, address, and Employer Identification Number (EIN).',
        },
        {
          icon: blue_number_icon_3,
          text: 'Accurately report the partnership\'s income, including gross receipts, sales, and other types of income, and record deductible expenses like salaries, rent, and taxes.',
        },
        {
          icon: blue_number_icon_4,
          text: 'Carefully check all the entered financial details for accuracy, ensuring all income and expenses are correctly reported.',
        },
        {
          icon: blue_number_icon_5,
          text: 'Once you\'ve reviewed the form and confirmed its accuracy, save and download the completed form 1065 securely using PDF Guru.',
        },
        {
          icon: blue_number_icon_6,
          text: 'After downloading Form 1065, proceed to complete the necessary schedules, such as Schedule K and K-1, according to IRS guidelines. These schedules are essential for IRS filing.',
        },
        {
          icon: blue_number_icon_7,
          text: 'With the completed form 1065 and schedules, you\'re ready for the IRS filing. While PDF Guru provides the tools for form preparation, remember that it doesn\'t handle the actual filing. You\'ll need to submit the completed form and schedules to the IRS as required.',
        },
      ],
    },    
    blockWithBlueTitle2: {
      title: 'What is a schedule K-1 (Form 1065)?',
      description: [
        "Schedule K-1 (Form 1065) is used to report each partner's share of the partnership's income, deductions, credits, and other items. The form is used to determine each partner's tax liability and to allocate profits and losses to the partners. One Schedule K-1 must be generated for each partner for every tax year - without these forms, partners would lack the information to file their taxes properly.",
      ],
      title_list: {
        text: 'Other schedules that may be required to be filed with Form 1065 include Schedule B, Schedule M-1, and Schedule M-2. Here\'s a brief overview of each schedule:',
      },
      orangeList: [
        {
          strong: 'Schedule B:',
          text: ' This schedule is used to provide information applicable to certain entities, individuals, and estates that own, directly or indirectly, more than 50% of the partnership. It is not required if the partnership has no such owners.',
        },
        {
          strong: 'Schedule M-1:',
          text: ' This schedule is used to reconcile the income reported on Form 1065 with the income reported on the partnership\'s income tax return. It shows the reconciliation of income (loss) per books with income (loss) per tax form.',
        },
        {
          strong: 'Schedule M-2:',
          text: ' This schedule is used to analyze each partner\'s capital account. It shows the changes in each partner\'s capital account from the beginning to the end of the tax year. The changes are reported on Schedule M-2, and the schedules of the partners are attached to the return.',
        },
      ],
    },    
    fillFormFree: {
      headline: "Simplify Tax Reporting with PDF Guru's 1065 Form Filler",
      subtitle: '',
      buttonLabel: 'Fill Form',
    },
    bannerQuickNEasy: {
      headline: 'Simplify Tax Prep with Our 1065 Form Filler',
      subtitle: '',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'How to file Form 1065 online?',
          answer: [
            'Form 1065 can be filed online through the IRS e-filing system. PDF Guru provides an easy-to-use platform to prepare the form before online submission. Electronic filing is a fast and secure way to file your form and can help to ensure that your form is filed on time.',
          ],
        },
        {
          question: 'When is Form 1065 due?',
          answer: [
            'The due date for filing Form 1065 is typically March 15th of the year following the reported fiscal year. If the 15th falls on a weekend or holiday, the deadline is the next business day. Extensions are available under certain circumstances. You can request an automatic 5-month extension if more time is needed to prepare an accurate return.',
          ],
        },
        {
          question: 'What taxes are deductible on line 14 of Form 1065?',
          answer: [
            'Line 14 of Form 1065 is used to report the partnership\'s deductible taxes. This includes state and local income taxes, real estate taxes, and personal property taxes. It is important to ensure that you are reporting all deductible taxes on this line to maximize your tax benefits.',
          ],
        },
        {
          question: 'How to report PPP loan forgiveness on Form 1065?',
          answer: [
            'PPP loan forgiveness (Paycheck Protection Program) should be reported on Form 1065 according to the latest IRS guidelines, typically as an exempt income item, ensuring accurate tax liability assessment. Also remember to file Form 7203 detailing the PPP loan forgiveness figures and attesting proper regulatory use of the funds.',
          ],
        },
      ],
    },        
    fag: {
      question: 'How to fill out Form 1065?',
      answer:
        "1. Access PDF Guru and choose the blank form 1065 (U.S. Return of Partnership Income). 2. At the top of the form, fill in your partnership's name, address, and Employer Identification Number (EIN).  3.Accurately report the partnership's income, including gross receipts, sales, and other types of income, and record deductible expenses like salaries, rent, and taxes. 4.Carefully check all the entered financial details for accuracy, ensuring all income and expenses are correctly reported. 5. Once you've reviewed the form and confirmed its accuracy, save and download the completed form 1065 securely using PDF Guru. 6. After downloading Form 1065, proceed to complete the necessary schedules, such as Schedule K and K-1, according to IRS guidelines. These schedules are essential for IRS filing. 7. With the completed form 1065 and schedules, you're ready for the IRS filing. While PDF Guru provides the tools for form preparation, remember that it doesn't handle the actual filing. You'll need to submit the completed form and schedules to the IRS as required. ",
    },
  };
};
