const starsArray = Array.from({ length: 5 }, (_, i) => i);

export const renderRating = (props: {
  temporaryRating: number;
  userRating: number;
  onClick: (number: number) => void;
  setTemporaryRating: (number: number) => void;
}) => (
  <div className="flex justify-center h-[49px]">
    {starsArray.map((_, index) => (
      <div
        onMouseEnter={() => {
          props.setTemporaryRating(index + 1);
        }}
        onMouseLeave={() => {
          props.setTemporaryRating(props.userRating);
        }}
        onClick={() => props.onClick(props.temporaryRating)}
        key={index}
        className="hover:cursor-pointer"
      >
        <svg
          width="49"
          height="49"
          viewBox="0 0 49 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.7306 4.06098C24.0152 3.37657 24.9848 3.37657 25.2694 4.06098L30.0737 15.6117C30.3377 16.2465 30.9346 16.6802 31.6199 16.7352L44.0899 17.7349C44.8288 17.7941 45.1284 18.7162 44.5655 19.1984L35.0646 27.3369C34.5425 27.7841 34.3145 28.4859 34.474 29.1546L37.3767 41.3232C37.5487 42.0443 36.7643 42.6142 36.1317 42.2278L25.4556 35.7069C24.8689 35.3485 24.1311 35.3485 23.5444 35.7069L12.8683 42.2278C12.2357 42.6142 11.4513 42.0443 11.6233 41.3232L14.526 29.1546C14.6855 28.4859 14.4575 27.7841 13.9354 27.3369L4.43453 19.1984C3.87158 18.7162 4.17119 17.7941 4.91007 17.7349L17.3801 16.7352C18.0654 16.6802 18.6623 16.2465 18.9263 15.6117L23.7306 4.06098Z"
            fill={index + 1 <= props.temporaryRating ? '#009B63' : 'white'}
            stroke={index + 1 <= props.temporaryRating ? '#009B63' : '#BFBFBF'}
          />
        </svg>
      </div>
    ))}
  </div>
);
