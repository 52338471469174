import bgImgMob from 'assets/img/forms/background-w4-mob.webp';
//import bgImgTab from 'assets/img/forms/background-w4-tab.webp';
import bgImgWeb from 'assets/img/forms/background-w4-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';

import formW4 from 'assets/forms/w-4.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const formW4Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-w4',
    filePath: formW4,
    fileName: 'w-4.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_w4.title'),
      description: t('meta.form_w4.description'),
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: 'W-4',
      subHeadline: '2025 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline: 'Simplify your tax withholding process',
      description: [
          {
            icon: icon_1,
            iconMob: icon_mob_1,
            title: 'Fill form',
            text: 'Easily access the blank W-4 form on PDF Guru. Our intuitive interface guides you smoothly through the initial steps.',
          },
          {
            icon: icon_2,
            iconMob: icon_mob_2,
            title: 'Fill out the form',
            text: 'Use our PDF editor to fill in the necessary information, such as your name, address, and Social Security number.',
          },
          {
            icon: icon_3,
            iconMob: icon_mob_3,
            title: 'Download and submit',
            text: 'Review, download your completed W-4, and submit it to your employer, either electronically or printed.',
          },
        ],
    },
    whatIsForm: {
      headline: 'What is a W-4 tax Form?',
      text_1: [],
      text_2: [
        "Basically, Form W-4 lets your boss know your tax situation. This includes whether you're single or married, if you have a second job, or other ways you make money, which all affect your tax withholdings and how much you should be paying. Our W-4 Form PDF Filler is really handy for completing this form accurately.",
      ],
      text_3: [
        "Getting an Employee's Withholding Certificate online is super easy with PDF Guru, especially when it's time to update your W-4 due to changes in your job or tax situation. You can just click the 'GET FORM' button on this very page, and a bank document will open in a PDF editor. Now you can fill it out with the info you need, save, and download it to your device. Need a paper copy? No problem! We provide a current version of W-4 form, printable and editable.",
      ],
      text_4: [],
      orangeList: [],
      title_1: 'What is a W4 form used for?',
      title_2: 'How to get a W-4 form?',
      subTitle: '',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
          "Think of the W-4 form as a tool that tells your employer how much federal income tax to withhold from your paycheck. It is also known as Employee's Withholding Certificate. Every now and then, this form gets updated to match new tax rules. Filling out your W-4 correctly is important because it affects your tax refund or how much tax bill you'll either incur or owe at tax time at the end of the year. If you don't fill it out correctly, you might end up paying too much or too little in taxes. That's why accurately completing your W-4 is so important."
      ],
      bullets: [],
    },
    differenceBetween1: {
      headline: 'What does a W4 form look like',
      text: "The W-4 form is a one-page document with a series of fields to be filled out by the employee, detailing your withholdings and exemptions, including whether you are exempt from withholding. It includes sections for personal information, financial details, and specific tax-related declarations. Using PDF Guru's editor, you can view and interact with the federal W-4 form, getting a clear sense of its structure and requirements. You can also easily access other federal tax documents for the 2024 tax year.",
    },
    blockWithBlueTitle2: {
      title: 'Special situations for filling out a W-4 form',
      description: [],
      title_list: {
        text: "Understanding how to accurately complete the IRS Form W-4 is essential for various tax situations. Here's how to approach them:",
      },
      orangeList: [
        {
          strong: "How to fill out a W-4 Form as a non-resident alien: ",
          text: `If you're not a US citizen and working there, there are special rules for your IRS W-4 form. Basically, you can only claim one allowance and you should write "Nonresident Alien" or "NRA" on the form. You may need to provide additional information, such as your country of residence and tax treaty information.`,
        },
        {
          strong: "How to fill out a W-4 form as a single person: ",
          text: `If you're single, the W-4 form filling process is generally simpler. You typically claim one withholding allowance for yourself unless you have multiple jobs or other income sources. Also, you may need to provide additional information, such as your tax filing status and whether you have any dependents.`,
        }
      ],
    },
    howToFill: {
      headline: 'How to fill out a W-4 form',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Start with PDF Guru:',
          text: ' Access the DS-82 passport form online on our platform.',
        },
        {
          icon: blue_number_icon_2,
          strong: 'Input personal information:',
          text: ' Fill out your personal details, including name, address, and previous passport number.',
        },
        {
          icon: blue_number_icon_3,
          strong: 'Photo attachment:',
          text: ' Attach a passport-sized photo according to the specific requirements.',
        },
        {
          icon: blue_number_icon_4,
          strong: 'Review and download:',
          text: ' Ensure all information isсaccurate before downloading the fillable DS-82 form.',
        },
        {
          icon: blue_number_icon_5,
          strong: 'Print the form:',
          text: ' Print your form on standard white paper. Note that the DS-82 form does not require a signature before submission.',
        },
        {
          icon: blue_number_icon_6,
          strong: 'Mail the form:',
          text: ' Send your completed form along with your current passport and photo to the designated processing center.',
        },
      ],
    },
    fillFormFree: {
      headline: 'Easily Complete Your W-4 Form Now',
      subtitle: 'PDF Guru streamlines your tax filing',
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: "When did the W-4 Form change?",
          answer: [
            "The W-4 federal form has undergone several changes over the years, with the most recent update being in 2020. This was to make it easier to figure out and to match up with recent changes in federal income tax laws."
          ]
        },
        {
          question: "What should I claim on the W-4 Form?",
          answer: [
            "Your claims affect the federal income tax withheld from your paycheck. You're basically telling your employer how much tax to not take out of your pay. If you claim more allowances, they take out less tax. Claim fewer, and they take out more. The goal is to find a balance so you don't end up owing a lot of tax or getting a big refund at the end of the year."
          ]
        },
        {
          question: "How to fill out a W-4 Form for my first job?",
          answer: [
            "Consider how much you'll earn at your new job and any other income you might have coming in. It's a good idea to check the IRS guidelines or get some advice if you're not sure."
          ]
        },
        {
          question: "What are the differences between a W2, W4, 1040, and 1099 tax form?",
          answer: [
            "Let's break these down into simple terms:"
          ]
        },
      ],
    },
    fag: {
      question: 'How to fill out a 1040 Form',
      answer:
        '1.Start with PDF Guru: Access our platform and open the blank 1040 form. 2.Enter personal information: Fill in your name, address, SSN, and filing status. 3.Report income: Detail all sources of income as per IRS Form 1040 instructions. 4.Claim deductions: Reduce your taxable income by claiming eligible deductions. 5.Calculate tax: Follow the instructions to compute your tax liability or refund. 6.Review and download: Ensure accuracy and download the form for submission. You can either e-sign it digitally within PDF Guru or print it for manual signing. 7.Submission: Submit your form to the IRS as per their submission guidelines.',
    },
    bannerQuickNEasy: {
      headline: 'Complete Your W-4 Form Online in Minutes',
      subtitle: 'Efficient and user-friendly form-filling tool',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
  };
};
