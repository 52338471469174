import { setEditMultipleDocumentsDataABImportExportEditor244 } from 'data/actions/documents';
import { selectEditMultipleDocuments } from 'data/selectors/documents';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { UploadFileItem } from '../UploadFileItem';
import { Analytics } from 'services/analytics';

export const UploadFileList = ({ unsupportedFiles }: { unsupportedFiles: File[] }) => {
  const files = useSelector(selectEditMultipleDocuments);

  const dispatch = useDispatch();
  const moveFile = (fromIndex: number, toIndex: number) => {
    const updatedFiles = [...files];
    const [movedItem] = updatedFiles.splice(fromIndex, 1);
    updatedFiles.splice(toIndex, 0, movedItem);
    dispatch(setEditMultipleDocumentsDataABImportExportEditor244(updatedFiles));
    Analytics.sendEvent({
      event: 'upload_modal_action',
      data: { action_type: 'reorder_files' },
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="rounded-xl border border-[#E9E9E9] overflow-hidden w-full">
        {files.map((item: File, index: number) => (
          <UploadFileItem
            key={uuidv4()}
            item={item}
            index={index}
            moveFile={moveFile}
            unsupportedFiles={unsupportedFiles}
          />
        ))}
      </div>
    </DndProvider>
  );
};
