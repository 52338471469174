import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import translate_icon from '../assets/translate_icon.svg';

interface ActionButtonsProps {
  onCancel: () => void;
  onTranslate: () => void;
}

const ActionButtons: FC<ActionButtonsProps> = ({ onCancel, onTranslate }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col-reverse tablet:flex-row tablet:gap-4 bg-[#E9E9E9] p-4 tablet:p-0 tablet:px-6 tablet:bg-white tablet:mt-8 items-center tablet:mb-4 w-full">
      <button
        className="w-full tablet:bg-white mt-2 tablet:mt-0 tablet:border-2 rounded-xl border-[#1D1D1D] text-[#1D1D1D] tablet:h-[57px] text-[18px] font-[700]"
        onClick={onCancel}
      >
        {t('editor_page.translate_pdf_banner.cancel')}
      </button>
      <button
        className="bg-[#D2294B] py-4 px-6 tablet:py-0 tablet:px-0 rounded-xl w-full flex justify-center items-center gap-2 h-[57px] text-white text-[18px] font-[700]"
        onClick={onTranslate}
      >
        <img src={translate_icon} alt="translate" />
        {t('editor_page.translate_pdf_banner.translate')}
      </button>
    </div>
  );
};

export default ActionButtons;
