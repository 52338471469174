import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export interface IChatHistoryItem {
  id: string;
  chatName: string;
  updated_at: string;
  fileId?: string;
  file_url?: string;
  file_name?: string;
  openai_thread_id?: string;
  status?: 'READY' | 'WAITING';
}

export interface ICurrentThreadAssistantMessage {
  id: string;
  role: 'assistant';
  message: string;
  threadId: string;
  suggestedQuestions?: string[];
}

interface ICurrentThreadUserMessage {
  id: string;
  role: 'user';
  threadId: string | null;
  message: string;
}

export interface INewFileData {
  key: string;
  filename: string;
  size: number;
  pagesCount: number;
}

export type ICurrentThreadData = ICurrentThreadAssistantMessage | ICurrentThreadUserMessage;

export interface IAiSummarizerState {
  chatsHistory: IChatHistoryItem[];
  fileId: string | null;
  currentThreadId: string | null;
  currentThreadData: ICurrentThreadData[];
  currentFileUrl: string | null;
  newFileData: INewFileData | null;
  fileName: string | null;
  isMobileMenuOpen: boolean;
  freeLimitReached: boolean;
}

const initialState: IAiSummarizerState = {
  chatsHistory: [],
  fileId: null,
  currentThreadId: null,
  currentThreadData: [],
  currentFileUrl: null,
  newFileData: null,
  fileName: null,
  isMobileMenuOpen: false,
  freeLimitReached: false,
};

const aiSummarizer = (state = initialState, action: IReduxAction): IAiSummarizerState => {
  switch (action.type) {
    case EReduxTypes.SET_CHATS_HISTORY:
      const { chatsHistory } = action.data;

      return {
        ...state,
        chatsHistory,
      };

    case EReduxTypes.SET_FILE_ID:
      const { fileId } = action.data;

      return {
        ...state,
        fileId,
      };

    case EReduxTypes.SET_CURRENT_THREAD_ID:
      const { currentThreadId } = action.data;

      return {
        ...state,
        currentThreadId,
      };

    case EReduxTypes.SET_CURRENT_THREAD_ASSISTANT_MESSAGE:
      const { currentThreadData: assistantMessage } = action.data;
      const { id, threadId, role, message } = assistantMessage;

      console.log('REDUCER ASISSTANT MESSAGE ===>', assistantMessage);

      return {
        ...state,
        currentThreadData: [
          ...state.currentThreadData,
          {
            id,
            threadId,
            role,
            message,
          },
        ],
      };

    case EReduxTypes.SET_CURRENT_THREAD_USER_MESSAGE:
      console.log('SET_CURRENT_THREAD_USER_MESSAGE received:', JSON.stringify(action));

      const userMessage = action.data.message;

      console.log('Extracted user message:', userMessage);

      return {
        ...state,
        currentThreadData: [
          ...state.currentThreadData,
          {
            id: `id-${Math.random().toString(36).substr(2, 9)}`,
            role: 'user',
            threadId: state.currentThreadId || null,
            message: userMessage,
          },
        ],
      };

    case EReduxTypes.REMOVE_CURRENT_THREAD_DATA:
      console.log('REMOVE_CURRENT_THREAD_DATA received in reducer with state:', state);
      console.log('Returning state with empty currentThreadData');

      return {
        ...state,
        currentThreadData: [],
      };

    case EReduxTypes.SET_CURRENT_FILE_URL:
      const { currentFileUrl } = action.data;

      return {
        ...state,
        currentFileUrl,
      };

    case EReduxTypes.SET_NEW_FILE_DATA:
      const { newFileData } = action.data;

      return {
        ...state,
        newFileData,
      };

    case EReduxTypes.SET_FILE_NAME:
      const { fileName } = action.data;

      return {
        ...state,
        fileName,
      };

    case EReduxTypes.SET_MOBILE_MENU_OPEN:
      const { isMobileMenuOpen } = action.data;

      return {
        ...state,
        isMobileMenuOpen,
      };

    case EReduxTypes.SET_FREE_LIMIT_REACHED:
      const { freeLimitReached } = action.data;

      return {
        ...state,
        freeLimitReached,
      };

    default:
      return state;
  }
};

export default aiSummarizer;
