import { toggleModal } from 'data/actions/modals';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useCloseModalOnBack = (onClose?: unknown): void => {
  const dispatch = useDispatch();

  const handleCloseModal = useCallback(() => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    } else {
      dispatch(toggleModal({ visible: false }));
    }
  }, [onClose, dispatch]);

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      handleCloseModal();
    };
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleCloseModal]);
};

export default useCloseModalOnBack;
