import { Dispatch } from 'react';

import {
  removeCurrentThreadData,
  setCurrentFileUrl,
  setCurrentThreadId,
  setFileId,
  setFileName,
  setMobileMenuOpen,
} from 'data/actions/aiSummarizer';
import { triggerLoadingAction } from 'data/actions/loading';

import { buildThreadMessage } from './build-thread-message';

export const setCurrentChat = async (chat: any, messages: any, dispatch: Dispatch<any>) => {
  dispatch(setCurrentThreadId(chat.chatId));
  dispatch(setFileId(chat.fileId));
  dispatch(setCurrentFileUrl(chat.file_url));
  dispatch(setFileName(chat.chatName));
  dispatch(setMobileMenuOpen(false));
  dispatch(removeCurrentThreadData());

  try {
    if (messages?.length) {
      buildThreadMessage(messages, chat.chatId, dispatch);
    }

    dispatch(triggerLoadingAction(['threadHistory'], false));
  } catch (error) {}
};
