import fileTypeExel from 'assets/img/icons/20px-new-design/Exel.svg';
import fileTypeIMG from 'assets/img/icons/20px-new-design/IMG.svg';
import fileTypePDF from 'assets/img/icons/20px-new-design/PDF.svg';
import fileTypePNG from 'assets/img/icons/20px-new-design/PNG.svg';
import fileTypePPTX from 'assets/img/icons/20px-new-design/PTT.svg';
import fileTypeWord from 'assets/img/icons/20px-new-design/Word.svg';

export const formatsDictionary: any = {
  '.pdf': [
    {
      label: 'Word',
      to: 'DOCX',
      icon: fileTypeWord,
      format: '.docx',
    },
    {
      label: 'Epub',
      to: 'EPUB',
      icon: fileTypeWord,
      format: '.epub',
    },
    {
      label: 'PNG',
      icon: fileTypePNG,
      to: 'PNG',
      format: '.png',
    },
    {
      label: 'Excel',
      icon: fileTypeExel,
      to: 'XLS',
      format: '.xls',
    },
    {
      label: 'JPG',
      to: 'JPG',
      icon: fileTypeIMG,
      format: '.jpg',
    },
    {
      label: 'PPTX',
      to: 'PPTX',
      icon: fileTypePPTX,
      format: '.pptx',
    },
  ],
  '.png': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
    {
      label: 'JPG',
      to: 'JPG',
      icon: fileTypeIMG,
      format: '.jpg',
    },
  ],
  '.doc': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
    {
      label: 'PNG',
      icon: fileTypeIMG,
      to: 'PNG',
      format: '.png',
    },
    {
      label: 'JPG',
      to: 'JPG',
      icon: fileTypeIMG,
      format: '.jpg',
    },
  ],
  '.docx': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
    {
      label: 'PNG',
      icon: fileTypeIMG,
      to: 'PNG',
      format: '.png',
    },
    {
      label: 'JPG',
      to: 'JPG',
      icon: fileTypeIMG,
      format: '.jpg',
    },
  ],
  '.jpg': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
    {
      label: 'PNG',
      icon: fileTypeIMG,
      to: 'PNG',
      format: '.png',
    },
  ],
  '.xls': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
    {
      label: 'PNG',
      icon: fileTypeIMG,
      to: 'PNG',
      format: '.png',
    },
  ],
  '.xlsx': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
    {
      label: 'PNG',
      icon: fileTypeIMG,
      to: 'PNG',
      format: '.png',
    },
  ],
  '.ppt': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
    {
      label: 'PNG',
      icon: fileTypeIMG,
      to: 'PNG',
      format: '.png',
    },
  ],
  '.pptx': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
    {
      label: 'PNG',
      icon: fileTypeIMG,
      to: 'PNG',
      format: '.png',
    },
  ],
  '.epub': [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
  ],

  default: [
    {
      label: 'PDF',
      icon: fileTypePDF,
      to: 'PDF',
      format: '.pdf',
    },
  ],
};
