import bgImgMob from 'assets/img/forms/background-941-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-941-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';

import form941 from 'assets/forms/941.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form941Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-941',
    filePath: form941,
    fileName: '941.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_941.title'),
      description: t('meta.form_941.description'),
    },
    getForm: {
      headline1: 'Filing A',
      headline2: 'Tax Form Online',
      formName: '941',
      subHeadline: '',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline:
        'Completing your 941 Form has never been easier than with PDF Guru',
        description: [
          {
            icon: icon_1,
            iconMob: icon_mob_1,
            title: 'Input your details',
            text: 'Add all the required information to your 941 Form with the help of PDF Guru',
          },
          {
            icon: icon_2,
            iconMob: icon_mob_2,
            title: 'Download the form',
            text: 'Download your finalized Form 941 as a PDF file. You can now choose to print it out either in color or black and white',
          },
          {
            icon: icon_3,
            iconMob: icon_mob_3,
            title: 'Submit the form',
            text: 'Send your completed Form 941 for 2023 electronically or by mail, together with the other required documents',
          },
        ],
    },
    whatIsForm: {
      headline: 'What is Form 941?',
      text_1: [],
      text_2: [
        'You might have also heard of the 941-x Form but don’t know the difference between the two. In a nutshell, this is needed to correct any mistakes that have been made in the regular document. Form 941-x instructions are relatively easy to follow, but make sure to get them right to avoid further edits.',
      ],
      text_3: [
        'The 941 Form is on a quarterly basis, which means there were as many as four deadlines in 2023. Here are the dates:',
      ],
      text_4: [],
      text_5: [
        'Please note that it is absolutely critical to meet these deadlines. Only then will you not run into penalties and allow the IRS to do its job for the good of all.',
      ],
      title_1: 'What is the 941 Tax Form used for?',
      title_2: 'When are Form 941s due in 2023?',
      subTitle:
        "There are at least four things Form 941 is for. The first is to provide a detailed overview of the employer's tax activities. The second, because of the quarterly frequency, is a more frequent reconciliation of tax activities. The third is timely and accurate tax compliance assurance. Finally, the fourth is that it allows monitoring of providential and Medicare contributions.",
      //@ts-ignore
      sectionImage: section_img,
      //@ts-ignore
      sectionImageTab: section_img_tab,
      description: [
        `Form 941 is also known as the Employer's Quarterly Federal Tax Return. In short, it is a document used by employers in the United States. Through it, federal income taxes withheld from employees' wages are reported. But there is more. Social security contributions made during a fiscal quarter are also reported with this form. Such quarterly submission makes it easier: the IRS can actually monitor tax payments more efficiently.`
      ],
      bullets: [],
      redList: [
        { text: 'May 01, 2023' },
        { text: 'July 31, 2023' },
        { text: 'October 31, 2023' },
        { text: 'January 31, 2024' },
      ],
    },
    howToFill: {
      headline: 'How to fill out a 941 Form online',
      //@ts-ignore
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: "",
          text: 'Click the "Fill Form" button at the top or at the bottom of this page',
        },
        {
          icon: blue_number_icon_2,
          strong: "",
          text: 'An Editor window will now appear, and you’ll be able to add all the information required',
        },
        {
          icon: blue_number_icon_3,
          strong: "",
          text: 'Follow the IRS requirements carefully to fill out Form 941 for 2023',
        },
        {
          icon: blue_number_icon_4,
          strong: "",
          text: 'Once you’re done, PDF Guru will let you download your completed 941 Form 2023 PDF file',
        },
      ],
    },
    differenceBetween2: {
      headline: 'How do I submit a 941 Form?',
      text: 'Submitting Form 941 is very simple. Just use our service on PDF Guru to complete all form sections, including Form 941 Schedule B, and verify all the information you enter before proceeding. Once you finish, choose between paper or electronic submission (recommended for greater accuracy and rapidity). Those who submit electronically, however, must also wait for a confirmation receipt from the IRS. Alternatively, bring your printed documents to the nearest IRS office.',
    },
    fillFormFree: {
      headline: 'The Best Way to Fill Your 941 Form',
      subtitle: '',
      buttonLabel: 'Fill Form',
    },
    bannerQuickNEasy: {
      headline: 'Fill Out Form 941 Within Seconds',
      subtitle: 'Complete your form with just a few clicks!',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: "Who needs to file Form 941?",
          answer: [
            "It is the employer who has to fill out the 941 Form 2023. They must also state whether they are subject to additional taxes. These can be, for example, the employer's share of Social Security and Medicare taxes. To do this, simply go to PDF Guru, fill out the document, and then print it out or send it electronically."
          ]
        },
        {
          question: "What do you need to report on Form 941?",
          answer: [
            "If you are following our Form 941 instructions carefully, you will already know this. However, repeating won’t hurt. There are many things to report on a 941 Form 2023. In particular, we talk about payroll-related taxes. You have to report wages paid to employees, federal income tax withheld, and Social Security and Medicare payments. This is for both the employer and the employee. All adjustments or credits, if any, must then be reported and must always be accounted for on this form. Also, remember Schedule B Form 941 which shows your quarterly tax liability. Finally, any corrections must be made with Form 941-x."
          ]
        },
        {
          question: "Can you file Form 941 electronically?",
          answer: [
            "Yes, employers can file the 941 Form electronically. This is very convenient not only for the employer himself but also for the IRS, which encourages this method. It indeed allows for a more efficient and faster, but also more secure, audit. No more sheets of paper, but a digitization that makes life easier for more and more people. In addition, this system speeds up the processing of tax returns."
          ]
        },
        {
          question: "Where to mail Form 941 for 2023?",
          answer: [
            "Some people prefer to print the form and mail it to the IRS address that is nearest to them. Though this is not the method recommended by the IRS itself, which prefers electronic mailing, it is a method you can still use. To find out to which Form 941 mailing address you should send it, just go to the official website and read the instructions provided. Surely there will be an IRS office very close to you!"
          ]
        },
      ],
    },
    fag: {
      question: 'How to fill out a 941 Form online?',
      answer:
        '1. Click the "Fill Form" button at the top or at the bottom of this page. 2. An Editor window will now appear, and you’ll be able to add all the information required. 3. Follow the IRS requirements carefully to fill out Form 941 for 2023. 4. Once you’re done, PDF Guru will let you download your completed 941 Form 2023 PDF file',
    },
  };
};
