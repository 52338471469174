import { Buffer } from 'buffer';
import { getJsonFromStorage } from 'helpers/localStorageHelper';
import isNil from 'lodash/isNil';
import { MAX_FILES_ALLOWED } from 'pages/mergePdf';

import { EReduxTypes } from 'ts/enums/redux.types';
import { IDocumentsState } from 'ts/interfaces/documents/document';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

const initialState: IDocumentsState = {
  documents: {
    data: [],
    meta: {},
  },
  uploadLinks: null,
  uploadedFile: null,
  downloadProgress: 0,
  convertDocumentData: null,
  editDocumentData: null,
  pdfFileContent: null,
  fileUpload: null,
  documentById: null,
  editFilename: '',
  mergeDocumentsList: [],
  sendNotification: false,
  selectedFile: null,
  selectedFiles: [],
  editDocumentsList: undefined,
  editMultipleDocumentsSendNotification: false,
};

const documentsReducer = (state = initialState, action: IReduxAction) => {
  switch (action.type) {
    case EReduxTypes.UPLOAD_DOCUMENT: {
      return {
        ...state,
      };
    }

    case EReduxTypes.SAVE_UPLOADED_FILE: {
      return {
        ...state,
        uploadedFile: action.payload,
        documents: {
          ...state.documents,
          data: [action.payload, ...state.documents.data],
        },
      };
    }

    case EReduxTypes.SHARE_FILE_LINK: {
      return {
        ...state,
        link: action.payload?.url,
      };
    }

    case EReduxTypes.SIGN_OUT: {
      return initialState;
    }

    case EReduxTypes.GET_UPLOAD_LINK: {
      return {
        ...state,
        uploadLinks: action.payload,
      };
    }

    case EReduxTypes.SET_CONVERT_DOCUMENT_DATA: {
      localStorage.setItem('dataToConvert', JSON.stringify(action.data));
      return {
        ...state,
        convertDocumentData: action.data,
      };
    }

    case EReduxTypes.SET_DOCUMENT_FOR_TRANSLATE: {
      return {
        ...state,
        documentForTranslate: action.data,
      };
    }

    case EReduxTypes.SET_TRANSLATE_DOCUMENT_DATA: {
      return {
        ...state,
        translateDocumentData: action.data,
      };
    }

    case EReduxTypes.TRANSLATE_FIRST_PAGE: {
      const base64 = Buffer.from(action.payload as any, 'binary').toString('base64');
      return {
        ...state,
        pdfFileContent: `data:application/pdf;base64,${base64}`,
      };
    }

    case EReduxTypes.MERGE_DOCUMENT: {
      return {
        ...state,
        convertDocumentData: action.data,
      };
    }

    case EReduxTypes.SET_PDF_FILE_CONTENT: {
      return {
        ...state,
        pdfFileContent: action?.data?.document,
      };
    }

    case EReduxTypes.UPLOAD_EDIT_DOCUMENT: {
      return {
        ...state,
        editDocumentData: action.payload,
        editFilename: action.payload?.filename?.replace('.pdf', ''),
      };
    }

    case EReduxTypes.UPDATE_EDIT_FILENAME: {
      // set new file name to localStorage
      const dataToEdit: any = getJsonFromStorage('dataToEdit');
      if (dataToEdit?.file) dataToEdit.file.filename = `${action.data?.filename}.pdf`;
      localStorage.setItem('dataToEdit', JSON.stringify(dataToEdit));

      return {
        ...state,
        editFilename: action.data?.filename,
      };
    }

    case EReduxTypes.UPDATE_EDIT_DOCUMENT_DATA: {
      return {
        ...state,
        editDocumentData: {
          ...state.editDocumentData,
          ...action.data,
        },
      };
    }

    case EReduxTypes.GET_CONVERTED_FILE: {
      const setFileToRedux = action?.data?.setFileToRedux;
      return {
        ...state,
        fileUpload: action.data,
        documents: {
          ...state.documents,
          data: setFileToRedux ? [action.payload, ...state.documents.data] : state.documents.data,
        },
      };
    }

    case EReduxTypes.CONVERT_DOCUMENT: {
      return {
        ...state,
        fileUpload: action.data,
      };
    }

    case EReduxTypes.GET_SUPPORTED_LANGUAGES: {
      return {
        ...state,
        supportedLanguages: action.payload || [],
      };
    }

    case EReduxTypes.UPDATE_DOWNLOAD_PROGRESS: {
      return {
        ...state,
        downloadProgress: action?.data?.progress,
      };
    }

    case EReduxTypes.DELETE_DOCUMENT_BY_ID: {
      const newDocuments = state?.documents?.data?.filter(
        (item) => item?.id !== action.data.documentId
      );
      return {
        ...state,
        documents: {
          data: newDocuments?.length ? [...newDocuments] : [],
          meta: {},
        },
      };
    }

    case EReduxTypes.GET_DOCUMENTS: {
      const data = (action?.payload?.files || [])?.sort(
        (a: any, b: any) => new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime()
      );
      return {
        ...state,
        documents: {
          data,
          meta: {},
        },
      };
    }

    case EReduxTypes.SET_SELECTED_FILE: {
      return {
        ...state,
        selectedFile: action.data?.file,
      };
    }

    case EReduxTypes.UPDATE_FILENAME:
      return {
        ...state,
        documents: {
          ...state.documents,
          data: state.documents.data.map((doc) =>
            doc.id === action.data?.documentId
              ? { ...doc, filename: action.payload?.body.filename }
              : doc
          ),
        },
      };

    case EReduxTypes.GET_DOCUMENT_BY_ID: {
      return {
        ...state,
        documentById: { ...action?.payload, id: action?.data?.documentId },
      };
    }

    case EReduxTypes.DOWNLOAD_DOCUMENT_AND_UPDATE_DOCUMENT_ID:
    case EReduxTypes.DOWNLOAD_DOCUMENT: {
      if (action?.payload?.url && action?.payload?.filename !== 'dummy-convert') {
        const link = document?.createElement('a');

        link.href = action?.payload?.url;
        link.setAttribute('target', '_blank');
        link.style.display = 'none';
        link.setAttribute('download', action?.payload?.filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      }

      if (action?.type === EReduxTypes.DOWNLOAD_DOCUMENT_AND_UPDATE_DOCUMENT_ID) {
        return {
          ...state,
          documentById: { ...action?.payload, id: action?.data?.documentId },
        };
      }

      return state;
    }

    case EReduxTypes.SET_EDIT_MULTIPLE_DOCUMENT_DATA: {
      return {
        ...state,
        editDocumentsList: action.data,
        sendNotification: false,
      };
    }

    case EReduxTypes.PUSH_EDIT_MULTIPLE_DOCUMENT_DATA: {
      if (state.editDocumentsList.length >= MAX_FILES_ALLOWED) {
        if (state.sendNotification) return state;
        return {
          ...state,
          sendNotification: true,
        };
      }

      return {
        ...state,
        editDocumentsList: [...state.editDocumentsList, ...action.data],
      };
    }

    case EReduxTypes.SET_MERGE_DOCUMENT_DATA: {
      return {
        ...state,
        mergeDocumentsList: action.data,
        sendNotification: false,
      };
    }

    case EReduxTypes.PUSH_MERGE_DOCUMENT_DATA: {
      if (!action.data.skipValidate && state.mergeDocumentsList.length >= MAX_FILES_ALLOWED) {
        if (state.sendNotification) return state;
        return {
          ...state,
          sendNotification: true,
        };
      }

      if (!isNil(action.data.index)) {
        const newMergeDocumentsList = [...state.mergeDocumentsList];
        newMergeDocumentsList.splice(action.data.index, 0, action.data.file);

        action?.onSuccess?.({});
        return {
          ...state,
          mergeDocumentsList: newMergeDocumentsList,
        };
      }

      action?.onSuccess?.({});
      return {
        ...state,
        mergeDocumentsList: [...state.mergeDocumentsList, action.data.file],
      };
    }

    case EReduxTypes.UPDATE_MERGE_DOCUMENT_IMAGE: {
      if (!action.data.thumbnail) return state;

      const newMergeDocumentsList = [...state.mergeDocumentsList];
      newMergeDocumentsList[action.data.index].thumbnail = action.data.thumbnail;

      return {
        ...state,
        mergeDocumentsList: newMergeDocumentsList,
      };
    }

    case EReduxTypes.SET_SELECTED_FILES: {
      return {
        ...state,
        selectedFiles: action.data,
      };
    }

    case EReduxTypes.CONVERT_MULTIPLE_DOCUMENTS: {
      return {
        ...state,
        convertMultipleDocuments: action.data,
      };
    }

    default:
      return state;
  }
};

export default documentsReducer;
