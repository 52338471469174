import FlagGB from 'react-world-flags/src/svgs/gb.svg';
import FlagES from 'react-world-flags/src/svgs/es.svg';
import FlagFR from 'react-world-flags/src/svgs/fr.svg';
import FlagPT from 'react-world-flags/src/svgs/pt.svg';
import FlagDE from 'react-world-flags/src/svgs/de.svg';
import { Language } from "./localesFull";

export const sourceDefaultLang: Language[] = [
  { language: 'English', nativeName: 'English', code: 'en', flag: 'gb', francName: 'eng', link: FlagGB },
  { language: 'Spanish', nativeName: 'Español', code: 'es', flag: 'es', francName: 'spa', link: FlagES },
  { language: 'French', nativeName: 'Français', code: 'fr', flag: 'fr', francName: 'fra', link: FlagFR },
  { language: 'Portuguese', nativeName: 'Português', code: 'pt', flag: 'pt', francName: 'por', link: FlagPT },
  { language: 'German', nativeName: 'Deutsch', code: 'de', flag: 'de', francName: 'deu', link: FlagDE },
];

export const outputDefaultLang: Language[] = [
  { language: 'Spanish', nativeName: 'Español', code: 'es', flag: 'es', francName: 'spa', link: FlagES },
  { language: 'English', nativeName: 'English', code: 'en', flag: 'gb', francName: 'eng', link: FlagGB },
  { language: 'French', nativeName: 'Français', code: 'fr', flag: 'fr', francName: 'fra', link: FlagFR },
  { language: 'Portuguese', nativeName: 'Português', code: 'pt', flag: 'pt', francName: 'por', link: FlagPT },
  { language: 'German', nativeName: 'Deutsch', code: 'de', flag: 'de', francName: 'deu', link: FlagDE },
];
