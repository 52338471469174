import { AnyAction } from 'redux';

import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const triggerLoadingAction = (components: string[], loading: boolean): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_LOADING_COMPONENT,
    data: {
      components,
      loading,
    },
  };
  return action;
};

export const triggerLoadingActionWithId = (
  components: string[],
  loading: boolean,
  id: string
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.UPDATE_MULTIPLE_LOADING_COMPONENTS_WITH_ID,
    data: {
      components,
      loading,
      id,
    },
  };

  return action;
};
