import imgExel from './assets/excel-icon.svg';
import imgImage from './assets/jpg-icon.svg';
import imgPdf from './assets/pdf-icon.svg';
import imgPng from './assets/png-icon.svg';
import imgPtt from './assets/pptx-icon.svg';
import imgWord from './assets/word-icon.svg';
import imgEpub from './assets/epub-icon.svg';

export type Format = {
  label: string;
  description: string;
  icon: string;
  to: string;
  format: string;
};

export const getFormatsList = (t: (key: string) => string): Format[] => [
  {
    label: 'PDF',
    description: t('popups.choose_format_modal_import_export_editor_244_b.formats_list.pdf.description'),
    icon: imgPdf,
    to: 'PDF', 
    format: '.pdf',
  },
  {
    label: 'DOCX',
    description: t('popups.choose_format_modal_import_export_editor_244_b.formats_list.word.description'),
    icon: imgWord,
    to: 'DOCX',
    format: '.docx',
  },
  {
    label: 'XLSX',
    description: t('popups.choose_format_modal_import_export_editor_244_b.formats_list.excel.description'),
    icon: imgExel,
    to: 'XLS',
    format: '.xls',
  },
  {
    label: 'PPTX',
    description: t('popups.choose_format_modal_import_export_editor_244_b.formats_list.pptx.description'),
    icon: imgPtt,
    to: 'PPTX',
    format: '.pptx',
  },
  {
    label: 'JPG',
    description: t('popups.choose_format_modal_import_export_editor_244_b.formats_list.jpg.description'),
    icon: imgImage,
    to: 'JPG',
    format: '.jpg',
  },
  {
    label: 'PNG',
    description: t('popups.choose_format_modal_import_export_editor_244_b.formats_list.png.description'),
    icon: imgPng,
    to: 'PNG',
    format: '.png',
  },
  {
    label: 'EPUB',
    description: t('popups.choose_format_modal_import_export_editor_244_b.formats_list.epub.description'),
    icon: imgEpub,
    to: 'EPUB',
    format: '.epub',
  },
];
