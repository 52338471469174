import { Dispatch } from 'react';

import {
  removeCurrentThreadData,
  setCurrentFileUrl,
  setCurrentThreadId,
  setFileId,
  setFileName,
} from 'data/actions/aiSummarizer';

export const resetChat = async (dispatch: Dispatch<any>) => {
  dispatch(setCurrentThreadId(null));
  dispatch(removeCurrentThreadData());
  dispatch(setCurrentFileUrl(null));
  dispatch(setFileName(null));
  dispatch(setFileId(null));
};
