import lottie from 'lottie-web/build/player/lottie_light';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import animationData from 'assets/animations/check-animation.json';
import useDisableScroll from 'hooks/useDisableScroll';
import ModalContainer from '../baseModal/base';
import s from './purchase-success.module.scss';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';

const ThankYouPaymentModal: FC = () => {
  const { t } = useTranslation();
  const container = useRef<HTMLDivElement>(null);
  useDisableScroll();
  useCloseModalOnBack();

  useEffect(() => {
    if (!container.current) return;
    if (typeof window === 'undefined') return;

    const animationPlayer = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: animationData,
    });

    return () => {
      animationPlayer.destroy();
    };
  }, [container]);

  return (
    <ModalContainer dataTestId="thank-you-payment-modal">
      <div className={s.convertingPopup}>
        <div ref={container} className={s.animation} />
        <h2>{t('popups.purchase_success.title')}</h2>
      </div>
    </ModalContainer>
  );
};

export default ThankYouPaymentModal;
