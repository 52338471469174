import { config } from 'configuration'

export enum LogLevels {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export class Logger {
  private readonly _name: string

  constructor(name: string) {
    this._name = name
  }

  get isProd() {
    return config.appEnv === 'production'
  }

  public log(message: string, data?: DataPayload) {
    this.sendLogs(LogLevels.INFO, message, data)
    if (!this.isProd) {
      console.log(`[${this._name}] ${message}`, data)
    }
  }

  public warn(message: string, data?: DataPayload) {
    this.sendLogs(LogLevels.WARN, message, data)
    if (!this.isProd) {
      console.warn(`[${this._name}] ${message}`, data)
    }
  }

  public error(message: string, data?: DataPayload) {
    this.sendLogs(LogLevels.ERROR, message, data)
    if (!this.isProd) {
      console.trace(`[${this._name}] ${message}`, data)
    }
  }

  private sendLogs(level: LogLevels, message: string, data?: DataPayload) {
    // fetch('/api/logger', {
    //   method: 'POST',
    //   credentials: 'include',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     level,
    //     message,
    //     data,
    //     context: this._name,
    //   }),
    // })
  }
}

type DataPayload = Record<string, string | number | boolean | null>
