import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import editFill from './assets/edit-pdf.svg';
import imgExel from './assets/pdf-excel.svg';
import imgEpub from './assets/pdf-epub.svg';
import imgImage from './assets/pdf-jpg.svg';
import imgPdf from 'assets/img/icons/20px/PDF.svg';
import imgPtt from './assets/pdf-pttx.svg';
import imgWord from './assets/pdf-word.svg';
import imgPng from './assets/pdf-png.svg';
import editStroke from 'assets/img/icons/24px/edit-stroke.svg';
import compress from './assets/compress.svg';
import merge from './assets/merge-pdf.svg';
import split from './assets/split-pdf.svg';
import protect from './assets/protect-pdf.svg';
import signature from './assets/sign-pdf.svg';
import forms from './assets/forms.svg';
import s from './icon-button-new-design.module.scss';
import ocr from 'assets/img/icons/24px/ocr.svg';
import imagePdf from './assets/image-to-pdf.svg';
import pdfConverter from './assets/pdf-converter.svg';
import translateIcon from 'assets/img/icons/50px/translate-icon.svg';

export type ButtonIcon =
  | 'edit-stroke'
  | 'edit-fill'
  | 'pdf'
  | 'word'
  | 'ptt'
  | 'img'
  | 'png'
  | 'spreadsheets'
  | 'compress'
  | 'merge'
  | 'split'
  | 'protect'
  | 'signature'
  | 'epub'
  | 'forms'
  | 'ocr'
  | 'image-pdf'
  | 'translate'
  | 'pdf-converter';

interface IProps {
  icon: ButtonIcon;
  classname?: string;
  imageClassnames?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const IconButton: React.FC<IProps & React.HTMLProps<HTMLButtonElement>> = ({
  icon,
  classname,
  imageClassnames,
  onClick,
  children,
}) => {
  const { i18n } = useTranslation();

  const images: Record<ButtonIcon, string> = {
    'edit-stroke': editStroke,
    'edit-fill': editFill,
    pdf: imgPdf,
    word: imgWord,
    ptt: imgPtt,
    img: imgImage,
    png: imgPng,
    epub: imgEpub,
    spreadsheets: imgExel,
    compress: compress,
    merge: merge,
    split: split,
    protect: protect,
    signature: signature,
    forms: forms,
    ocr: ocr,
    'image-pdf': imagePdf,
    'pdf-converter': pdfConverter,
    'translate': translateIcon,
  };

  return (
    <button className={`${s.iconButton} ${classname ? classname : ''}`} onClick={onClick}>
      <img
        src={images[icon]}
        alt="Icon"
        className={classNames('mr-[10px]', {
          'mr-0 ml-[10px]': i18n?.dir() === 'rtl',
          [`${imageClassnames}`]: imageClassnames,
        })}
      />
      <div>{children}</div>
    </button>
  );
};
