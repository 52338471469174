import { config } from 'configuration';
import { InternalFileType, InternalType } from 'ts/types/file';

export const isEmailValid = (email: string): boolean => {
  // eslint-disable-next-line
  return /(?:[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@([\w-]+\.)+[\w-]{2,8}$/.test(
    email
  );
};

interface ValidateSelectedFileProps {
  files: FileList | null;
  type: InternalFileType | InternalFileType[];
  translator: (key: string) => string;
  isPDFConverter?: boolean;
}
interface ValidateSuccess {
  valid: true;
  file: File;
  error: null;
}
interface ValidateError {
  valid: false;
  file: null;
  error: string;
  code: string;
}
export const validateSelectedFile = (
  data: ValidateSelectedFileProps
): ValidateSuccess | ValidateError => {
  const { files, type, translator, isPDFConverter } = data;

  if (!files || files.length === 0) {
    return {
      valid: false,
      error: 'file missing',
      code: 'file-missing',
      file: null,
    };
  }

  const file = files[0];
  if (file.size > config.api.maxFileSizeMb * 1024 * 1024) {
    return {
      valid: false,
      error: translator('api_errors.error.file.max-size'),
      code: 'max-size',
      file: null,
    };
  }

  if (isPDFConverter) {
    return {
      valid: true,
      file,
      error: null,
    };
  }

  const types = Array.isArray(type) ? type : [type];

  if (!file.type || file.type === '') {
    if (Array.isArray(type)) {
      if (!type.some((type) => file.name.toLowerCase().endsWith(type.toString().toLowerCase()))) {
        return {
          valid: false,
          error: translator('api_errors.error.file.wrong-format'),
          code: 'wrong-format',
          file: null,
        };
      }
    } else {
      if (!file.name.toLowerCase().includes(type.toString().toLowerCase() as InternalFileType)) {
        return {
          valid: false,
          error: translator('api_errors.error.file.wrong-format'),
          code: 'wrong-format',
          file: null,
        };
      }
    }
  } else {
    if (!InternalType.toMultipleMimetypes(types).includes(file.type)) {
      return {
        valid: false,
        error: translator('api_errors.error.file.wrong-format'),
        code: 'wrong-format',
        file: null,
      };
    }
  }

  return {
    valid: true,
    file,
    error: null,
  };
};
