import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import webP from 'assets/img/icons/20px-new-design/IMG.svg';
import classNames from 'classnames';
import { getJsonFromStorage } from 'helpers/localStorageHelper';
import { useIsRtl } from 'hooks/isRtl';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';

import { PrimaryButton } from 'components/buttons/primary-button';
import { LoadingAnimation } from 'components/loading-animation';
import ModalLayout from 'components/modals/baseModal/base';

import { Analytics } from 'services/analytics';

import { updateEditDocumentData, updateEditFilename } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import { convertDocumentDataSelector, editFilenameSelector } from 'data/selectors/documents';
import { getLoadingSelector } from 'data/selectors/loading';
import { modalOptionsSelector } from 'data/selectors/modals';

import { getPrevPage } from 'utils/prevPath';

import imgEpub from '../../buttons/icon-button-new-design/assets/pdf-epub.svg';
import radio_off from './assets/radio-off.svg';
import radio_on from './assets/radio-on.svg';
import s from './converter.module.scss';
import { formatsList } from './formatsList';

const ChooseFormatModal: FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isLoading = useSelector(getLoadingSelector('save_edit_document'));

  const { documentId, handleSaveFile } = useSelector(modalOptionsSelector);
  const editFilename = useSelector(editFilenameSelector());

  useCloseModalOnBack();

  const dataToConvert = useSelector(convertDocumentDataSelector());
  const [searchParams] = useSearchParams();
  const convertTo = searchParams.get('to');
  const isRtl = useIsRtl();

  const [currentFormat, setCurrentFormat] = useState<{
    format: string;
    label: string;
    to: string;
    icon?: any;
  }>(formatsList[0]);

  const dynamicFormatsList = useMemo(
    () => [
      ...formatsList,
      ...(convertTo === 'EPUB'
        ? [
            {
              label: 'EPUB',
              icon: imgEpub,
              to: 'EPUB',
              format: '.epub',
            },
          ]
        : []),
      ...(convertTo === 'WEBP'
        ? [
            {
              label: 'WEBP',
              to: 'WEBP',
              icon: webP,
              format: '.webp',
            },
          ]
        : []),
    ],
    [convertTo]
  );

  useEffect(() => {
    Analytics.sendEvent({
      event: 'save_edited_file_modal_view',
      data: {
        screen_config_name: 'formats_and_filename',
      },
    });
  }, []);

  const handleCancel = () => {
    Analytics.sendEvent({ event: 'cancel_save_edited_file' });
    dispatch(toggleModal({ visible: false }));
  };

  useEffect(() => {
    const selectedFormat = dynamicFormatsList.find((format) => format.to === convertTo);
    if (convertTo && selectedFormat) {
      handleSetCurrentFormat(selectedFormat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertTo]);

  const handleSetCurrentFormat = (format: {
    format: string;
    label: string;
    to: string;
    icon?: any;
  }) => {
    const dataToEdit: any = getJsonFromStorage('dataToEdit');
    if (dataToEdit) {
      dataToEdit.from = 'PDF';
      dataToEdit.to = format.to;
      dataToEdit.documentId = documentId;
    }
    dispatch(updateEditDocumentData({ to: format.to === 'PDF' ? '' : format.to }));
    localStorage.setItem('dataToEdit', JSON.stringify(dataToEdit));

    setCurrentFormat(format);
  };

  useEffect(() => {
    const selectedFormat = formatsList.find((format) => format.to === convertTo);
    if (convertTo && selectedFormat) {
      handleSetCurrentFormat(selectedFormat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertTo]);

  const handleDownload = () => {
    Analytics.sendEvent({
      event: 'select_type_tap',
      data: {
        features_name: getConverterName(currentFormat.to),
        name_changed: dataToConvert?.filename !== editFilename,
        fromPage: getPrevPage() || '',
      },
    });
    handleSaveFile(editFilename, currentFormat.to);
  };

  const getConverterName = (selectedFormat: string) => {
    if (selectedFormat === 'PDF') {
      return `as_pdf`;
    }

    return `pdf_to_${selectedFormat.toLowerCase()}`;
  };

  if (isLoading) {
    return (
      <>
        <div className="w-full h-full fixed top-0 left-0 z-[10006] bg-[rgba(0,0,0,0.6)] flex justify-center items-center px-4">
          <div
            className="max-w-[557px] w-full h-[300px] tablet:h-[340px] p-6 flex flex-col justify-center items-center bg-white
            rounded-[10px] tablet:text-[22px] text-[18px] leading-[24px] text-[#373737]"
          >
            <LoadingAnimation
              currentState="loading"
              className="tablet:!w-[160px] tablet:!h-[160px] !w-[100px] !h-[100px]"
            />
            <p className="font-[700] tablet:my-4 my-2 text-center">{t('editor_page.saving')}</p>
            <p className="text-[#878787] text-[16px]">{t('editor_page.please_wait')}</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <ModalLayout
      canClose
      closePopup={() => Analytics.sendEvent({ event: 'cancel_save_edited_file' })}
      dataTestId="choose-format-modal"
    >
      <div
        className="flex flex-col text-[#1D1D1D] items-center w-full mobile:min-w-[630px]
      mobile:max-w-[600px] p-4"
      >
        <h2 className="text-[25px] font-extrabold leading-[32.5px] mt-6 tablet:mt-6">
          {t('editor_page.convert_banner.title_b')}
        </h2>
        <div className="text-[16px] text-[#616161] leading-[20.8px] tablet:mt-4">
          {t('editor_page.convert_banner.description')}
        </div>

        <div className="w-full grid grid-cols-2 gap-3 tablet:gap-4 tablet:px-6 mt-6 tablet:mt-8">
          {dynamicFormatsList.map((item, index) => (
            <div
              className={classNames(
                'flex items-center text-[16px] font-semibold justify-between cursor-pointer w-full max-w-[267px] p-4 bg-[#f6f6f6] border-2 border-[#f6f6f6] rounded-[6px] transition-all duration-100',
                {
                  '!border-[2px] !border-solid !border-[#5F30E2] !bg-white flex flex-wrap':
                    currentFormat.format === item.format,
                }
              )}
              onClick={() => handleSetCurrentFormat(item)}
              data-testid={`format-item`}
            >
              <div className="flex">
                {currentFormat.format === item.format ? (
                  <img
                    src={radio_on}
                    alt="radio-off"
                    className={classNames('w-5 tablet:w-7 cursor-pointer', {
                      'ml-2': isRtl,
                      'mr-2': !isRtl,
                    })}
                  />
                ) : (
                  <img
                    src={radio_off}
                    alt="radio-off"
                    className={classNames('w-5 tablet:w-7 cursor-pointer', {
                      'ml-2': isRtl,
                      'mr-2': !isRtl,
                    })}
                  />
                )}
                <span>{item.label}</span>
              </div>
              <LazyLoadImage className={s.icons} src={item.icon} alt="format icon" />
            </div>
          ))}
        </div>

        <div className="flex flex-col w-full mt-6 tablet:mt-8 tablet:px-6">
          <label htmlFor="fileName" className="right-0">
            {t('editor_page.convert_banner.file_name_placeholder')}
          </label>
          <input
            type="text"
            id="fileName"
            name="fileName"
            placeholder={t('editor_page.convert_banner.file_name_placeholder')}
            value={editFilename}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              dispatch(updateEditFilename(event.target.value));
            }}
            className="rounded-md border border-[#C7C7C7] py-3 px-4 mt-2"
          />
        </div>

        <div className="flex mt-6 tablet:mt-8 items-center justify-center tablet:mb-4 w-full">
          {' '}
          <PrimaryButton
            onClick={handleCancel}
            className={classNames(
              'bg-white border-2 border-[#1D1D1D] hover:bg-white text-[#1D1D1D] hover:text-[#1D1D1D] min-w-[200px] h-[57px] mr-6 hidden tablet:block',
              {
                'ml-6': i18n?.dir() === 'rtl',
                'mr-6': i18n?.dir() !== 'rtl',
              }
            )}
            dataTestId="cancel-button"
          >
            {t('editor_page.convert_banner.cancel')}
          </PrimaryButton>
          <PrimaryButton
            onClick={handleDownload}
            dataTestId="download-button"
            className="min-w-full tablet:min-w-[200px] bg-[#D2294B] h-[57px] hover:bg-[#f4476ae0] text-[20px] mobile:!leading-[30px] py-5 mobile:py-[17px]"
          >
            {t('editor_page.convert_banner.convert')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};

export default ChooseFormatModal;
