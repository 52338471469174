import * as pdfjs from 'pdfjs-dist';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { modalOptionsSelector } from 'data/selectors/modals';
import { isNil } from 'lodash';
import ModalLayout from '../baseModal/base';

export const MergePdfFilePreviewModal = () => {
  const [numOfPages, setNumOfPages] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const options = useSelector(modalOptionsSelector);

  const renderPdf = useCallback(
    (data: string | URL | ArrayBuffer) => {
      const viewer = document.getElementById('pdf-viewer');
      if (!viewer) return;

      pdfjs
        .getDocument(data)
        .promise.then((pdf) => {
          viewer.innerHTML = '';
          setNumOfPages(pdf.numPages);
          setCurrentPage(1);

          for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
            const canvas = document.createElement('canvas');
            canvas.className = 'pdf-page-canvas';
            canvas.onmouseover = () => setCurrentPage(pageNumber);

            viewer.appendChild(canvas);
            pdf.getPage(pageNumber).then((page) => {
              const viewport = page.getViewport({ scale: 1 });
              const scaleFactor =
                viewport.width > options?.pageMaxWidth ? options?.pageMaxWidth / viewport.width : 1;
              const scaledViewport = page.getViewport({ scale: scaleFactor });

              canvas.width = scaledViewport.width;
              canvas.height = scaledViewport.height;

              const context = canvas.getContext('2d');
              //@ts-ignore
              page.render({ canvasContext: context, viewport: scaledViewport });
            });
          }
        })
        .catch((error) => {
          console.error('Error rendering PDF:', error);
        });
    },
    [options?.pageMaxWidth]
  );

  useEffect(() => {
    if (options?.file) {
      options.file.arrayBuffer().then((buffer: ArrayBuffer) => renderPdf(buffer));
    }
  }, [options?.file, renderPdf]);

  return (
    <ModalLayout closeOnBackdrop dataTestId="merge-pdf-file-preview-modal">
      <div className="rounded-tl-[4px] border-l border-t bg-white w-[570px] relative">
        <div className="h-full px-4 py-[43.5px] bg-[#F6F6F6] relative max-h-[875px] overflow-y-auto">
          <div
            id="pdf-viewer"
            className="w-full overflow-y-auto flex flex-col items-center bg-[#F6F6F6] gap-1 scrollbar-hidden"
          ></div>
        </div>
        {!isNil(numOfPages) && (
          <div className="absolute right-3 bottom-3 px-3 py-2 shadow-md bg-white rounded-[4px] text-sm">
            {currentPage}/{numOfPages}
          </div>
        )}
      </div>
    </ModalLayout>
  );
};
