import { useTranslation } from 'react-i18next';

export const NoMessage = ({ message }: { message: string }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 h-full">
      <p className="text-gray-500">{message}</p>
      <button
        onClick={() => window.location.reload()}
        className="ml-4 text-blue-500 underline w-max !m-0"
      >
        {t('ai_summarizer.messages.reload_page')}
      </button>
    </div>
  );
};
