import { createSelector } from 'reselect';

import { IAiSummarizerState } from 'data/reducers/aiSummarizer';
import { RootState } from 'data/reducers/rootReducer';

const baseAiSummarizerSelector = (state: RootState) => state.aiSummarizer;

export const chatHistorySelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.chatsHistory || []
  }
);

export const fileIdSelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.fileId || null
  }
);

export const currentThreadIdSelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.currentThreadId || null
  }
);

export const currentThreadDataSelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.currentThreadData || []
  }
);

export const currentFileUrlSelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.currentFileUrl || null
  }
);

export const newFileDataSelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.newFileData || null
  }
);

export const fileNameSelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.fileName || null
  }
);

export const isMobileMenuOpenSelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.isMobileMenuOpen || false
  }
);


export const freeLimitReachedSelector = createSelector(
  baseAiSummarizerSelector,
  (aiSummarizer: IAiSummarizerState) => {
    return aiSummarizer.freeLimitReached || false
  }
);
