import { PDFDocument } from 'pdf-lib';

export const splitPdfByPages = async (file: File): Promise<File[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          // Load the PDF
          const pdfDoc = await PDFDocument.load(e.target?.result as ArrayBuffer, {
            ignoreEncryption: true,
          });
          const totalPages = pdfDoc.getPageCount();
          const splitFiles: File[] = [];

          for (let i = 0; i < totalPages; i++) {
            const newPdf = await PDFDocument.create();
            const [copiedPage] = await newPdf.copyPages(pdfDoc, [i]);
            newPdf.addPage(copiedPage);

            // Serialize new PDF
            const pdfBytes = await newPdf.save();
            const newPdfFile = new File(
              [pdfBytes],
              `${file.name.split('.')[0]}_page_${i + 1}.pdf`,
              { type: 'application/pdf' }
            );

            splitFiles.push(newPdfFile);
          }

          resolve(splitFiles);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    } catch (error) {
      reject(error);
    }
  });
};
