import { FC } from 'react';

import image_cancel from 'assets/img/icons/120px/image_cancel_modal.svg';
import ModalLayout from 'components/modals/baseModal/base';
import { SecondaryButton } from 'components/buttons/secondary-button';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'components/buttons/primary-button';
import { Analytics } from 'services/analytics';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'data/actions/modals';
import { PAGE_LINKS } from 'ts/constants/page-links';
import { useLocaleNavigate } from 'hooks/useLocaleNavigate';
import { useSelector } from 'react-redux';
import { userSubscriptionSelector } from 'data/selectors/user';

const CancelPlanModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useLocaleNavigate();
  const subscription = useSelector(userSubscriptionSelector);
  const isTrial = subscription?.isTrial && subscription?.trial_price;

  const handleKeepPlanClick = () => {
    Analytics.sendEvent({
      event: 'cancel_plan_modal_tap',
      data: {
        paymentPlan: isTrial ? 'trial' : 'rebill',
        result: 'keep_plan',
      },
    });
    dispatch(toggleModal({ visible: false }));
  };

  const handleCancelPlanClick = () => {
    Analytics.sendEvent({
      event: 'cancel_plan_modal_tap',
      data: {
        paymentPlan: isTrial ? 'trial' : 'rebill',
        result: 'cancel_confirm',
      },
    });
    dispatch(toggleModal({ visible: false }));
    navigate(`${PAGE_LINKS.CANCEL_COUPON}`);
  };

  return (
    <ModalLayout canClose>
      <div
        className="bg-white rounded-2xl tablet:pt-8 tablet:pb-10 tablet:px-[44px] pt-[44px] pb-4 px-4
        max-w-[657px] w-full relative text-[#575757] flex flex-col justify-center items-center text-center"
      >
        <img src={image_cancel} alt="image_cancel" className="" />
        <h3 className="tablet:text-[24px] tablet:leading-[34px] text-[20px] leading-[30px] text-[#373737] mt-8 font-bold mb-1">
          {isTrial
            ? t('popups.cancel_flow_popup.cancel_trial')
            : t('popups.cancel_flow_popup.cancel_plan')}
        </h3>
        <p>{t('popups.cancel_flow_popup.subtitle')}</p>
        <p className="mt-6">{t('popups.cancel_flow_popup.you_sure')}</p>
        <div className="mt-10 flex  tablet:max-w-[521px] justify-center items-center ">
          <div className="flex tablet:flex-row flex-col tablet:gap-x-6 gap-y-4 tablet:gap-y-0">
            <SecondaryButton
              dataTestId='cancel-plan'
              onClick={handleCancelPlanClick}
              className="flex-grow tablet:!w-[248px] medium-mobile:!w-[346px] small-mobile:!w-[336px] !w-[310px] py-[17px] !text-[#878787] !border-[#DEDEDE]"
            >
              {isTrial
                ? t('popups.cancel_flow_popup.cancel_trial')
                : t('popups.cancel_flow_popup.cancel_plan')}
            </SecondaryButton>
            <PrimaryButton
              dataTestId='keep-plan'
              onClick={handleKeepPlanClick}
              className="flex-grow tablet:!w-[248px] medium-mobile:!w-[346px] small-mobile:!w-[336px] !w-[310px] py-[17px]"
            >
              {isTrial
                ? t('popups.cancel_flow_popup.keep_trial')
                : t('popups.cancel_flow_popup.keep_plan')}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CancelPlanModal;
