
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import userAvatar from 'assets/ai-summarizer/avatar.svg';

import { CHOOSE_PLAN_URL } from 'use-cases/ai-summarizer/const';
import { redirectToChoosePlan } from 'use-cases/ai-summarizer/redirect-to-choose-plan';

import { Analytics } from 'services/analytics';

import { toggleModal } from 'data/actions/modals';
import { userDataSelector } from 'data/selectors/user';

import { EModalTypes } from 'ts/enums/modal.types';

export const Account = () => {
  const dispatch = useDispatch();
  const user = useSelector(userDataSelector);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderUserContent = () => {
    if (!user) {
      return null;
    }

    if (!user.email) {
      return (
        <>
          <h2 className="text-base font-bold mb-1">{t('ai_summarizer.become_pro')}</h2>
        </>
      );
    }

    return (
      <div className="flex items-center gap-3">
        <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
          <img src={userAvatar} alt="user" width={40} height={40} className="min-h-10 min-w-10" />
        </div>
        <span className="text-gray-600 text-sm truncate">{user.email}</span>
      </div>
    );
  };

  const handleUpgrade = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    Analytics.sendEvent({
      event: 'click_banner_tap',
    });

    if (!user?.email) {
      dispatch(toggleModal({ visible: true, type: EModalTypes.ENTER_EMAIL_AI_SUMMARIZER }));

      return;
    }

    redirectToChoosePlan(navigate);
  };

  const renderUpgradeButton = () => {
    if (!user || user?.subscription) {
      return null;
    }

    return (
      <a
        href={CHOOSE_PLAN_URL}
        onClick={(e) => handleUpgrade(e)}
        className="mt-4 inline-flex items-center justify-center gap-2 px-6 py-2 text-base font-bold text-[#192CD2] border-2 border-[#192CD2] rounded-[8px] hover:bg-[#192CD2] hover:text-[#fff] transition-colors w-full"
        data-testid="ai-summarizer-upgrade-button"
      >
        <span>{t('ai_summarizer.go_to_pro')}</span>{' '}
      </a>
    );
  };

  return (
    <div className="bg-[#F6F7FD] p-4 rounded-2xl max-w-[400px] text-center mx-auto w-full">
      {renderUserContent()}
      {renderUpgradeButton()}
    </div>
  );
};
