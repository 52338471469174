import { AnyAction } from 'redux';

import {
  INewFileData,
  IChatHistoryItem,
  ICurrentThreadAssistantMessage,
  ICurrentThreadData,
} from 'data/reducers/aiSummarizer';

import { EReduxTypes } from 'ts/enums/redux.types';
import { IReduxAction } from 'ts/interfaces/redux/redux.action';

interface ISetChatsHistoryData {
  chatsHistory: IChatHistoryItem[];
}

export const setChatsHistory = (chatsHistory: IChatHistoryItem[]): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_CHATS_HISTORY,
    data: {
      chatsHistory,
    } as ISetChatsHistoryData,
  };
  return action;
};

interface ISetFileIdData {
  fileId: string;
}

export const setFileId = (fileId: string | null): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_FILE_ID,
    data: { fileId } as ISetFileIdData,
  };
  return action;
};

interface ISetCurrentThreadIdData {
  currentThreadId: string | null;
}

export const setCurrentThreadId = (currentThreadId: string | null): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_CURRENT_THREAD_ID,
    data: { currentThreadId } as ISetCurrentThreadIdData,
  };
  return action;
};

interface ISetCurrentThreadAssistantMessageData {
  currentThreadData: ICurrentThreadAssistantMessage;
}

export const setCurrentThreadAssistantMessage = (
  currentThreadData: ICurrentThreadData
): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_CURRENT_THREAD_ASSISTANT_MESSAGE,
    data: { currentThreadData } as ISetCurrentThreadAssistantMessageData,
  };

  return action;
};

interface ISetCurrentThreadUserMessageData {
  message: string;
}

export const setCurrentThreadUserMessage = (message: string): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_CURRENT_THREAD_USER_MESSAGE,
    data: { message } as ISetCurrentThreadUserMessageData,
  };

  return action;
};

export const removeCurrentThreadData = (): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.REMOVE_CURRENT_THREAD_DATA,
    data: {},
  };

  return action;
};

interface ISetCurrentFileUrlData {
  currentFileUrl: string | null;
}

export const setCurrentFileUrl = (currentFileUrl: string | null): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_CURRENT_FILE_URL,
    data: { currentFileUrl } as ISetCurrentFileUrlData,
  };

  return action;
};

interface ISetNewFileData {
  newFileData: INewFileData;
}

export const setNewFileData = (newFileData: INewFileData): AnyAction => {

  const action: IReduxAction = {
    type: EReduxTypes.SET_NEW_FILE_DATA,
    data: { newFileData } as ISetNewFileData,
  };

  return action;
};

interface ISetFileNameData {
  fileName: string;
}

export const setFileName = (fileName: string | null): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_FILE_NAME,
    data: { fileName } as ISetFileNameData,
  };

  return action;
};


interface ISetMobileMenuOpenData {
  isMobileMenuOpen: boolean;
}

export const setMobileMenuOpen = (isMobileMenuOpen: boolean): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_MOBILE_MENU_OPEN,
    data: { isMobileMenuOpen } as ISetMobileMenuOpenData,
  };

  return action;
};

interface ISetFreeLimitReachedData {
  freeLimitReached: boolean;
}

export const setFreeLimitReached = (freeLimitReached: boolean): AnyAction => {
  const action: IReduxAction = {
    type: EReduxTypes.SET_FREE_LIMIT_REACHED,
    data: { freeLimitReached } as ISetFreeLimitReachedData,
  };

  return action;
};
