const osMap: Record<string, string> = {
  'win': 'Windows',
  'mac': 'macOS',
  'linux': 'Linux',
  'android': 'Android',
  'iphone': 'iOS',
  'ipad': 'iOS',
  'ipod': 'iOS',
};
  
export const getUserPlatform = (): string => {
  const platform = navigator.platform;
  if (!platform) {
    return 'Unknown';
  }

  const foundKey = Object.keys(osMap).find((key) => platform.toLocaleLowerCase().includes(key));
  return foundKey ? osMap[foundKey] : 'Unknown';
}
