import React from 'react';

import { toast } from 'react-toastify';

const showToast = (
  type: string,
  message: string | React.ReactNode,
  autoClose: number = 5000,
  id: string
) => {
  switch (type) {
    case 'success':
      if (!toast.isActive(id)) {
        toast.success(message, {
          position: 'top-center',
          autoClose: autoClose,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: id,
        });
      }
      break;
    case 'error':
      if (!toast.isActive(id)) {
        toast.error(message, {
          position: 'top-center',
          autoClose: autoClose,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: id,
        });
      }
      break;
    case 'info':
      if (!toast.isActive(id)) {
        toast.info(message, {
          position: 'top-center',
          autoClose: autoClose,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: id,
          //@ts-ignore
          className: 'bg-[#5F30E2] text-white',
        });
      }
      break;
    default:
      toast.info(message, {
        position: 'top-center',
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
  }
};

export default showToast;
