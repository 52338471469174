import { useEffect } from 'react';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentFileUrl } from 'data/actions/aiSummarizer';
import { getDocumentById } from 'data/actions/documents';
import { currentFileUrlSelector, fileIdSelector } from 'data/selectors/aiSummarizer';
import { getLoadingSelector } from 'data/selectors/loading';

import { Logger } from 'utils/logger';

import { ApiFile } from 'ts/types/file';

import { Loader } from '../ui/loader';

export const PDFViewer = () => {
  const dispatch = useDispatch();
  const currentFileUrl = useSelector(currentFileUrlSelector);
  const loadingFileUpload = useSelector(getLoadingSelector('fileUpload'));

  const fileId = useSelector(fileIdSelector);

  const { t } = useTranslation();
  const [showMissingFileMessage, setShowMissingFileMessage] = useState(false);

  const downloadFileLogger = new Logger('PDFViewer');

  const downloadFile = async (id: ApiFile['id']) => {
    downloadFileLogger.log('downloadFileId', { id });
    downloadFileLogger.log('current file id', { id });

    const res = await dispatch(getDocumentById(id));

    dispatch(setCurrentFileUrl(res.payload.url));
  };

  useEffect(() => {
    if (fileId) {
      downloadFile(fileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (currentFileUrl === 'missing-file-id') {
      timer = setTimeout(() => {
        setShowMissingFileMessage(true);
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [currentFileUrl]);

  const renderLoadingSpinner = () => {
    return <Loader />;
  };

  const renderMissingFileMessage = () => {
    return (
      <div className="flex items-center justify-center h-full bg-gray-50">
        <div className="p-8 rounded-lg bg-gray-100 shadow-sm max-w-lg">
          <p className="text-lg text-center">{t('ai_summarizer.messages.missing_file_message')}</p>
        </div>
      </div>
    );
  };

  const renderPdfViewer = () => (
    <iframe src={currentFileUrl || undefined} title="Default Viewer" width="100%" height="100%" />
  );

  const renderContent = () => {
    if ((!currentFileUrl && currentFileUrl !== 'missing-file-id') || loadingFileUpload) {
      return renderLoadingSpinner();
    }

    if (currentFileUrl === 'missing-file-id') {
      return showMissingFileMessage ? renderMissingFileMessage() : renderLoadingSpinner();
    }

    return renderPdfViewer();
  };

  return <div className="flex-1 h-full hidden small-desktop:block">{renderContent()}</div>;
};
