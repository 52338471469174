import { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { Loader } from 'components/ai-summarizer/ui/loader';
import { AnalyzingLoader } from 'components/ai-summarizer/ui/loader/analyzing-loader';
import { FreeLimitReached } from 'components/modals/aiSummarizer/freeLimitReachedModal';

import { currentThreadDataSelector, currentThreadIdSelector } from 'data/selectors/aiSummarizer';
import { getLoadingSelector } from 'data/selectors/loading';
import { userDataSelector } from 'data/selectors/user';

import { AIResponse } from './ai-response';
import { NoMessage } from './no-message';
import { UserResponse } from './user-response';

export const Convertsation = () => {
  const currentThreadData = useSelector(currentThreadDataSelector);
  const currentThreadId = useSelector(currentThreadIdSelector);
  const loadingThreadHistory = useSelector(getLoadingSelector('threadHistory'));
  const loadingCurrentThread = useSelector(getLoadingSelector('currentThread'));
  const loadingFileUpload = useSelector(getLoadingSelector('fileUpload'));

  const loadingNewMessageInThread = useSelector(getLoadingSelector('newMessageInThread'));
  const user = useSelector(userDataSelector);
  const containerRef = useRef<HTMLDivElement>(null);
  const justLoadedThreadHistory = useRef(false);

  const { t } = useTranslation();

  const showFreeLimitError =
    !user?.subscription &&
    (currentThreadData?.filter((msg) => msg.role === 'user').length || 0) >= 4 &&
    !loadingNewMessageInThread[currentThreadId as string];

  useEffect(() => {
    const fetchThreadData = async () => {
      if (currentThreadId) {
        try {
          const sortedMessages = currentThreadData?.sort(
            (a: any, b: any) => a.created_at - b.created_at
          );

          if (loadingThreadHistory && showFreeLimitError) {
            justLoadedThreadHistory.current = true;
          }

          if (loadingNewMessageInThread[currentThreadId as string]) {
            justLoadedThreadHistory.current = false;
          }

          if (containerRef.current && sortedMessages?.length > 0) {
            // eslint-disable-next-line no-lone-blocks
            {
              const lastElement = containerRef.current.lastElementChild;
              lastElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          }
        } catch (error) {
          console.error('Error fetching thread data:', error);
        }
      }
    };

    fetchThreadData();
  }, [
    currentThreadId,
    currentThreadData,
    showFreeLimitError,
    loadingThreadHistory,
    loadingNewMessageInThread,
  ]);

  return (
    <div
      className={classNames(
        'flex flex-col gap-[20px] sticky top-0 h-full overflow-hidden px-6 pr-0 small-desktop:px-0',
        {
          'h-[calc(100vh-100px)]':
            !loadingCurrentThread && !loadingFileUpload && loadingThreadHistory,
        }
      )}
      ref={containerRef}
    >
      {currentThreadData?.length > 0 &&
        currentThreadData
          ?.slice(1)
          ?.map((messageData) => {
            const { id, role, message, threadId } = messageData;

            let parsedMessage = {
              content: '',
              questions: [],
            };

            try {
              parsedMessage = JSON.parse(message.replace(/^```json|```$/g, ''));
            } catch (error) {
              console.error('Error parsing message:', error);
            }

            if (role === 'assistant' && threadId === currentThreadId) {
              return (
                <AIResponse
                  key={id}
                  message={parsedMessage?.content}
                  suggestedQuestions={parsedMessage?.questions}
                  isHistory={currentThreadData.length > 1}
                />
              );
            }

            if (role === 'user') {
              return <UserResponse message={message} key={id} />;
            }

            return <></>;
          })}

      {loadingCurrentThread && <AnalyzingLoader type={'new-file'} />}
      {loadingFileUpload && <AnalyzingLoader type={'file'} />}
      {!loadingCurrentThread && !loadingFileUpload && loadingThreadHistory && (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      )}

      {loadingNewMessageInThread[currentThreadId as string] && (
        <AnalyzingLoader type={'messages'} />
      )}
      {/* {state.error.messages && <ErrorMessageTemplate message={state.error.messages} />} */}
      {showFreeLimitError && <FreeLimitReached />}

      {currentThreadData.length <= 1 &&
        currentThreadId &&
        !loadingCurrentThread &&
        !loadingFileUpload &&
        !loadingThreadHistory &&
        !loadingNewMessageInThread[currentThreadId as string] && (
          <NoMessage message={t('ai_summarizer.messages.no_message')} />
        )}

      {currentThreadData.filter((msg) => msg.role === 'assistant').slice(-1)[0]?.message === '' &&
        currentThreadId &&
        !loadingCurrentThread &&
        !loadingFileUpload &&
        !loadingThreadHistory &&
        !loadingNewMessageInThread[currentThreadId as string] && (
          <NoMessage message={t('ai_summarizer.messages.no_message')} />
        )}
    </div>
  );
};
