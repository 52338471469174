export const getFileFormatFromFileList = (
  filelist: FileList | null
): string => {
  try {
    if (!filelist || filelist.length <= 0) {
      return `empty file list`
    }

    const formatMatch = filelist[0].name.match(/\.[0-9a-z]+$/i)

    if (formatMatch) {
      return formatMatch[0]
    }

    return `unknown`
  } catch (e) {
    return `unknown`
  }
}
