import { useState, useEffect, useMemo } from 'react';
import {
  sourceDefaultLang,
  outputDefaultLang,
} from '../components/modals/choseTranslateFormatModal/locales';
import { Language, languages } from '../components/modals/choseTranslateFormatModal/localesFull';


export const useLanguageSelection = () => {
  const [sourceLang, setSourceLang] = useState<Language>(() => {
    const savedLang = localStorage.getItem('detectedLangForTranslate');

    if (savedLang) {
      const foundLang = sourceDefaultLang.find((lang) => lang.francName === savedLang);
      if (foundLang) return foundLang;

      const foundInAllLangs = languages.find((lang) => lang.francName === savedLang);
      if (foundInAllLangs) return foundInAllLangs;
    }

    return sourceDefaultLang[0];
  });

  const [outputLang, setOutputLang] = useState<Language>(() => {
    const savedLang = localStorage.getItem('detectedLangForTranslate');

    if (savedLang && savedLang !== 'eng' && savedLang !== 'und') {
      const englishLang = outputDefaultLang.find((lang) => lang.code === 'en');
      if (englishLang) return englishLang;

      const foundInAllLangs = languages.find((lang) => lang.code === 'en');
      if (foundInAllLangs) return foundInAllLangs;
    }

    return outputDefaultLang[0];
  });

  const sortedSourceDefaultLang = useMemo(() => {
    const savedLang = localStorage.getItem('detectedLangForTranslate');

    if (savedLang) {
      const foundLang = sourceDefaultLang.find((lang) => lang.francName === savedLang);
      if (foundLang) {
        const otherLangs = sourceDefaultLang.filter((lang) => lang.francName !== savedLang);
        return [foundLang, ...otherLangs];
      }
    }

    return sourceDefaultLang;
  }, []);

  const sortedOutputDefaultLang = useMemo(() => {
    const savedLang = localStorage.getItem('detectedLangForTranslate');

    if (savedLang && savedLang !== 'eng' && savedLang !== 'und') {
      const englishLang = outputDefaultLang.find((lang) => lang.code === 'en');
      if (englishLang) {
        const otherLangs = outputDefaultLang.filter((lang) => lang.code !== 'en');
        return [englishLang, ...otherLangs];
      }
    }

    return outputDefaultLang;
  }, []);

  useEffect(() => {
    localStorage.setItem('outputLang', outputLang.code);
    localStorage.setItem('sourceLang', sourceLang.code);
  }, [outputLang, sourceLang]);

  return {
    sourceLang,
    setSourceLang,
    outputLang,
    setOutputLang,
    sortedSourceDefaultLang,
    sortedOutputDefaultLang,
  };
};
