import bgImgMob from 'assets/img/forms/background-1040-x-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-1040-x-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';
import blue_number_icon_7 from 'assets/img/forms/7-step-blue-icon.svg';
import blue_number_icon_8 from 'assets/img/forms/8-step-blue-icon.svg';


import form1040x from 'assets/forms/1040-x.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form1040xService = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-1040-x',
    filePath: form1040x,
    fileName: '1040-x.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_1040x.title'),
      description: t('meta.form_1040x.description'),
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '1040-X',
      subHeadline: '2020-2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline: 'How it works',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Start with PDF Guru',
          text: 'Access our easy-to-use editor and open the blank Form 1040-X, ready for your input.',
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Enter your info',
          text: 'Carefully fill in the necessary amendments to your original tax return details.',
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Review and download',
          text: 'Ensure accuracy in your entries, download the form, and get it ready for filing.',
        },
      ],
    },
    whatIsForm: {
      headline: 'What is a 1040-X Form?',
      text_1: [],
      text_2: [
        "One of the most common reasons for filing Form 1040-X is discovering errors or omissions in your original tax return. These could range from simple mistakes in entering income or deductions to more significant issues like failing to claim a tax credit you're entitled to. For instance, if you missed a significant deduction or tax credit, amending your tax return can lead to a refund.",
        'Another scenario is changes in your tax situation, like an adjustment in your filing status or dependents. For example, if you initially filed as single but were actually eligible to file as head of household, amending your return could lead to tax benefits.',
      ],
      text_3: [
        'Getting your hands on a 1040-X form is easy with PDF Guru as we have a blank, editable version of the form available on our platform. All you need to do is click "Get Form," and you\'re ready to start making your amendments.',
      ],
      text_4: [],
      title_1: 'What is a common reason for filing a 1040-X form?',
      title_2: 'How do I get a 1040-X form?',
      title_3: '',
      subTitle: '',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        'The Form 1040-X is known as the Amended U.S. Individual Income Tax Return. It\'s designed for taxpayers who need to correct or modify information on their previously filed standard tax returns. This form plays a crucial role in ensuring that your tax records reflect accurate information, which is essential for both legal compliance and personal financial health.',
        'Filing a 1040-X form can be intimidating, especially for those not well-versed in tax matters. However, it\'s a vital process for correcting any errors in your previously filed tax returns. This might include changes in your income, federal tax credits, deductions, or filing status in your original return. The form allows you to provide updated information and explain why the amendments are necessary.'
      ],      
      bullets: [], 
      orangeList: [],           
    },
    howToFill: {
      headline: 'How to fill out a 1040-X form',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Access PDF Guru:',
          text: ' Visit our platform and open the blank Form 1040-X.',
        },
        {
          icon: blue_number_icon_2,
          strong: 'Specify tax year:',
          text: ' Indicate the tax year you\'re amending at the top of the form.',
        },
        {
          icon: blue_number_icon_3,
          strong: 'Enter personal information:',
          text: ' Add your name, social security number, address, and filing status.',
        },
        {
          icon: blue_number_icon_4,
          strong: 'Amend figures:',
          text: ' Correct your income, deductions, credits, and tax figures in the designated columns.',
        },
        {
          icon: blue_number_icon_5,
          strong: 'Explain changes:',
          text: ' On the back of the form, briefly explain each amendment.',
        },
        {
          icon: blue_number_icon_6,
          strong: 'Double-check entries:',
          text: ' Review all information for accuracy.',
        },
        {
          icon: blue_number_icon_7,
          strong: 'Download and print:',
          text: ' Download and print the completed form.',
        },
        {
          icon: blue_number_icon_8,
          strong: 'Sign and date:',
          text: ' Finalize the form with your signature and date.',
        },
      ],
    },
    blockWithBlueTitle2: {
      title: 'Submitting the amended tax return',
      description: [
        "Submitting the amended tax return is the final step in correcting your tax records. Once you've completed your Form 1040-X with PDF Guru and double-checked it, it's crucial to submit it correctly to ensure your amendments are processed by the IRS. Here's how you can do it.",
      ],
      orangeList: [
        {
          strong: 'Where to mail 1040-X',
          isColumn: true,
          text: 'After filling out Form 1040-X, mail it to the IRS center for your region. The specific address can be found on the IRS website. Ensure you use the correct address to avoid delays in processing.',
        },
        {
          strong: 'How to file 1040-X electronically',
          isColumn: true,
          text: 'Currently, the IRS allows electronic filing of Form 1040-X. This can be done through various IRS-authorized e-file providers. Remember, you\'ll still need to print and mail your form if it includes attachments not supported by e-filing.',
        },
      ],
    },
    differenceBetween1: {
      headline: 'What to include with 1040-X',
      text: "When you submit Form 1040-X, it's crucial to include any supporting documents that relate to your amendments. This can include W-2s, 1099s, or other forms that substantiate your income adjustments. If you're claiming additional deductions or credits, attach relevant documents like receipts or financial statements.",
    },
    fillFormFree: {
      headline: 'Complete Form 1040-X with Ease in No Time',
      subtitle: 'Your gateway to accurate tax amendments',
      buttonLabel: 'Fill Form',
    },
    bannerQuickNEasy: {
      headline: 'Update Your Form 1040-X',
      subtitle: 'Correct your tax return the right way',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'Where is my 1040-X refund?',
          answer: [
            'Tracking your 1040-X refund involves waiting for the IRS to process your amended return, which can take up to 16 weeks. You can check the status of your amended return using the IRS "Where\'s My Amended Return?" tool, available online or by phone.',
          ],
        },
        {
          question: 'How do I check my 1040-X status?',
          answer: [
            'To check the status of your 1040-X, use the IRS\'s "Where\'s My Amended Return?" service. This online tool provides the current status of your amended return, typically available three weeks after filing.',
          ],
        },
        {
          question: 'How do I know if I filed a 1040-X?',
          answer: [
            'If you\'re unsure whether you filed a 1040-X, you can confirm this by checking your records or contacting the IRS directly. Additionally, reviewing past tax documents can help determine if an amended return was filed.',
          ],
        },
        {
          question: 'How long does 1040-X take to process?',
          answer: [
            'The processing time for a Form 1040-X can vary, but it generally takes the IRS about 16 weeks to process an amended return. During peak tax seasons, this time frame might be longer.',
          ],
        },
      ],
    },               
    fag: {
      question: 'How to fill out Form 1065?',
      answer:
        "1. Access PDF Guru: Visit our platform and open the blank Form 1040-X. 2. Specify tax year: Indicate the tax year you're amending at the top of the form. 3. Enter personal information: Add your name, social security number, address, and filing status. 4. Amend figures: Correct your income, deductions, credits, and tax figures in the designated columns. 5. Explain changes: On the back of the form, briefly explain each amendment. 6. Double-check entries: Review all information for accuracy. 7. Download and print: Download and print the completed form. 8. Sign and date: Finalize the form with your signature and date.",
    },
  };
}