import React from 'react'

interface IProps {}
export const ArrowUpIcon: React.FC<IProps> = () => {
    return (
      <svg width="24" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7138 7.28629C12.9148 7.28629 13.1187 7.20811 13.2711 7.05568C13.5763 6.75051 13.5763 6.2459 13.2711 5.94074L7.56074 0.230349C7.41335 0.0829615 7.20542 -0.000264241 7.00342 -0.000264259C6.80331 -0.000264276 6.60387 0.0725721 6.44609 0.230348L0.743513 5.93341C0.424047 6.23409 0.419946 6.75061 0.725114 7.05577C1.03028 7.36094 1.53489 7.36094 1.84005 7.05577L1.84024 7.05558L6.99321 1.89236L12.1567 7.05583C12.3094 7.20855 12.5133 7.28644 12.714 7.28644L12.7138 7.28629Z" fill="currentColor"/>
      </svg>
    )
}
