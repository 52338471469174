import classNames from 'classnames';
import { FC, ReactElement, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import cross from 'assets/img/icons/32px/cross-grey.svg';
import { toggleModal } from 'data/actions/modals';
import { modalTypeSelector } from 'data/selectors/modals';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { EModalTypes } from 'ts/enums/modal.types';
import { EServiceType } from 'ts/interfaces/services/service';
import s from './popup-wrapper.module.scss';

export interface IProps {
  wrapperRef?: RefObject<HTMLDivElement>;
  popupType?: EModalTypes;
  closePopup?: unknown;
  canClose?: boolean;
  children: ReactElement;
  dataTestId?: string;
  closeOnBackdrop?: boolean;
  overflowVisible?: boolean;
}

const ModalLayout: FC<IProps> = ({
  children,
  canClose,
  wrapperRef,
  closePopup,
  dataTestId,
  closeOnBackdrop,
  overflowVisible = false,
}) => {
  const { t } = useTranslation();
  const modalType = useSelector(modalTypeSelector);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryParams = searchParams.get('to');

  const popupType: any = 'ddfdsf';

  const handleClosePopup = () => {
    dispatch(toggleModal({ visible: false }));
    if (typeof closePopup === 'function') {
      closePopup();
    }
  };

  const renderCloseIcon = () => {
    if (!canClose) {
      return null;
    }

    // if (popupType === 'sign-up-popup') {
    //   return (
    //     <img
    //       className={s.closeIcon}
    //       src="/app/assets/img/icons/32px/cross-grey.svg"
    //       alt="Close icon"
    //       onClick={handleClosePopup}
    //       aria-hidden
    //       width={16}
    //       height={16}
    //     />
    //   );
    // }

    return (
      <img
        className={classNames(s.closeIcon, {
          '!brightness-100':
            popupType === 'convert-from-editor' || popupType === 'convertion-failed',
        })}
        src={cross}
        alt="Close icon"
        onClick={handleClosePopup}
        aria-hidden
        data-testid="close-modal-button"
      />
    );
  };

  return (
    <>
      <div
        className={classNames(s.popupWrapper, {
          '-top-7 tablet:top-0': popupType === 'convert-from-editor',
          '!z-[9999]':
            popupType === 'sign-up-popup' || modalType === EModalTypes.ENTER_EMAIL_ADDRESS,
        })}
        ref={wrapperRef}
        aria-hidden
        data-testid={dataTestId}
        onClick={closeOnBackdrop ? handleClosePopup : undefined}
      >
        <div
          className={classNames(s.innerWrapper, {
            '!overflow-visible !rounded-none':
              popupType === 'thanks-purchase' || popupType === 'thanks-purchase-annual',
            'relative max-h-[97vh]': popupType === 'convert-from-editor',
            '!overflow-visible': overflowVisible,
          })}
          onClick={closeOnBackdrop ? (e) => e.stopPropagation() : undefined}
        >
          {children}
          {queryParams === EServiceType.TRANSLATE ? null : renderCloseIcon()}
          {(popupType === 'thanks-purchase' || popupType === 'thanks-purchase-annual') && (
            <div className="tablet:w-full text-center text-[#FFFFFF] text-sm font-light absolute tablet:-bottom-5 tablet:translate-y-[100%] flex justify-center">
              <div className="mt-2 tablet:mt-0">
                {t('popup_wrapper.thanks_purchase.description1')}
                <br />
                {t('popup_wrapper.thanks_purchase.description2')}

                <br />
                <a href="mailto:support@pdfguru" className="underlined text-[#B2A0E3]">
                  support@pdfguru
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalLayout;
