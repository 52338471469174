const providers: Record<string, string> = {
  'gmail.com': 'Google',
  'yahoo.com': 'Yahoo',
  'outlook.com': 'Outlook',
  'hotmail.com': 'Hotmail',
  'icloud.com': 'iCloud',
  'aol.com': 'AOL',
  'protonmail.com': 'ProtonMail',
  'zoho.com': 'Zoho Mail',
  'gmx.com': 'GMX',
  'fastmail.com': 'FastMail',
};

export const getEmailHost = (email: string): string => {
  const domain = email.split('@').pop()?.toLowerCase();
  return domain && providers[domain] ? providers[domain] : 'Other';
}
