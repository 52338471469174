import { userCountryCodeSelector, userDataSelector } from 'data/selectors/user';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getEmailHost } from 'utils/getEmailHost';
import { getUserPlatform } from 'utils/getUserOs';
import { useSessionTimer } from 'utils/sessionTimer';
import { getMarketingValues } from 'utils/storeMarketingValues';

interface ICustomerAttributes {
  emailHost?: string;
  platform?: string;
  email?: string;
  page?: string;
  lastSession?: string;
  feature?: string;
  keyword?: string;
  id_partner?: string;
  marketing_url?: string;
  country?: string;
}

export const useCustomerIOAttributes = (): void => {
  const user = useSelector(userDataSelector);
  const userCountry = useSelector(userCountryCodeSelector);

  useSessionTimer();

  useEffect(() => {
    if (user?.id) {
      const attributes: ICustomerAttributes = {};
  
      // Set customerIo user attributes
      if (user.email) {
        attributes.emailHost = getEmailHost(user.email);
        attributes.platform = getUserPlatform();
        attributes.email = user.email;
        attributes.page = window.location.pathname;
        attributes.country = userCountry || '';

        const marketing = getMarketingValues();
        if (marketing) {
          attributes.feature = marketing.feature;
          attributes.keyword = marketing.keyword;
          attributes.id_partner = marketing.id_partner;
        }

        window.analytics.identify(user.id, attributes);
      }
    }
  }, [user, userCountry]);

}
