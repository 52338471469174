import bgImgMob from 'assets/img/forms/background-1099-nec-mob.webp';
//import bgImgTab from 'assets/img/forms/background-1099-nec-tab.webp';
import bgImgWeb from 'assets/img/forms/background-1099-nec-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';

import form1099Nec from 'assets/forms/1099-nec.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form1099NecService = (t: any): IFormService => {
  return {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-1099-nec',
      filePath: form1099Nec,
      fileName: '1099-nec.pdf',
      from: 'PDF',
      to: 'PDF',
      meta: {
        title: t('meta.form_1099nec.title'),
        description: t('meta.form_1099nec.description'),
      },
      getForm: {
        headline1: 'IRS',
        headline2: 'Form',
        formName: '1099-NEC',
        subHeadline: '2024-2025 Printable Template',
        backgroundImageWeb: bgImgWeb,
        backgroundImageTab: bgImgWeb,
        backgroundImageMob: bgImgMob,
        buttonLabel: 'GET FORM',
      },
      easilyComplete: {
        headline: 'Filling your 1099 NEC Form is easy with PDF Guru',
        description: [
          {
            icon: icon_1,
            iconMob: icon_mob_1,
            title: 'Fill out the 1099-NEC form',
            text: 'Access our 1099 NEC Form 2023 and provide the required information, such as your name, contact details, etc.',
          },
          {
            icon: icon_2,
            iconMob: icon_mob_2,
            title: 'Save or print out the form',
            text: 'Download your printable 1099 NEC Form 2023 or simply save the filled-out form as a digital file.',
          },
          {
            icon: icon_3,
            iconMob: icon_mob_3,
            title: 'Submit your tax return',
            text: 'Send your completed form to the IRS electronically or present it to them in person.',
          },
        ],
      },
      whatIsForm: {
        headline: 'What is Form 1099-NEC?',
        text_1: [],
        text_2: [],
        text_3: [],
        text_4: [],
        title_1: 'What is a 1099-NEC tax form used for?',
        title_2: '',
        subTitle:
          'The 1099-NEC Form is used to report non-employee compensation. These could be payments made to freelancers, independent contractors, self-employed people, or sole proprietors. As per the IRS, any payments meeting the following four points must be reported in the 1099 NEC Form 2023:',
        //@ts-ignore
        sectionImage: section_img,
        //@ts-ignore
        sectionImageTab: section_img_tab,
        description: [
          `It’s likely that many people need clarification about the 1099 NEC Form meaning and usage. This is a tax return form aimed at businesses that engage in non-employee compensation to individuals who earned more than $600 during the year. Originally, this form was used until 1982, when it stopped being required. After that year, businesses would instead use the 1099-MISC Form to report payments to non-employees. However, the IRS reintroduced tax form 1099-NEC in 2020 to separate non-employee compensation payments from miscellaneous income reported on the 1099-MISC form.`
        ],
        bullets: [],
        orangeListSmall: [
          'You paid someone who is not employed by your business',
          'The payment was made for a service falling within the course of your business',
          'The receiver is either an individual, an estate, a partnership, or a corporation',
          'The total annual amount of the payment is at least $600',
        ],
        title_list:
        'The compensation to a non-employee can take the following forms:',
        redList: [
          { text: 'Monetary fees' },
          { text: 'Benefits' },
          { text: 'Prizes' },
          { text: 'Commissions' },
          { text: 'Any other form of compensation' },
        ],
      },
      differenceBetween1: {
        headline: 'Who must file a 1099-NEC?',
        text: 'Form 1099 NEC 2023 must be filed by any business that pays non-employees at least $600 in a year for services rendered by them. Additionally, filing the form becomes necessary when you withhold federal income tax from the payment of someone who isn’t your employee. If you’re liable to fill out the 1099 NEC Form and you fail to do so, you may incur heavy penalties. So, it’s crucial that you report your non-employee payments using our easy, online filler complete with all relevant Form 1099-NEC instructions.',
      },
      howToFill: {
        headline: 'How to file Form 1099-NEC?',
        //@ts-ignore
        sectionImage: howToFillImage,
        bullets: [
          {
            icon: blue_number_icon_1,
            strong: "",
            text: 'Click on the Get Form button',
          },
          {
            icon: blue_number_icon_2,
            strong: "",
            text: 'Fill out the complete form, providing all the required data, such as your name, address, TIN, etc',
          },
          {
            icon: blue_number_icon_3,
            strong: "",
            text: 'Review all the information you’ve given',
          },
          {
            icon: blue_number_icon_4,
            strong: "",
            text: 'Download the completed, printable 1099 NEC Form 2023',
          },
        ],
      },
      differenceBetween2: {
        headline: 'What is the deadline for 1099-NEC?',
        text: 'Form 1099-NEC needs to be filed before January 31, just like most other tax forms. Failure to submit it by the deadline may lead to a penalty. Luckily, filling out the form is quick and easy with our tool. Once you’ve filled it out, there are two main ways to present the data. You may use the IRS Taxpayer Portal and simply upload the digital version of your form. Alternatively, you could always print out your 1099-NEC Form 2023 and mail it to the IRS processing center near you.',
      },
      fillFormFree: {
        headline: 'Complete Your 1099 NEC Form Hassle-Free',
        subtitle: '',
        buttonLabel: 'Fill Form',
      },
      faqSection: {
        headline: 'Frequently asked questions',
        faqSectionDescription: [
          {
            question: 'I received a 1099-NEC. What do I do with it?',
            answer: [
              `As a professional, receiving the form gives you a way to report your income correctly. Be thorough when checking the form filled out by your payer. Make sure all the information, like your name, contact details, TIN, etc, is correct. After verifying everything, you need to report your income and pay any relevant taxes.`
            ]
          },
          {
            question: 'When is Form 1099-NEC due?',
            answer: [
              `Form 1099 NEC is due before the 31st of January each year. It must be completed well in time using our quick and easy form filler, so you may avoid any penalties due to late submission.`
            ]
          },
          {
            question: 'Where do you send 1099-NEC Forms?',
            answer: [
              `To make the taxation process easier, the IRS has created a Taxpayer Portal where tax forms can be uploaded digitally. The tax form 1099-NEC is no different. If you’re more comfortable sending a printed copy, you may physically send it to any IRS processing center near your area.`
            ]
          },
          {
            question: 'How much taxes do you pay on a 1099-NEC?',
            answer: [
              `The combined tax rate to be paid is 15.3%. This includes a 12.4% Social Security tax and a 2.9% Medicare tax. This amount is paid by the employer, although in some cases, the employer and the employee may split the tax.`
            ]
          },
          {
            question: 'What is the difference between 1099-MISC and 1099-NEC?',
            answer: [
              `While both these forms are used for reporting payments made to non-employees, there is a key difference between them. 1099-NEC is applicable in cases where payments are subject to self-employment taxes. Alternatively, 1099-MISC is reserved for payments that aren’t subject to any self-employment tax.`
            ]
          }
        ],
      },
      fag: {
        question: 'How to file Form 1099-NEC?',
        answer:
          '1. Click on the Get Form button. 2. Fill out the complete form, providing all the required data, such as your name, address, TIN, etc. 3. Review all the information you’ve given. 4.Download the completed, printable 1099 NEC Form 2023',
      },
      bannerQuickNEasy: {
        headline: 'The Easiest Way to Complete the 1099 NEC Form',
        subtitle: 'File your tax returns without the stress!',
        format: InternalFileType.PDF,
        buttonLabel: 'Fill Form',
      },
  };
};
