import { FC } from 'react';

const FullScreenLoader: FC = () => {
  return (
    <div className="w-full h-screen z-[50000] fixed flex items-center justify-center flex-col bg-white">
      Loading...
    </div>
  );
};

export default FullScreenLoader;
