import { useTranslation } from 'react-i18next';

export const OngoingLoading = ({ className }: { className: string }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`small-desktop:block hidden absolute -top-[10%] -left-[10%] bg-gray-800 text-white px-3 py-1 rounded text-sm whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200 ${className}`}
    >
      {t('ai_summarizer.messages.pending_loading')}
    </div>
  );
};
