import { FC } from "react"

export const Description: FC = () => {
  return(
    <div className="flex flex-col gap-y-4">
      <p>
        <strong>
          Form 433-D, also known as the Installment Agreement, is for
          taxpayers in the U.S. who need to set up a payment plan for their
          taxes.
        </strong>{' '}
        It's a detailed form where you share your financial situation with
        the IRS. This form helps you manage your tax liabilities by letting
        you negotiate a payment plan that suits your financial situation,
        avoiding penalties or legal issues.
      </p>
    </div>
  )
}