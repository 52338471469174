export enum GrowthBookTestType {
  CHECKBOX_TEST = 'ab-new_checkbox_2_4_3',
  CHECKBOX_TEST_V3 = 'abcd-v3_new_checkbox_2_4_6',
  NEW_LANDING_TEST_2_4_1 = 'ab-landing_support_2_4_1_v2',
  ABCDE_NEW_SALES_2_4_2 = 'abcdefgh-new_sales_2_4_2',
  AB_APPLE_PAY_CHROME_2_4_2 = 'ab-apple_pay_for_chrome_2_4_2_skeleton',
  AB_GERMANY_FREE_TRIAL = 'ab-germany-free-trial',
  AB_MOBILE_SALES_2_4_4 = 'ab-mobile_payment_update_2_4_4',
  ABCD_IMPORT_EXPORT_EDITOR_2_4_4 = 'abcd-import_export_editor_2_4_4',
  ABC_NEW_CHECKBOX_TEST_2_4_5 = 'ab-v2_new_checkbox_2_4_5',
  ABC_NEW_EMAIL_MODAL_2_4_5 = 'abc-new_email_modal_2_4_5',
  AB_MOBILE_PAYMENT_2_4_4 = 'ab-mobile_sales_update_2_4_4_v2',
  ABC_OWN_CONVERSION_2_9_9 = 'abc-own_conversion_2_9_9',
}

export enum TestVariants {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
}

export type ExtractEnumKeys<T extends string> = T extends `${infer Prefix}-${string}`
  ? PrefixToTestVariant<Uppercase<Prefix>>
  : T extends `${infer Prefix}_${string}`
    ? PrefixToTestVariant<Uppercase<Prefix>>
    : never;

// Convert extracted letters into corresponding TestVariants keys
type PrefixToTestVariant<T extends string, Acc = never> = T extends `${infer First}${infer Rest}`
  ? First extends keyof typeof TestVariants
    ? PrefixToTestVariant<Rest, Acc | (typeof TestVariants)[First]> // Map to TestVariants keys
    : PrefixToTestVariant<Rest, Acc>
  : Acc;

// Generic resolver
export type ResultTestVariants<T extends string> = ExtractEnumKeys<T>;
