import { removeCurrentThreadData, setCurrentThreadId, setFileId } from 'data/actions/aiSummarizer';
import { triggerLoadingAction } from 'data/actions/loading';
import { Analytics } from 'services/analytics';
import { authorizedEditFileError } from 'services/global-events';

export const uploadFileToBucket = async (
  uploadFile: (file: File) => Promise<any>,
  file: File,
  dispatch?: any
) => {
  let filename = file.name;

  try {
    dispatch(triggerLoadingAction(['uploadFile'], true));
    dispatch(setCurrentThreadId(null));
    dispatch(removeCurrentThreadData());
    
    const response = await uploadFile(file);

    if (response && typeof response === 'object' && 'uploadLink' in response.fileData) {

      dispatch(setFileId(response.fileData.fileId));
    }

    void Analytics.sendEvent({
      event: 'upload_file_to_bucket',
      data: {
        status: 'success',
        filename,
      },
    });

    void Analytics.sendEvent({
      event: 'file_uploaded',
      data: {
        filename,
      },
    });

    dispatch(triggerLoadingAction(['uploadFile'], false));

    return { fileUrl: response.fileData.uploadLink, fileId: response.fileData.fileId };
  } catch (err) {
    void Analytics.sendEvent({
      event: 'upload_file_to_bucket',
      data: {
        status: 'fail',
        filename,
        err: JSON.stringify(err),
      },
    });

    window.dispatchEvent(
      authorizedEditFileError((err as any).response?.data?.message || 'Unknown error')
    );
    throw err;
  }
};
