import { useEffect } from 'react';

// /**
//  * The useCustomerIO function is a custom React hook that initializes and loads the Customer.io
//  * analytics script.
//  */
const useCustomerIO = () => {
  useEffect(() => {
    var analytics = (window.analytics = window.analytics || []);
    if (analytics.initialize) return;

    if (analytics.invoked) {
      return window.console && console.error && console.error('Snippet included twice.');
    }

    analytics.invoked = true;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
    ];
    analytics.factory = function (e) {
      return function () {
        var t = Array.prototype.slice.call(arguments);
        t.unshift(e);
        analytics.push(t);
        return analytics;
      };
    };
    for (var e = 0; e < analytics.methods.length; e++) {
      var key = analytics.methods[e];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function (key, e) {
      var t = document.createElement('script');
      t.type = 'text/javascript';
      t.async = true;
      t.src = `https://cdp.customer.io/v1/analytics-js/snippet/${key}/analytics.min.js`;
      var n = document.getElementsByTagName('script')[0];

      if (n.parentNode) n.parentNode.insertBefore(t, n);
      analytics._writeKey = key;
      analytics._loadOptions = e;
    };
    analytics.SNIPPET_VERSION = '4.15.3';
    analytics.load(process.env.REACT_APP_CUSTOMER_IO_KEY as string);
    analytics.page();
  }, []);
};

export default useCustomerIO;
