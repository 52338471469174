import classNames from 'classnames';
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

/** Utils */
import { getFileFormatFromFileList } from 'utils/getFileFormatFromFileList';
import { Logger } from 'utils/logger';
import { validateSelectedFile } from 'utils/validation';

/** Helpers */
import { InternalFileType, InternalType } from 'ts/types/file';

/** Components */
import { DragNDrop } from 'components/drag-n-drop';
import UploadButtonMerge from 'components/uploadButtons/uploadButtonMerge';
import { HeaderUploadSection } from '../header-for-upload-section';
import { IUploadSectionInteractor } from './interactor';

/** Configuration */
import { config } from 'configuration';

/** Services */
import { Analytics } from 'services/analytics';

/** Assets */
import newPdfFilePath from 'assets/forms/create-new.pdf';
import cross from 'assets/img/icons/32px/error-format-cross.svg';
import clock from './assets/clock.svg';
import list from './assets/list.svg';
import shield from './assets/shield.svg';

/** Hooks */
import useHandleSelectFile from 'hooks/fileFlows/useHandleSelectFile';

/** Styles */
import { EServiceType } from 'ts/interfaces/services/service';
import s from './upload-section-new-design.module.scss';
/** Types */
import { PrimaryButton } from 'components/buttons/primary-button';
import UploadButtonSplit from 'components/uploadButtons/uploadButtonSplit';
import { sendAnalyticEvent } from 'data/actions/analytics';
import { toggleModal } from 'data/actions/modals';
import { getAbTestVariant } from 'data/selectors/abTests';
import { useDispatch, useSelector } from 'react-redux';
import { GrowthBookTestType, TestVariants } from 'ts/enums/growthbook';
import { EModalTypes } from 'ts/enums/modal.types';

export const FILE_TYPES_NOT_FOR_EDITOR_FLOW = [
  '.docx',
  '.doc',
  '.pptx',
  '.xls',
  '.xlsx',
  '.csv',
  '.txt',
  '.rtf',
  '.ods',
  '.odt',
  '.epub',
];
export interface IProps {
  interactor: IUploadSectionInteractor;
}
export const UploadSection: React.FC<any> = ({ service }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  const importExportEditorVariant = useSelector(
    getAbTestVariant(GrowthBookTestType.ABCD_IMPORT_EXPORT_EDITOR_2_4_4)
  );

  const { handleSelectFile } = useHandleSelectFile({ service });

  const handleUploadFile = (file: File) => {
    void Analytics.sendEvent({
      event: 'file_from_provider_chosen',
      data: {
        features_name: service?.path?.replace('/', '') || '',
        method: 'click',
      },
    });

    handleSelectFile(file);
  };

  const features = [
    {
      img: shield,
      text: t('upload_section.hero.benefit_1'),
    },
    {
      img: list,
      text: t('upload_section.hero.benefit_2'),
    },
    {
      img: clock,
      text: t('upload_section.hero.benefit_3'),
    },
  ];

  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const logger = useMemo(() => new Logger('UploadSection'), []);

  const handleCreateNewPDF = () => {
    dispatch(toggleModal({ type: EModalTypes.PROGRESS_EDIT_FILE, visible: true }));
    dispatch(
      sendAnalyticEvent({
        event: 'file_from_provider_chosen',
        data: {
          features_name: service?.path?.replace('/', '') || '',
          method: 'click',
        },
      })
    );

    const uploadForm = async () => {
      const response = await fetch(newPdfFilePath); // Fetch the file
      if (!response.ok) {
        throw new Error('Failed to fetch file');
      }
      const blob = await response.blob();
      handleUploadFile(
        new File([blob], 'create-new.pdf', {
          lastModified: new Date().getTime(),
          type: 'application/pdf',
        })
      );
    };
    uploadForm();
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (!inputFileRef.current) return;

      const validation = validateSelectedFile({
        files: e.target.files,
        type: service.from,
        translator: t,
      });

      if (!validation.valid && validation.error) {
        setError(validation.error);
        logger.error('validation_error', {
          files: JSON.stringify(e.target.files),
        });

        // reset input value
        inputFileRef.current.value = '';

        const eventData: Record<string, any> = {
          status: 'fail',
          place: 'main',
          errorCode: validation.code,
        };

        eventData.file_format = getFileFormatFromFileList(e.target.files);
        eventData.is_validation_error = true;

        void Analytics.sendEvent({
          event: 'file_upload_status',
          data: eventData,
        });
        return;
      }

      if (validation.valid && e.target.files && e.target.files.length > 0) {
        handleUploadFile(e?.target?.files[0]);
        setError(null);
        return;
      }
    },
    [service, logger, t] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const errorClasses = {
    [s.error]: true,
    [s.visible]: service.error !== null,
  };

  const onCloseErrorBannerClick = useCallback(() => {
    setError(null);
  }, [service]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let errorBannerTimeout: NodeJS.Timeout | undefined;
    if (error) {
      errorBannerTimeout = setTimeout(() => {
        setError(null);
      }, 10 * 1000);
    }
    return () => {
      if (errorBannerTimeout) {
        clearTimeout(errorBannerTimeout);
      }
    };
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateUploadFrom = () => {
    if (service.path === 'create-pdf') {
      return (
        <div className="flex mobile:flex-row flex-col justify-center items-center gap-x-[48px] w-full gap-y-6">
          <PrimaryButton
            onClick={handleCreateNewPDF}
            className="bg-[#D2294B] hover:bg-[#D2294B] w-full mobile:max-w-[286px] max-w-[311px] rounded-lg
                shadow-[2px_2px_0px_0px_#1D1D1D] mobile:py-5 py-[15px] cursor-pointer text-[#FFFFFF] text-center font-[700]"
          >
            {t('landing.create_pdf.create_button')}
          </PrimaryButton>

          <div
            className="bg-[#D2294B] w-full mobile:max-w-[286px] max-w-[311px] rounded-lg relative
                shadow-[2px_2px_0px_0px_#1D1D1D] mobile:py-5 py-[15px] cursor-pointer text-[#FFFFFF] text-center font-[700] outline-none"
          >
            <input
              type="file"
              onChange={onFileChange}
              ref={inputFileRef}
              accept={InternalType.toMimeType(InternalFileType.PDF)}
              size={config.api.maxFileSizeMb * 1024 * 1024}
              className="text-[transparent] !block absolute top-0 left-0 w-full h-full cursor-pointer opacity-0"
            />
            {t('landing.create_pdf.upload_button')}
          </div>
        </div>
      );
    }

    if (service.serviceType === EServiceType.MERGER) {
      return <UploadButtonMerge service={service} />;
    }

    if (
      [TestVariants.B, TestVariants.C, TestVariants.D].includes(importExportEditorVariant) &&
      service.serviceType === EServiceType.SPLITTER
    ) {
      return <UploadButtonSplit service={service} />;
    }

    return (
      <>
        <div className={s.dragNDrop}>
          <DragNDrop
            format={service.from}
            onSelectFile={handleUploadFile}
            color={service.mainColor}
            onClick={service.onUploadClick}
            setError={setError}
            service={service}
          />
        </div>
      </>
    );
  };

  return (
    <div className={s.uploadSection}>
      <div className="hidden mobile:block w-full">
        <HeaderUploadSection />
      </div>
      <div className="flex mobile:flex-row flex-col items-center justify-center mobile:mb-6 font-[900] tablet:mt-10">
        <h1
          className="font-[900] text-[38px] leading-[52px] mobile:text-[48px]
          mobile:leading-[58px] text-center mobile:px-8 w-full max-w-[946px]"
        >
          {service.uploadSection.headline}
        </h1>
      </div>
      <div>
        <p className="font-[600] w-full max-w-[752px]">{service.uploadSection.subtitle_1}</p>
      </div>

      {generateUploadFrom()}

      {error && (
        <div className={classNames(errorClasses)}>
          {error}
          <img
            src={cross}
            alt="wrong-file-format-banner-cross"
            className="ml-4 cursor-pointer"
            onClick={onCloseErrorBannerClick}
          />
        </div>
      )}
      <div className="text-[13px] leading-[17px] text-[#1D1D1D] mt-2 text-center mobile:hidden block">
        {t('upload_section.widget.limit')}
      </div>
      <div
        className="w-full mobile:max-w-[684px] mobile:mt-[48px] mt-6 flex justify-between items-center flex-row mx-auto
        mobile:gap-y-0 gap-y-3 gap-x-4 mobile:gap-x-2"
      >
        {features.map((item) => (
          <div
            key={item.text}
            className="flex items-center justify-center mobile:gap-x-3 gap-x-2 text-[13px] leading-[17px]
               w-full mobile:max-w-[222px] mobile:text-[20px] mobile:leading-[25px] font-[600]"
          >
            <img src={item.img} alt="feature_img" className="mobile:w-[48px] w-[36px] h-fit" />
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
