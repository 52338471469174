import { ButtonIcon } from 'components/buttons/icon-button';
import { PAGE_LINKS } from 'ts/constants/page-links';

import form1040 from 'assets/img/icons/50px/1040.svg';
import form1065 from 'assets/img/icons/50px/1065.svg';
import form1095a from 'assets/img/icons/50px/1095-a.svg';
import form1098t from 'assets/img/icons/50px/1098-t.svg';
import misc1099form from 'assets/img/icons/50px/1099-misc.svg';
import nec1099form from 'assets/img/icons/50px/1099-nec.svg';
import form2553 from 'assets/img/icons/50px/2553.svg';
import compressPdf from 'assets/img/icons/50px/compressPdf.svg';
import ds11form from 'assets/img/icons/50px/ds-11.svg';
import ds82form from 'assets/img/icons/50px/ds-82.svg';
import editPdf from 'assets/img/icons/50px/editPdf.svg';
import exelPdf from 'assets/img/icons/50px/exel-pdf.svg';
import form941 from 'assets/img/icons/50px/f-941.svg';
import i9form from 'assets/img/icons/50px/i-9.svg';
import jpgPdf from 'assets/img/icons/50px/jpg-pdf.svg';
import mergePdf from 'assets/img/icons/50px/mergePdf.svg';
import ocr from 'assets/img/icons/50px/ocr.svg';
import pdfExel from 'assets/img/icons/50px/pdf-exel.svg';
import pdfJpg from 'assets/img/icons/50px/pdf-jpg.svg';
import pdfPng from 'assets/img/icons/50px/pdf-png.svg';
import pdfPtt from 'assets/img/icons/50px/pdf-ptt.svg';
import pdfWord from 'assets/img/icons/50px/pdf-word.svg';
import pngPdf from 'assets/img/icons/50px/png-pdf.svg';
import protectPdf from 'assets/img/icons/50px/protectPdf.svg';
import pttPdf from 'assets/img/icons/50px/ptt-pdf.svg';
import signPdf from 'assets/img/icons/50px/sign-pdf.svg';
import splitPdf from 'assets/img/icons/50px/splitPdf.svg';
import w2form from 'assets/img/icons/50px/w-2.svg';
import w3form from 'assets/img/icons/50px/w-3.svg';
import w4form from 'assets/img/icons/50px/w-4.svg';
import w9form from 'assets/img/icons/50px/w-9.svg';
import wordPdf from 'assets/img/icons/50px/word-pdf.svg';
import { InternalFileType } from 'ts/types/file';

type Link = {
  label: string;
  href: string;
  icon: ButtonIcon;
  img: string;
  from: InternalFileType;
  to?: InternalFileType;
};
export type DropDownList = {
  label: string;
  links: Link[];
};

export const getLinksFrom = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.from.label'),
    links: [
      {
        label: t('links.from.pdf_to_word'),
        href: PAGE_LINKS.PDF_TO_WORD,
        icon: 'word',
        img: pdfWord,
        from: InternalFileType.PDF,
        to: InternalFileType.DOC,
      },
      {
        label: t('links.from.pdf_to_png'),
        href: PAGE_LINKS.PDF_TO_PNG,
        icon: 'png',
        img: pdfPng,
        from: InternalFileType.PDF,
        to: InternalFileType.PNG,
      },
      {
        label: t('links.from.pdf_to_epub'),
        href: PAGE_LINKS.PDF_TO_EPUB,
        icon: 'epub',
        img: wordPdf,
        from: InternalFileType.PDF,
        to: InternalFileType.EPUB,
      },
      {
        label: t('links.from.pdf_to_jpg'),
        href: PAGE_LINKS.PDF_TO_JPG,
        icon: 'img',
        img: pdfJpg,
        from: InternalFileType.PDF,
        to: InternalFileType.JPG,
      },
      {
        label: t('links.from.pdf_to_pptx'),
        href: PAGE_LINKS.PDF_TO_PPTX,
        icon: 'ptt',
        img: pdfPtt,
        from: InternalFileType.PDF,
        to: InternalFileType.PPTX,
      },
      {
        label: t('links.from.pdf_to_excel'),
        href: PAGE_LINKS.PDF_TO_EXCEL,
        icon: 'spreadsheets',
        img: pdfExel,
        from: InternalFileType.PDF,
        to: InternalFileType.XLS,
      },
    ],
  };
};

export const getLinksTo = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.to.label'),
    links: [
      {
        label: t('links.to.word_to_pdf'),
        href: PAGE_LINKS.WORD_TO_PDF,
        icon: 'word',
        img: wordPdf,
        from: InternalFileType.DOC,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.to.png_to_pdf'),
        href: PAGE_LINKS.PNG_TO_PDF,
        icon: 'png',
        img: pngPdf,
        from: InternalFileType.PNG,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.to.epub_to_pdf'),
        href: PAGE_LINKS.EPUB_TO_PDF,
        icon: 'epub',
        img: wordPdf,
        from: InternalFileType.EPUB,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.to.jpg_to_pdf'),
        href: PAGE_LINKS.JPG_TO_PDF,
        icon: 'img',
        img: jpgPdf,
        from: InternalFileType.JPG,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.to.pptx_to_pdf'),
        href: PAGE_LINKS.PPTX_TO_PDF,
        icon: 'ptt',
        img: pttPdf,
        from: InternalFileType.PPTX,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.to.excel_to_pdf'),
        href: PAGE_LINKS.EXCEL_TO_PDF,
        icon: 'spreadsheets',
        img: exelPdf,
        from: InternalFileType.XLS,
        to: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksTools = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.tools.label'),
    links: [
      {
        label: t('links.tools.edit_pdf'),
        href: PAGE_LINKS.EDIT_PDF,
        icon: 'edit-fill',
        img: '',
        from: InternalFileType.PDF,
      },
      {
        label: t('links.tools.compress_pdf'),
        href: PAGE_LINKS.COMPRESS_PDF,
        icon: 'compress',
        img: '',
        from: InternalFileType.PDF,
      },
      {
        label: t('links.tools.translate_pdf'),
        href: PAGE_LINKS.TRANSLATE_PDF,
        icon: 'translate',
        img: '',
        from: InternalFileType.PDF,
      },
      {
        label: t('links.tools.pdf_ocr'),
        href: PAGE_LINKS.PDF_OCR,
        icon: 'ocr',
        img: '',
        from: InternalFileType.PDF,
      },
      {
        label: t('landing.fill_pdf.title'),
        href: PAGE_LINKS.FILL_PDF,
        icon: 'edit-fill',
        img: '',
        from: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksMergeSplit = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.merge_split.label'),
    links: [
      {
        label: t('links.merge_split.merge_pdf'),
        href: PAGE_LINKS.MERGE_PDF,
        icon: 'merge',
        img: mergePdf,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.merge_split.split_pdf'),
        href: PAGE_LINKS.SPLIT_PDF,
        icon: 'split',
        img: splitPdf,
        from: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksForms = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.forms.label'),
    links: [
      {
        label: t('links.forms.w9_form'),
        href: PAGE_LINKS.FORM_W9,
        icon: 'forms',
        img: w9form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.i9_form'),
        href: PAGE_LINKS.FORM_I9,
        icon: 'forms',
        img: i9form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.ds11_form'),
        href: PAGE_LINKS.FORM_DS11,
        icon: 'forms',
        img: ds11form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.1099_misc_form'),
        href: PAGE_LINKS.FORM_1099MISC,
        icon: 'forms',
        img: misc1099form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.1099_nec_form'),
        href: PAGE_LINKS.FORM_1099NEC,
        icon: 'forms',
        img: nec1099form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.941_form'),
        href: PAGE_LINKS.FORM_941,
        icon: 'forms',
        img: form941,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.w2_form'),
        href: PAGE_LINKS.FORM_W2,
        icon: 'forms',
        img: w2form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.w3_form'),
        href: PAGE_LINKS.FORM_W3,
        icon: 'forms',
        img: w3form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.1098_t_form'),
        href: PAGE_LINKS.FORM_1098T,
        icon: 'forms',
        img: form1098t,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.1065_form'),
        href: PAGE_LINKS.FORM_1065,
        icon: 'forms',
        img: form1065,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.2553_form'),
        href: PAGE_LINKS.FORM_2553,
        icon: 'forms',
        img: form2553,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.1095_a_form'),
        href: PAGE_LINKS.FORM_1095A,
        icon: 'forms',
        img: form1095a,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.1040_form'),
        href: PAGE_LINKS.FORM_1040,
        icon: 'forms',
        img: form1040,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.w4_form'),
        href: PAGE_LINKS.FORM_W4,
        icon: 'forms',
        img: w4form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.ds82_form'),
        href: PAGE_LINKS.FORM_DS82,
        icon: 'forms',
        img: ds82form,
        from: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksFormsShortList = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.forms.label'),
    links: [
      {
        label: t('links.forms.w9_form'),
        href: PAGE_LINKS.FORM_W9,
        icon: 'forms',
        img: w9form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.ds11_form'),
        href: PAGE_LINKS.FORM_DS11,
        icon: 'forms',
        img: ds11form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.1099_form'),
        href: PAGE_LINKS.FORM_1099,
        icon: 'forms',
        img: nec1099form,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.941_form'),
        href: PAGE_LINKS.FORM_941,
        icon: 'forms',
        img: form941,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.forms.w2_form'),
        href: PAGE_LINKS.FORM_W2,
        icon: 'forms',
        img: w2form,
        from: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksCompress = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.tools.label'),
    links: [
      {
        label: t('links.tools.compress_pdf'),
        href: PAGE_LINKS.COMPRESS_PDF,
        icon: 'compress',
        img: compressPdf,
        from: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksSecurity = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.security.label'),
    links: [
      {
        label: t('links.security.signature_pdf'),
        href: PAGE_LINKS.SIGNATURE_PDF,
        icon: 'signature',
        img: signPdf,
        from: InternalFileType.PDF,
      },
      {
        label: t('links.security.protect_pdf'),
        href: PAGE_LINKS.PROTECT_PDF,
        icon: 'protect',
        img: protectPdf,
        from: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksEditPdf = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.tools.label'),
    links: [
      {
        label: t('links.tools.edit_pdf'),
        href: PAGE_LINKS.EDIT_PDF,
        icon: 'edit-fill',
        img: editPdf,
        from: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksConverter = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.tools.label'),
    links: [
      {
        label: t('links.from.pdf_to_word'),
        href: PAGE_LINKS.PDF_TO_WORD,
        icon: 'word',
        img: pdfWord,
        from: InternalFileType.PDF,
        to: InternalFileType.DOC,
      },
      {
        label: t('links.to.word_to_pdf'),
        href: PAGE_LINKS.WORD_TO_PDF,
        icon: 'word',
        img: wordPdf,
        from: InternalFileType.DOC,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.from.pdf_to_png'),
        href: PAGE_LINKS.PDF_TO_PNG,
        icon: 'png',
        img: pdfPng,
        from: InternalFileType.PDF,
        to: InternalFileType.PNG,
      },
      {
        label: t('links.to.png_to_pdf'),
        href: PAGE_LINKS.PNG_TO_PDF,
        icon: 'png',
        img: pngPdf,
        from: InternalFileType.PNG,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.from.pdf_to_jpg'),
        href: PAGE_LINKS.PDF_TO_JPG,
        icon: 'img',
        img: pdfJpg,
        from: InternalFileType.PDF,
        to: InternalFileType.JPG,
      },
      {
        label: t('links.to.jpg_to_pdf'),
        href: PAGE_LINKS.JPG_TO_PDF,
        icon: 'img',
        img: jpgPdf,
        from: InternalFileType.JPG,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.from.pdf_to_excel'),
        href: PAGE_LINKS.PDF_TO_EXCEL,
        icon: 'spreadsheets',
        img: pdfExel,
        from: InternalFileType.PDF,
        to: InternalFileType.XLS,
      },
      {
        label: t('links.to.excel_to_pdf'),
        href: PAGE_LINKS.EXCEL_TO_PDF,
        icon: 'spreadsheets',
        img: exelPdf,
        from: InternalFileType.XLS,
        to: InternalFileType.PDF,
      },
      {
        label: t('links.from.pdf_to_pptx'),
        href: PAGE_LINKS.PDF_TO_PPTX,
        icon: 'ptt',
        img: pdfPtt,
        from: InternalFileType.PDF,
        to: InternalFileType.PPTX,
      },
      {
        label: t('links.to.pptx_to_pdf'),
        href: PAGE_LINKS.PPTX_TO_PDF,
        icon: 'ptt',
        img: pttPdf,
        from: InternalFileType.PPTX,
        to: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksOcr = (t: (key: string) => string): DropDownList => {
  return {
    label: t('links.tools.label'),
    links: [
      {
        label: t('links.tools.pdf_ocr'),
        href: PAGE_LINKS.PDF_OCR,
        icon: 'ocr',
        img: ocr,
        from: InternalFileType.PDF,
      },
    ],
  };
};

export const getLinksFillPdf = (t: (key: string) => string): DropDownList => {
  return {
    label: t('landing.fill_pdf.title'),
    links: [
      {
        label: t('landing.fill_pdf.title'),
        href: PAGE_LINKS.FILL_PDF,
        icon: 'edit-fill',
        img: editPdf,
        from: InternalFileType.PDF,
      },
    ],
  };
};
