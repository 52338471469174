import { useState } from 'react';

import { PdfguruLogo } from 'components/ai-summarizer/ui/logo';

import { AIResponseBody } from './ai-response-body';
import { AISuggestedQuestions } from './ai-suggested-questions';

interface AIResponseProps {
  message: string;
  suggestedQuestions?: string[];
  isHistory?: boolean;
}

export const AIResponse = ({ message, suggestedQuestions, isHistory }: AIResponseProps) => {
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [currentChunk, setCurrentChunk] = useState(0);
  const CHUNK_SIZE = 10;

  return (
    <div className="flex gap-4 pt-4">
      <PdfguruLogo />

      <div className="w-full">
        <AIResponseBody
          isHistory={isHistory}
          message={message}
          displayedMessage={displayedMessage}
          currentChunk={currentChunk}
          setDisplayedMessage={setDisplayedMessage}
          setCurrentChunk={setCurrentChunk}
          chunkSize={CHUNK_SIZE}
        />

        {suggestedQuestions && suggestedQuestions.length > 0 && (
          <AISuggestedQuestions suggestedQuestions={suggestedQuestions} />
        )}
      </div>
    </div>
  );
};
