import React from "react"

import { useHeaderUploadSectionInteractor } from "./interactor"
import { HeaderUploadSectionRouter } from "./router"

export interface IProps {
  withContactUsLink?: boolean;
}
export const HeaderUploadSection: React.FC<IProps> = ({ withContactUsLink = false }) => {
  const interactor = useHeaderUploadSectionInteractor()
  return <HeaderUploadSectionRouter interactor={interactor} withContactUsLink={withContactUsLink} />
}
