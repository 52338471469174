import { AnalyticsEvent } from 'services/analytics/events'
import {
  Analytics,
  AnalyticsService,
  AnalyticsUserProp,
} from 'services/analytics/index'
import { getDeviceType } from 'utils/getDeviceType'
import { Logger } from 'utils/logger'

export class PixelAnalytics implements AnalyticsService {
  private initialized = false
  private eventBuffer: AnalyticsEvent[] = []
  private readonly logger = new Logger('Pixel')

  public init() {
    const interval = setInterval(() => {
      // @ts-ignore
      if (window?.fbq === undefined) { // eslint-disable-line
        return
      }

      this.initialized = true
      this.logger.log('Init')
      this.processBuffer()

      clearInterval(interval)
    }, 50)
  }

  public track(event: AnalyticsEvent) {
    if (!this.initialized) {
      this.eventBuffer.push(event)
      return
    }

    this.sendEvent(event)
  }

  public updateUser(_: AnalyticsUserProp | AnalyticsUserProp[]) {}

  public setUserId(_: string): void {}

  private processBuffer() {
    this.eventBuffer.map((event) => this.sendEvent(event))
    this.eventBuffer = []
  }

  private sendEvent(event: AnalyticsEvent | any): void {
    const page = Analytics.getCurrentPageName()
    const localPage = Analytics.getCurrentPageLocale()
    const deviceType = getDeviceType()
    let props = {
      page,
      local_page: localPage,
      device: deviceType,
    }

    if ('data' in event && event.data) {
      props = { ...props, ...event.data }
    }

    if (event.event === 'sale_confirmation_success') {
      // @ts-ignore
      window?.fbq('track', 'Purchase', {
        value: event.data.value,
        price: event.data.price,
        currency: 'USD',
      })
    } else {
      // @ts-ignore
      window?.fbq('trackCustom', event.event, props)
    }
    this.logger.log('Send event', {
      name: event.event,
      data: JSON.stringify(props),
    })
  }
}

export const FBPixel = new PixelAnalytics()
