import { getABTestsFormatted } from 'helpers/getAbTestsFormatted';
import { EReduxTypes } from 'ts/enums/redux.types';
import { ABTestsState, IAbTest } from 'ts/interfaces/abTest/abTest';

import { IReduxAction } from 'ts/interfaces/redux/redux.action';

export const GET_AB_TESTS = 'GET_AB_TESTS';

const isAutoTest = !!localStorage.getItem('isAutoTest');

const initialState: ABTestsState = {
  tests: getABTestsFormatted().abTests,
};

// Reducer
const abTestsReducer = (state = initialState, action: IReduxAction) => {
  const data: IAbTest[] = action.data;
  switch (action.type) {
    case EReduxTypes.SET_AB_TESTS: {
      // Keep only tests that exist in both state.tests and data
      const filteredExistingTests = state.tests.filter((stateTest) =>
        data.some((dataTest) => dataTest.feature === stateTest.feature)
      );

      // Add new tests from data that don't exist in state.tests
      const newTests = data.filter(
        (dataTest) => !state.tests.some((stateTest) => stateTest.feature === dataTest.feature)
      );

      const updatedTests = [...filteredExistingTests, ...newTests];
      if (!isAutoTest) {
        localStorage.setItem('abTests', JSON.stringify(updatedTests));
      }

      return {
        tests: updatedTests,
      };
    }

    default:
      return state;
  }
};

export default abTestsReducer;
