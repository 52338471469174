
import { useTranslation } from 'react-i18next';

import burgerMenu from 'assets/ai-summarizer/burger-menu.svg';
import cross from 'assets/img/icons/32px/cross.svg';

import { Analytics } from 'services/analytics';

import { setMobileMenuOpen } from 'data/actions/aiSummarizer';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileMenuOpenSelector } from 'data/selectors/aiSummarizer';

export const Subheader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobileMenuOpen = useSelector(isMobileMenuOpenSelector);

  const handleMenuToggle = () => {
    Analytics.sendEvent({
      event: isMobileMenuOpen
        ? 'pdf_summarizer_close_chats_tap'
        : 'pdf_summarizer_view_chats_tap',
    });

    dispatch(setMobileMenuOpen(!isMobileMenuOpen));

  };

  return (
    <div className="border-b border-l border-[#E9E9E9] shadow-[1px_1px_2px_0px_rgba(29,29,29,0.08)] py-4 px-4 flex justify-between items-center small-desktop:hidden">
      <h1 className="text-[20px] w-max font-bold">{t('ai_summarizer.title')}</h1>

      <button onClick={handleMenuToggle} data-testid="ai-summarizer-menu-toggle">
        <img src={isMobileMenuOpen ? cross : burgerMenu} alt="burger-menu" />
      </button>
    </div>
  );
};
