import bgImgMob from 'assets/img/forms/background-w-9-mob.webp';
import bgImgTab from 'assets/img/forms/background-w-9-tab.webp';
import bgImgWeb from 'assets/img/forms/background-w-9-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';

import formW9 from 'assets/forms/w9.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';


export const formW9Service = (t: any): IFormService => {
  return {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-w9',
      filePath: formW9,
      fileName: 'w9.pdf',
      from: 'PDF',
      to: 'PDF',
      meta: {
        title: t('meta.form_w9.title'),
        description: t('meta.form_w9.description'),
      },
      getForm: {
        headline1: 'IRS',
        headline2: 'Form',
        formName: 'W-9',
        subHeadline: '2025 Printable Template',
        backgroundImageWeb: bgImgWeb,
        backgroundImageTab: bgImgTab,
        backgroundImageMob: bgImgMob,
        buttonLabel: 'GET FORM',
      },
      easilyComplete: {
        headline: 'Easily complete & submit your W-9 form',
        description: [
          {
            icon: icon_1,
            iconMob: icon_mob_1,
            title: 'Fill out your form',
            text: 'Our user-friendly editor helps you quickly enter personal & business details in the W9 template.',
          },
          {
            icon: icon_2,
            iconMob: icon_mob_2,
            title: 'Sign the form',
            text: 'Add your electronic signature to the fillable W9 form to certify it came directly from you.',
          },
          {
            icon: icon_3,
            iconMob: icon_mob_3,
            title: 'Send it to the requester',
            text: 'Download and instantly print or share your signed W-9 with the requester.',
          },
        ],
      },
      whatIsForm: {
        headline: 'What is a W-9 used for?',
        text_1: [],
        text_2: [
          'A W-9 fillable form needs to be completed by any individual or business that works as an independent contractor, freelancer, or vendor providing services to a company, organization, or individual payer. Whenever you begin working with a new client, they will likely ask you to send them a completed W-9 so they can properly document payments to you and report to the Internal Revenue Service (IRS). Even if you already have sent the payer this document, you may have to provide an updated one each year you continue receiving payments.',
        ],
        text_3: [
          'The W-9 and 1099 forms are both used for tax reporting in the United States, but they serve different purposes. The W-9 form is typically completed by the person or business receiving payment to collect the necessary information for tax purposes.',
          "On the other hand, the 1099 form is used to report income paid to a non-employee, such as an independent contractor or freelancer. This form is completed by the entity or business that made the payment to the individual or company. The 1099 form includes information such as the recipient's name, address, and TIN, as well as the amount of income paid. In summary, while the W-9 form collects information from individuals or businesses receiving payment, the 1099 form reports income paid to non-employees.",
        ],
        text_4: [''],
        title_1: 'Who needs to fill out a W-9 form',
        title_2: 'Difference between W-9 and 1099 forms',
        subTitle: '',
        sectionImage: section_img,
        sectionImageTab: section_img_tab,
        description: [
          "In simple terms, you fill out the W-9 tax form to give your details to companies or people who pay you. The main information you have to include on this form is your name, business name if applicable, address, tax identification number (TIN), and tax classification. The purpose of the W-9 is to allow payers to accurately report payments made to contractors, freelancers, vendors, etc., so that the IRS has a record of income for tax purposes. Basically, the W-9 helps facilitate 1099 tax reporting.",
          "You can fill out W9 form in several ways. For instance, you can use our PDF maker to complete an editable W9 template and sign it. Alternatively, you can print out the form and fill it out by hand, but that would require more paperwork. If you edit and download a W-9 form PDF using our tool, you'll be able to share the copy with the requester.",
          "According to W 9 requirements, this form may also be requested by financial institutions or other entities when establishing some types of financial accounts for the independent contractor. It helps to verify the contractor's identity and prevent identity theft. Overall, the W-9 form is necessary for collecting taxpayer information to accurately report payments and maintain legal and financial records."
        ],
        bullets: [],
      },
      howToFill: {
        headline: 'How to fill out a W-9 online?',
        //@ts-ignore
        sectionImage: howToFillImage,
        bullets: [
          {
            icon: blue_number_icon_1,
            text: 'Click the Fill Form button under this section to complete the W9 form online',
          },
          {
            icon: blue_number_icon_2,
            text: 'In the Editor window that opens, enter your personal, business, and tax ID information in the fillable W-9 form',
          },
          {
            icon: blue_number_icon_3,
            text: 'Certify the form with your digital signature and date',
          },
          {
            icon: blue_number_icon_4,
            text: 'Download or email the completed form to the person or company that requested it from you',
          },
        ],
      },
      fillFormFree: {
        headline: 'Fill out the W-9 Form in Seconds',
        subtitle: 'It has never been easier or faster',
        buttonLabel: 'Fill Form',
      },
      faqSection: {
        headline: 'Frequently asked questions',
        faqSectionDescription: [
          {
            question: "What's the difference between W-9 and W-4?",
            answer: [
              "The W-9 and W-4 forms are two different documents used for tax purposes in the United States. We've already established that you use the W-9 form to provide your taxpayer identification number to someone who might need to pay you.",
              "On the other hand, the W-4 form, also known as the Employee's Withholding Certificate, is a form you fill out when you start a new job or when your personal or financial situation changes. It helps your employer determine how much federal income tax to withhold from your paycheck.",
              "While both forms are related to taxation, they serve different purposes and are required in different situations. Whichever one you need, you can use our platform to fill out W9 online, as well as many other types of documents."
            ]
          },
          {
            question: 'Can I fill out and sign the W-9 form online?',
            answer: [
              "Yes, you can fill out and sign the W9 form (2023 version) on our website. You don't even need to get it elsewhere and upload it to PDF Guru. Simply click GET FORM at the top of this page, and we'll give you a blank W9 form, fillable and suitable for e-signature. Use it to provide your taxpayer details and certify the form for convenience and speed. The electronic signature on a W9 online form is as valid as a handwritten signature on a paper one. Once you've certified the document, you can download the W9 form PDF to your device for future sharing."
            ]
          },
          {
            question: 'What happens if I fail to provide a W-9 form when requested?',
            answer: [
              "If you do not fill out the W9 tax form when requested by the payer, they may be unable to process your payment. As per W 9 instructions, they'd be required to withhold a portion of payments made to you for tax purposes. Additionally, the IRS may penalize payers for failure to obtain the form. Therefore, to avoid these unpleasant consequences, we recommend you create a W9 fill out online using our tools because it's easy, doesn't require any extra paperwork, and can be done in less than 5 minutes."
            ]
          },
        ],
      },
      fag: {
        question: 'How to fill out a W-9 online?',
        answer:
          '1. Click the Fill Form button under this section to complete the W9 form online. 2. In the Editor window that opens, enter your personal, business, and tax ID information in the fillable W-9 form. 3. Certify the form with your digital signature and date. 4. Download or email the completed form to the person or company that requested it from you.',
      },
      bannerQuickNEasy: {
        headline: 'The Quickest Way to Fill Out a W-9',
        subtitle: 'Add your info in a few easy clicks!',
        format: InternalFileType.PDF,
        buttonLabel: 'Fill Form',
      },
  };
};
