import bgImgMob from 'assets/img/forms/background-w-3-mob.webp';
//import bgImgTab from 'assets/img/forms/background-w-3-tab.webp';
import bgImgWeb from 'assets/img/forms/background-w-3-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';

import formW3 from 'assets/forms/w-3.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const formW3Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-w3',
    filePath: formW3,
    fileName: 'w-3.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_w3.title'),
      description: t('meta.form_w3.description'),
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: 'W-3',
      subHeadline: '2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline: 'Fill your W-3 Form in just a few minutes with PDF Guru',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Add your details',
          text: 'Use our Form W-3 editor to put in your personal information and make sure everything is correct.',
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Print it out',
          text: 'Once you’ve filled in all the necessary details, download the W 3 Form PDF or  print it out.',
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Submit the form',
          text: "Visit a tax reporting facility or submit your completed W-3 Form 2023 through the IRS's FIRE system.",
        },
      ],
    },
    whatIsForm: {
      headline: 'What is Form W-3?',
      text_1: [],
      text_2: [
        "The W 3 Form definition is pretty clear: its goal is to facilitate tax reporting by employers. In other words, it makes their life easier by capturing and enlisting crucial information such as the employer's name, address, and Employer Identification Number (EIN). It also includes all employees' total wages, Social Security, and Medicare wages, making it easier to accurately report financial details to the SSA.",
      ],
      text_3: [
        'The next deadline to submit W-3 forms is January 31, 2024. That’s the case both for in-person and online submissions. Make sure you submit it by the due date because only this way you will avoid penalties. Also, don’t forget to submit the W 3 Form online along with the relevant W-2 forms.',
      ],
      text_4: [],
      orangeList: [],
      title_1: 'What is a W-3 Form 2023 used for?',
      title_2: 'When are W-3 Forms due in 2023?',
      subTitle: '',
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        `The official name of the W-3 Form is "Transmittal of Wage and Tax Statements”. It serves as a critical component in the employer's tax reporting process. Developed by the IRS, the W-3 Form is instrumental in summarizing and transmitting comprehensive wage and tax information to the Social Security Administration (SSA).`,
        `It’s not that easy to fill documents of this kind. Not only because some technical terms are difficult to decipher. But also because you would have to print it out, fill it out, sign it, and with each mistake you would have to start the process all over again. For this very reason, PDF Guru comes to your aid. With it, you can fill out the W-3 Form directly online, sign it, and print it without any problems. In short, at least one worry in paying your taxes is alleviated!`,
        `Once completed, the W-3 tax form, along with the corresponding W-2 forms, is submitted to an authorized SSA facility. The employer is responsible for ensuring the accuracy of the information provided before submitting the document. The form W-3, much like the I-9 form for employment eligibility, plays a pivotal role in maintaining legal compliance and transparency in workforce reporting.`
      ],
      bullets: [],
    },
    differenceBetween2: {
      headline: 'How do I submit a W-3 Form?',
      text: 'You have 2 options to submit your  W-3 Form: online or physically. For the first option, just use the IRS’s electronic submission system; it’s the easiest and fastest way. Otherwise, you can print your form and bring the paperwork to the closest tax return acceptance center.',
    },
    howToFill: {
      headline: 'How to fill out a W-3 Form online',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          text: `Locate and click the "Fill Form" button specific to the W-3 tax form`,
        },
        {
          icon: blue_number_icon_2,
          text: `In the opened Editor window, put all the essential information — make sure to type everything carefully`,
        },
        {
          icon: blue_number_icon_3,
          text: `Check with the IRS requirements for the W-3 Form (2023 version)`,
        },
        {
          icon: blue_number_icon_4,
          text: `Once all details are entered, your printable W 3 Form download will be ready as a PDF file`,
        },
      ],
    },
    fillFormFree: {
      headline: 'Compete the W-3 Form Quickly',
      subtitle: '',
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: "What’s included in a W-3 Form?",
          answer: [
            "A W-3 comprises many parts, starting from all the important information regarding the employer's declared payroll and tax returns. The employer's information includes their name, address, and EIN. The W-3 should also detail total wages, social security, Medicare, etc. By covering all these areas, it gives a very detailed view of the employer's activity."
          ]
        },
        {
          question: "What information needs to be included in the W-3 Form?",
          answer: [
           "When filling out a Form W 3, you will also need to include some more information. For example, the total number of W-2 forms submitted with the W-3 Form 2023. But also contact information, such as the name and phone number of the person responsible for the employer's payroll or tax matters. Remember, every single detail is crucial here, and no information can be dismissed as unimportant, nor can it be skipped! Follow the IRS W 3 Form instructions to be sure you won’t make any mistakes."
          ]
        },
        {
          question: "What is the difference between a form W-3 and a W-2?",
          answer: [
            "For novices, getting confused between Form W-3 and Form W-2 is common. We are here also for that! Let’s get to the point, then! The main difference is the type of information each contains and their purpose in employee tax reporting. Form W-2 provides specific details about earnings and taxes for each employee. It includes information such as gross income, federal and state taxes withheld, Social Security and Medicare contributions, and other items that can be relevant. On the other hand, Form W-3 works as a summary document. It collects information from multiple W-2 forms. The federal form W-3 includes cumulative totals of wages, social security contributions, and Medicare for all employees. By doing so, it provides an overall picture of the employer's tax liability to the Social Security Administration (SSA)."
          ]
        },
      ],
    },
    fag: {
      question: 'How to fill out a W-3 Form online?',
      answer:
        '1. Locate and click the "Fill Form" button specific to the W-3 tax form. 2. In the opened Editor window, put all the essential information — make sure to type everything carefully. 3. Check with the IRS requirements for the W-3 Form (2023 version). 4. Once all details are entered, your printable W 3 Form download will be ready as a PDF file',
    },
    bannerQuickNEasy: {
      headline: 'The Quickest Way to Fill Out a W-3 Form',
      subtitle: 'Your document will be ready in a few seconds',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
  };
};
