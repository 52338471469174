import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModalLayout from 'components/modals/baseModal/base';
import { Analytics } from 'services/analytics';
import { modalOptionsSelector } from 'data/selectors/modals';
import illustration from './assets/illustration.svg';

const UnderDevelopmentModal: FC = () => {
  const { t } = useTranslation();
  const { event } = useSelector(modalOptionsSelector);

  useEffect(() => {
    if (event) {
      Analytics.sendEvent({
        event: event,
        data: { place: 'my_dashboard' },
      });
    }
  }, [event]);

  return (
    <ModalLayout canClose>
      <div className="bg-white flex-col items-center py-8 px-4 tablet:py-8 tablet:px-[48px]">
        <img src={illustration} alt="Illustration" className="w-full max-w-[120px] mx-auto" />
        <div className="mx-auto text-[20px] tablet:text-[25px] text-[#1D1D1D] font-bold text-center mt-4 tablet:mt-[25px] tablet:max-w-[460px]">
          {t('popups.feature_under_development_popup.title')} 🎉
        </div>
      </div>
    </ModalLayout>
  );
};

export default UnderDevelopmentModal;
