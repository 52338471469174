import Markdown from 'markdown-to-jsx';
import { useEffect } from 'react';

interface AiResponseBodyProps {
  message: string;
  displayedMessage: string;
  currentChunk: number;
  setDisplayedMessage: (message: string) => void;
  setCurrentChunk: (chunk: number) => void;
  chunkSize: number;
  isHistory?: boolean;
}

export const AIResponseBody = ({
  message,
  displayedMessage,
  currentChunk,
  setDisplayedMessage,
  setCurrentChunk,
  chunkSize,
  isHistory,
}: AiResponseBodyProps) => {
  useEffect(() => {
    if (isHistory) {
      return;
    }

    if (currentChunk * chunkSize < message.length) {
      const timer = setTimeout(() => {
        const endIndex = Math.min((currentChunk + 1) * chunkSize, message.length);
        const cleanedMessage = message.slice(0, endIndex).replace(/【\d+:\d+†[^】]+】/g, '');
        setDisplayedMessage(cleanedMessage);
        setCurrentChunk(currentChunk + 1);
      }, 20);
      return () => clearTimeout(timer);
    }

    return undefined;
  }, [currentChunk, message, chunkSize, setDisplayedMessage, setCurrentChunk, isHistory]);

  const messageToDisplay = isHistory
    ? message
        .replace(/【\d+:\d+†[^】]+】/g, '')
        .replace(/^```json|```$/g, '')
        .trim()
    : displayedMessage;

  return (
    <Markdown
      options={{
        overrides: {
          ul: (props) => (
            <ul
              {...props}
              className="mb-4 mb-[20px] list-disc pl-2 small-desktop:pl-6 text-sm+ font-normal leading-[130%]"
            >
              {props.children}
            </ul>
          ),
          li: (props) => (
            <li
              {...props}
              className="mb-2 first:mt-2 last:mb-0 text-[16px] small-desktop:text-[18px]"
            >
              {props.children}
            </li>
          ),
          ol: (props) => (
            <ol
              {...props}
              className="mb-4 mb-[20px] list-decimal pl-2 small-desktop:pl-6 text-sm+ font-normal leading-[130%]"
            >
              {props.children}
            </ol>
          ),
          h1: (props) => (
            <h1
              {...props}
              className="mx-auto text-[16px] small-desktop:text-[18px] font-bold leading-[130%] md:mb-6 md:font-extrabold"
            >
              {props.children}
            </h1>
          ),
          p: (props) => (
            <p
              {...props}
              className="mx-auto text-[16px] small-desktop:text-[18px] font-normal leading-[130%] md:mb-6 pt-2"
            >
              {props.children}
            </p>
          ),
        },
      }}
      className="mb-6"
    >
      {messageToDisplay}
    </Markdown>
  );
};
