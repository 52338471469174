import ModalLayout from 'components/modals/baseModal/base';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { EmailInput } from 'components/inputs/email-registration-modal';
import { PrimaryButton } from 'components/buttons/primary-button';
import { LoadingAnimation } from 'components/loading-animation';
import { PAGE_LINKS } from 'ts/constants/page-links';

import list_item_1 from './assets/list_item_1.svg';
import list_item_2 from './assets/list_item_2.svg';
import list_item_3 from './assets/list_item_3.svg';
import list_item_4 from './assets/list_item_4.svg';
import list_item_5 from './assets/list_item_5.svg';
import { useEffect } from 'react';
import LocalizedLink from 'components/localized-link';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'data/actions/modals';
import { Analytics } from 'services/analytics';
import { useLocation } from 'react-router-dom';
import trustpilot from './assets/image_variant_b.svg';

const EmailModalB = ({
  onClosePopup,
  setEmail,
  email,
  errors,
  isLoading,
  handleDownLoadFile,
}: {
  onClosePopup: () => void;
  setEmail: (email: string) => void;
  email: string;
  errors: any;
  isLoading: boolean;
  handleDownLoadFile: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (window.Trustpilot) {
      const widgets = document.querySelectorAll('.trustpilot-widget');
      widgets.forEach((widget) => {
        window.Trustpilot.loadFromElement(widget);
      });
    }
  }, []);

  const rightBlockItemsList = [
    {
      title: t('popups.new_enter_email_popup_test.bullet_1'),
      icons: list_item_1,
    },
    {
      title: t('popups.new_enter_email_popup_test.bullet_2'),
      icons: list_item_2,
    },
    {
      title: t('popups.new_enter_email_popup_test.bullet_3'),
      icons: list_item_3,
    },
    {
      title: t('popups.new_enter_email_popup_test.bullet_4'),
      icons: list_item_4,
    },
    {
      title: t('popups.new_enter_email_popup_test.bullet_5'),
      icons: list_item_5,
    },
  ];

  return (
    <ModalLayout canClose closePopup={onClosePopup} dataTestId="enter-email-modal">
      <div className="flex flex-row">
        <div className="px-4 pt-10 pb-4 tablet:px-8 tablet:py-10 max-w-[453px]">
          <h2 className="text-[25px] leading-[32.5px] text-center font-[700] text-[#1D1D1D] mb-4 tablet:mb-8">
            {t('popups.new_enter_email_popup_test.b_variant_title')}
            {i18n.language === 'ja' && (
              <div className="mb-4 text-[10px] text-center">
                {t('popups.new_enter_email_popup_test.already_have_footer')}
              </div>
            )}
          </h2>
          <EmailInput
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            onBlur={() => {}}
            value={email}
            error={errors?.email}
            onDomainClick={(email: string) => setEmail(email)}
          />
          <PrimaryButton
            onClick={handleDownLoadFile}
            className="!bg-[#5F30E2] mt-3 px-6 py-4 text-[18px] font-[700] text-[#FFF] w-full"
            disabled={isLoading}
            dataTestId="enter-email-modal-submit"
          >
            {isLoading ? (
              <LoadingAnimation currentState="loading" className="!h-[22px]" />
            ) : (
              t('popups.enter_email_popup.download')
            )}
          </PrimaryButton>
          <p
            className="mt-4 mobile:mt-3 text-[12px] leading-[16.2px]
          text-[#1D1D1D] w-full max-w-[500px] text-center"
          >
            {t('popups.enter_email_popup.by_clicking')}{' '}
            <a href={PAGE_LINKS.TERMS} rel="noreferrer" target="_blank" className="text-[#5F30E2]">
              {t('popups.enter_email_popup.terms_and_conditions')}
            </a>{' '}
            {t('popups.enter_email_popup.and')}{' '}
            <a
              href={PAGE_LINKS.PRIVACY_POLICY}
              rel="noreferrer"
              target="_blank"
              className="text-[#5F30E2]"
            >
              {t('popups.enter_email_popup.privacy_policy')}
            </a>
          </p>

          <div className="text-[13.2px] text-[#616161] leading-[17.7px] tablet:absolute tablet:bottom-4 tablet:w-[389px] text-center mt-4 tablet:mt-0">
            {t('popups.new_enter_email_popup_test.already_have_footer')}{' '}
            <LocalizedLink
              href={PAGE_LINKS.LOGIN}
              className="text-[#5F30E2] font-[700] underline"
              onClick={() => {
                dispatch(toggleModal({ visible: false }));
                Analytics.sendEvent({
                  event: 'log_in_tap',
                  data: {
                    placement: `email_modal/${location.pathname}`,
                    path: location.pathname,
                  },
                });
              }}
            >
              {t('header.login')}
            </LocalizedLink>{' '}
          </div>
        </div>
        <div className="px-8 py-10 min-w-[243px] hidden tablet:block bg-[#F6F6F6]">
          <h2 className="text-[18px] font-[700] leading-[32px] align-bottom">
            {t('popups.new_enter_email_popup_test.why_pdf_guru_title')}
          </h2>
          <ul className="flex flex-col gap-y-3 mt-4">
            {rightBlockItemsList.map((item, index) => (
              <li key={index} className="flex gap-x-2 items-center">
                <img src={item.icons} alt={item.title} />
                <p className="text-[16px] leading-[20.8px]">{item.title}</p>
              </li>
            ))}
          </ul>
          <div className="mt-4">
            <img src={trustpilot} alt="trustpilot" />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default EmailModalB;
