/**
 * @file bunch of feature flags to turn on/off some functionality.
 */
// import { config } from './configuration'

// const devOnly = config.appEnv === 'dev' || config.appEnv === 'local'

export const featureFlags = {
  /**
   * @description task:
   * https://atomoperations.atlassian.net/browse/PM-173
   */
  converterFromQuiz: true,
  updatedConvertingModals: true,
  convertingFailedModal: true,
  subscriptionCancelledText: true,
  paypalModal: true,
  featureLandings: true,
  isRemarketingEmailReleased: true,
  newPricingAB: true,
  'ff-filename-validation': true,
};
