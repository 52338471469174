const ANIMATION_CONFIG = {
    className: 'h-2 w-2 rounded-full animate-[pulse_1.4s_ease-in-out_infinite]',
    background: '#192CD2',
  }
  
  export const LoadingDot: React.FC<{ delay: string }> = ({ delay }) => (
    <div
      className={ANIMATION_CONFIG.className}
      style={{
        background: ANIMATION_CONFIG.background,
        animationDelay: delay,
      }}
    />
  )
  