import bgImgMob from 'assets/img/forms/background-w-2-mob.webp';
//import bgImgTab from 'assets/img/forms/background-w4-tab.webp';
import bgImgWeb from 'assets/img/forms/background-w-2-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';

import formW2 from 'assets/forms/w-2.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const formW2Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-w2',
    filePath: formW2,
    fileName: 'w-2.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_w2.title'),
      description: t('meta.form_w2.description'),
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: 'W-2',
      subHeadline: '2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline: 'Quick and easy Form W-2 instructions',
      description: [
        {
          icon: icon_1,
          iconMob: icon_mob_1,
          title: 'Fill out the W-2 tax form',
          text: 'Add the required information to the W-2 Form using our tool.',
        },
        {
          icon: icon_2,
          iconMob: icon_mob_2,
          title: 'Print it out',
          text: 'Complete the printable W-2 Form and get your hard copy.',
        },
        {
          icon: icon_3,
          iconMob: icon_mob_3,
          title: 'Send it to your employer',
          text: 'Send the W-2 Form via email or regular post to your employer for verification.',
        },
      ],
    },
    whatIsForm: {
      headline: 'What is a W-2 Form?',
      text_1: [],
      text_2: [
        'The W-2 Form you’ll receive offers a comprehensive snapshot of your taxable income, thereby aiding accurate tax filings with the IRS. It also contains Social Security contributions and Medicare taxes in addition to employer and employee details.',
      ],
      text_3: [
        "The purpose of a W-2 tax form is to report an employee's annual earnings, along with the taxes withheld by their employer throughout the year, thereby acting as a comprehensive summary of income. Additionally, the form shows deductions for federal, state, and other taxes, along with contributions to Social Security and Medicare.",
      ],
      text_4: [],
      text_5: [
        'The form is filled by the employer who must send it to both the employee and the IRS every year.',
      ],
      title_1: 'What does the W-2 Form tell you?',
      title_2: 'What’s the purpose of a W-2 Form?',
      title_3: 'Who files Form W-2?',
      subTitle: '',
      //@ts-ignore
      sectionImage: section_img,
      sectionImageTab: section_img_tab,
      description: [
        "The W-2 Form (2023 version) is an indispensable document for filling in US income tax returns that employers must give to their employees. The W-2 Form definition, or rather purpose, is to sum up your earnings and the amount of taxes withheld from your paychecks over the course of the previous year. As such, it shows your total income, including wages, tips, and other compensation, alongside deductions for federal, state, and other taxes"
      ],
      bullets: [],
    },
    howToFill: {
      headline: 'How to fill out a W-2 Form?',
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: '',
          text: 'Start by clicking the “Fill Form” button at the top or at the bottom of this page',
        },
        {
          icon: blue_number_icon_2,
          strong: '',
          text: 'Enter all the required information following IRS requirements',
        },
        {
          icon: blue_number_icon_3,
          strong: '',
          text: 'Verify the information on your W 2 Form online and download the file',
        },
        {
          icon: blue_number_icon_4,
          strong: '',
          text: 'Send it by regular post or email it to your employer',
        },
      ],
    },
    blockWithBlueTitle2: {
      title: 'How to read Form W-2',
      description: [
        'Before you fill in and print W 2 Form, it’s crucial that you know how to read it. For this purpose, we have a W-2 Form example so as to identify the various sections and know how they should be completed. Check this out before getting your W-2 Form 2023 PDF.',
      ],
      orangeList: [
        {
          strong: 'Boxes A-F. ',
          text: "Here are all the important details about you and your employer. Box A is for your social security number, Box E for your name, and Box F for your address. In addition, your employer's employer identification number (EIN) goes in Box B, and their name and address in Box C. If necessary, put a control number in Box D.",
        },
        {
          strong: 'Box 1 and 2. ',
          text: 'Box 1 on the W 2 Form 2023 PDF displays the annual taxable income as issued by your employer. This includes everything such as salary, wages, bonuses, tips, and other taxable compensations. Meanwhile, Box 2 outlines the aggregate federal income tax amount that your employer has withheld on your behalf.',
        },
        {
          strong: 'Boxes 3 - 6. ',
          text: 'Boxes 3 and 5 display the income portions that are subject to Social Security and Medicare taxes. Boxes 4 and 6 show the specific amounts withheld for these taxes. Discrepancies between Boxes 3 and 5 in comparison to Box 1 often occur. This is often due to income deferral, such as contributions to a 401(k) or similar plans.',
        },
        {
          strong: 'Box 7 and 8. ',
          text: "Shows the reported tip amount you've earned, while Box 8 on the W 2 tax form details the allocated tip portion designated by your employer.",
        },
        {
          strong: 'Box 9. ',
          text: 'Benefits that have become inactive as the employer benefit it previously represented is no longer applicable go into Box 9.',
        },
        {
          strong: 'Box 10. ',
          text: 'Use Box 10 of the W-2 Form to disclose any dependent care benefits provided or covered by your employer.',
        },
        {
          strong: 'Box 11. ',
          text: 'Details any received deferred compensation from your employer through a non-qualified plan.',
        },
        {
          strong: 'Box 12. ',
          text: "Here on the W-2 Form 2023, you'll find the details of various compensations or reductions affecting your taxable income. Each entry in this series features a single or double letter code and includes items like contributions to a 401(k) plan, employer-made health savings account contributions, or the taxable value of group-term life insurance surpassing $50,000.",
        },
        {
          strong: 'Box 13. ',
          text: 'Box 13 shows whether you were a statutory employee without any federal income tax withholding. Or if you were a part of any employer-sponsored retirement plans (e.g., 401(k) or 403(b)). It also shows if you collected sick pay from a third party, like an insurance policy.',
        },
        {
          strong: 'Box 14. ',
          text: 'Serves as a space for miscellaneous tax information not covered in other sections of the W 2 Form. It allows employers to report various items such as union dues, state disability insurance taxes withheld, uniform payments, deducted health insurance premiums, and similar details.',
        },
        {
          strong: 'Boxes 15-20. ',
          text: 'Finally, before you complete and proceed with the W 2 Form download, you need state and local income tax information. They display the two-letter abbreviation for your state alongside your employer’s state ID number assigned by the state. These boxes can accommodate wage details for up to two states and two localities.',
        },
      ],
    },
    differenceBetween2: {
      headline: 'When are W-2s due in 2023?',
      text: [
        'To ensure you receive the form on time, the IRS mandates that employers send it to you by January 31st after the calendar year closes. On the whole, they are mailed out by this date, though employees might not necessarily get them by then. To prevent this, some employers send a W 2 Form PDF file via email.',
        'Employers must file W-2 Forms with the Social Security Administration (SSA) and the IRS by January 31st. But, it’s possible to request a 30-day extension using Form 8809 under one of the extension criteria.',
      ],
    },
    fillFormFree: {
      headline: 'Zip Through Your W-2 Form with PDF Guru',
      subtitle: '',
      buttonLabel: 'Fill Form',
    },
    bannerQuickNEasy: {
      headline: 'Complete Your W-2 Form With Just a Few Clicks',
      subtitle: 'Let’s turbocharge your tax filing',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'When should you receive your W-2 tax form from your employer?',
          answer: [
            'The last day of January is the usual cutoff point to receive the W 2 tax form.',
          ],
        },
        {
          question: 'Why is it necessary to have a W-2 or 1099 Form when using tax preparation software?',
          answer: [
            'In a word, “accuracy.” When you\'re using tax preparation software, having forms W-2 and 1099 ensures all the details of income and taxes are at your fingertips.',
          ],
        },
        {
          question: 'What is the difference between a 1099 and W-2?',
          answer: [
            'The 1099-MISC form is for payments to independent contractors. On the other hand, the W-2 Form is for payroll employees to report their taxable income.',
          ],
        },
        {
          question: 'What is Form W-2G?',
          answer: [
            'This is used to report gambling winnings along with any federal taxes that were withheld at the time of payouts.',
          ],
        },
      ],
    },    
    fag: {
      question: 'How to fill out a W-3 Form online?',
      answer:
        '1. Locate and click the "Fill Form" button specific to the W-3 tax form. 2. In the opened Editor window, put all the essential information — make sure to type everything carefully. 3. Check with the IRS requirements for the W-3 Form (2023 version). 4. Once all details are entered, your printable W 3 Form download will be ready as a PDF file',
    },
  };
};
