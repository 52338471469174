import { updateDownloadProgress } from 'data/actions/documents';
import { uploadProgressSelector } from 'data/selectors/documents';
import { calculateProgress, useAnimatedProgress } from 'hooks/useAnimatedProgress';
import useCloseModalOnBack from 'hooks/useCloseModalOnBack';
import useDisableScroll from 'hooks/useDisableScroll';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Analytics } from 'services/analytics';
import { FileUploadingModalViewTypes } from 'services/analytics/events';
import { ProgressBar } from './components/progress-bar';

const ProcessingTranslateModal: FC = () => {
  const dispatch = useDispatch();
  useDisableScroll();
  useCloseModalOnBack();
  const { t } = useTranslation();
  const [barValue, setBarValue] = useState(0);
  const animatedProgress = useAnimatedProgress(5);
  const progressValue = calculateProgress(animatedProgress, 0, 90);

  useEffect(() => {
    dispatch(updateDownloadProgress(progressValue));
  }, [dispatch, progressValue]);
  const downloadProgress = useSelector(uploadProgressSelector());

  useEffect(() => {
    const newBarValue = downloadProgress.toFixed(0);

    if (newBarValue < 35) setBarValue(10);
    else if (newBarValue < 70) setBarValue(35);
    else if (newBarValue < 90) setBarValue(70);
    else if (newBarValue < 100) setBarValue(100);
  }, [downloadProgress]);

  useEffect(() => {
    Analytics.sendEvent({
      event: 'file_uploading_modal_view',
      data: {
        type: FileUploadingModalViewTypes.otherFunnels,
      },
    });
  }, []);

  return (
    <div className="fixed top-0 w-full h-screen bg-[rgba(11,16,44,0.3)] z-[1000] flex items-center justify-center px-4 animate-fadeIn md:h-[calc(100vh-calc(100vh-100%))]">
      <div className="px-12 py-8 rounded-[20px] bg-white shadow-[0px_5px_12px_0px_rgba(0,0,0,0.1)] w-[549px] min-h-[152px] flex flex-col items-center gap-[22px] relative md:w-[calc(100%-30px)] md:h-auto md:mx-[15px]">
        <div className="text-center text-[20px] leading-[24px] flex-shrink-0 self-stretch mx-auto md:w-full font-[700]">
          {t('editor_page.translate_pdf_banner.progress')}
        </div>
        <div className="text-center text-[#575757] text-[16px] leading-[130%] flex-shrink-0 self-stretch mx-auto md:w-full">
          {t('editor_page.please_wait')}
        </div>
        <ProgressBar progress={barValue} className="!mt-3" />
        {barValue}%
      </div>
    </div>
  );
};

export default ProcessingTranslateModal;
