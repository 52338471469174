import how_to_img from 'assets/img/how-to-section/how-to-new-design-merge-pdf.svg';
import icon_1 from 'assets/img/how-to-section/icon_1.svg';
import icon_2 from 'assets/img/how-to-section/icon_2.svg';
import icon_3 from 'assets/img/how-to-section/icon_3.svg';
import { FEATURE_IDS } from 'ts/constants/general';
import { EServiceType } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';
import merge_images from './assets/merge_images.svg';

export const mergeImagesPageService = (t: any) => {
  return {
    serviceType: EServiceType.MERGER,
    path: '/merge-img',
    uploadSectionImage: merge_images,
    mainColor: 'berry',
    isMergeImages: true,
    uploadSection: {
      from: [
        InternalFileType.HEIC,
        InternalFileType.SVG,
        InternalFileType.PNG,
        InternalFileType.BMP,
        InternalFileType.EPS,
        InternalFileType.GIF,
        InternalFileType.TIFF,
        InternalFileType.WEBP,
        InternalFileType.JPG,
        InternalFileType.JPEG,
        InternalFileType.PDF,
      ],
      to: InternalFileType.PDF,
      headline: t('landing.merge_images.h1.title'),
      subtitle_1: t('landing.merge_images.h1.subtitle'),
    },
    howTo: {
      headline_1: t('landing.merge_images.how_to.h2'),
      img: how_to_img,
      description: [
        {
          icon: icon_1,
          text: t('landing.merge_images.how_to.first'),
        },
        {
          icon: icon_2,
          text: t('landing.merge_images.how_to.second'),
        },
        {
          icon: icon_3,
          text: t('landing.merge_images.how_to.third'),
        },
      ],
    },
    title: t('meta.merge_images.title'),
    description: t('meta.merge_images.description'),
    name: t('meta.merge_images.title'),
    nameKey: t('meta.merge_images.title'),
    featureId: FEATURE_IDS.MERGE,
    fag: {
      question: 'How to organize multiple PDFs documents?',
      answer:
        '1. Upload PDF files you would like to be combined. 2. Arrange files in the desired order, delete unnecessary. 3. Download or share your merged PDF file.',
    },
    //from: 'Image',
    from: 'JPG',
    to: 'PDF',
    availableFormats: '.jpg, .jpeg, .png, .gif, .bmp, .webp',
  };
};
