export const getFileKeyFromAWSLink = (link: string): string => {
  // Use regular expression to extract the desired part between "amazonaws.com/" and "?"
  const pattern = /pdfguru.com\/(.*?)\?/
  const match = link.match(pattern)

  if (!match) {
    throw new Error('File key not found')
  }

  return match[1]
}