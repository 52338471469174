import { IFormService } from 'ts/interfaces/services/service';
import { formW9Service } from './forms/form-w9';
import { formDS11Service } from './forms/form-ds11';
import { formDS82Service } from './forms/form-ds82';
import { formW4Service } from './forms/form-w4';
import { formW3Service } from './forms/form-w3';
import { formI9Service } from './forms/form-i9';
import { form1099NecService } from './forms/form-1099-nec';
import { form1099Service } from './forms/form-1099';
import { form1099MiscService } from './forms/form-1099-misc';
import { form941Service } from './forms/form-941';
import { form1095AService } from './forms/form-1095-a';
import { formSS5Service } from './forms/form-ss-5';
import { formW2Service } from './forms/form-w2';
import { formI864Service } from './forms/form-i864';
import { form433DService } from './forms/form-433-d';
import { form1098tService } from './forms/form-1098-t';
import { form1065Service } from './forms/form-1065';
import { form1040Service } from './forms/form-1040';
import { form1040xService } from './forms/form-1040-x';
import { form255Service } from './forms/form-2553';
import { formCMS1500Service } from './forms/form-cms-1500';
import { form4506tService } from './forms/form-4506-t';


export const formServicesList = (t: any): IFormService[] => {
  return [
    formW9Service(t),
    formDS11Service(t),
    formDS82Service(t),
    formW4Service(t),
    formW3Service(t),
    formI9Service(t),
    form1099NecService(t),
    form1099Service(t),
    form1099MiscService(t),
    form941Service(t),
    form1095AService(t),
    formSS5Service(t),
    formW2Service(t),
    formI864Service(t),
    form433DService(t),
    form1098tService(t),
    form1065Service(t),
    form1040Service(t),
    form1040xService(t),
    form255Service(t),
    formCMS1500Service(t),
    form4506tService(t)
  ];
};
