import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PdfguruLogo } from 'components/ai-summarizer/ui/logo';

import { redirectToChoosePlan } from 'use-cases/ai-summarizer/redirect-to-choose-plan';

import { Analytics } from 'services/analytics';

import { toggleModal } from 'data/actions/modals';

import { EModalTypes } from 'ts/enums/modal.types';

import { userDataSelector } from '../../../../data/selectors/user';

export const FreeLimitReached = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(userDataSelector);

  const query: { file?: string; feature?: string } = {
    feature: 'ai-summarizer',
  };

  useEffect(() => {
    Analytics.sendEvent({
      event: 'pdf_summarizer_limit_exceeded',
    });
  }, []);

  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const fileParam = searchParams.get('file');

  if (fileParam) {
    query.file = fileParam;
  }

  const handleUpgrade = () => {
    Analytics.sendEvent({
      event: 'summarizer_upgrade_plan_tap',
    });

    if (!user?.email) {
      dispatch(toggleModal({ visible: true, type: EModalTypes.ENTER_EMAIL_AI_SUMMARIZER }));

      return;
    }

    redirectToChoosePlan(navigate);
  };

  return (
    <div className="flex flex-col gap-6 items-center mb-10">
      <div className="flex gap-4">
        <PdfguruLogo />

        <div className="py-3 px-6 bg-[#f447691a] rounded-[8px] text-[#D90A0A] text-base">
          {t('ai_summarizer.errors.error_limit_new_message')}
        </div>
      </div>

      <button
        onClick={() => handleUpgrade()}
        className="bg-[#192CD2] mt-4 inline-flex items-center justify-center gap-2 px-6 py-2 text-base font-bold text-[#fff] border-2 border-[#192CD2] rounded-[8px] transition-colors w-full small-desktop:max-w-[300px]"
      >
        <span>{t('ai_summarizer.upgrade')}</span>
      </button>
    </div>
  );
};
