import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModalLayout from 'components/modals/baseModal/base';
import { deleteDocumentById, setSelectedFiles } from 'data/actions/documents';
import { toggleModal } from 'data/actions/modals';
import useKeyPress from 'hooks/useKeyPress';
import { Analytics } from 'services/analytics';
import { SecondaryButton } from 'components/buttons/secondary-button';
import { PrimaryButton } from 'components/buttons/primary-button';
import s from './delete-file-popup.module.scss';
import { selectedFilesSelector } from 'data/selectors/documents';

const DeleteFilesModal: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedFiles = useSelector(selectedFilesSelector());

  useEffect(() => {
    Analytics.sendEvent({ event: 'delete_ﬁle_modal_view' });
  }, []);

  const handleDeleteFiles = () => {
    void Analytics.sendEvent({
      event: 'delete_all_files_confirm_tap',
      data: { place: 'my_dashboard' },
    });

    selectedFiles.forEach((fileId: string) => {
      dispatch(deleteDocumentById(fileId));
    });
    dispatch(setSelectedFiles([]));

    Analytics.sendEvent({
      event: 'select_all_files_delete_tap',
      data: {
        place: 'my_dashboard',
      },
    });
    dispatch(toggleModal({ visible: false }));
  };

  useKeyPress({ targetKey: 'Enter', onKeyPressDown: handleDeleteFiles });

  const handleClose = () => {
    dispatch(toggleModal({ visible: false }));
    Analytics.sendEvent({ event: 'delete_ﬁle_cancel_tap' });
  };

  return (
    <ModalLayout canClose>
      <div className={s.deleteDocumentPopup}>
        <h2>{t('popups.delete_file_popup.question_multiple', { count: selectedFiles.length })}</h2>
        <div className={s.buttons}>
          <SecondaryButton onClick={handleClose} className={s.button}>
            {t('popups.delete_file_popup.no')}
          </SecondaryButton>
          <PrimaryButton onClick={handleDeleteFiles} className={s.buttonSubmit}>
            {t('popups.delete_file_popup.yes')}
          </PrimaryButton>
        </div>
      </div>
    </ModalLayout>
  );
};

export default DeleteFilesModal;
