import { ApiFile, fromMimetype, InternalFileType } from 'ts/types/file';

export const isPdfOrImage = (file: any): boolean => {
  const fileType = fromMimetype(file.type);
  if (!fileType) return false;
  return fileType === InternalFileType.PDF || isImageType(fileType);
};

export const isImageType = (type: InternalFileType): boolean => {
  return [
    InternalFileType.JPEG,
    InternalFileType.JPG,
    InternalFileType.HEIC,
    InternalFileType.HEIF,
    InternalFileType.BMP,
    InternalFileType.EPS,
    InternalFileType.GIF,
    InternalFileType.SVG,
    InternalFileType.TIFF,
    InternalFileType.WEBP,
    InternalFileType.PNG,
    InternalFileType.IMAGE,
  ].includes(type);
};

export const areOnlyPdfAndImages = (files: ApiFile[]): boolean => {
  return files.every(isPdfOrImage);
};
