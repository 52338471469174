import apiEndpoints from 'use-cases/ai-summarizer/api/api';
import { buildThreadMessage } from 'use-cases/ai-summarizer/build-thread-message';

import {
  setCurrentFileUrl,
  setCurrentThreadId,
  setFileId,
  setFileName,
} from 'data/actions/aiSummarizer';

export const getDefaultChat = async (chats: any, dispatch: any) => {
  dispatch(setCurrentThreadId(chats[0]?.id));
  dispatch(setFileId(chats[0]?.fileId));
  dispatch(setCurrentFileUrl(chats[0]?.file_url));
  dispatch(setFileName(chats[0]?.chatName));

  try {
    const messages = await apiEndpoints.getAllMessages(chats[0]?.id);
    const threadId = messages.chatId;

    if (messages?.data) {
      const sortedMessages = messages.data.sort((a: any, b: any) => a.created_at - b.created_at);

      buildThreadMessage(sortedMessages, threadId, dispatch);
    }
  } catch (error) {
    console.error('Error fetching messages:', error);
  }
};
