import bgImgMob from 'assets/img/forms/background-2553-mob.webp';
//import bgImgTab from 'assets/img/forms/background-941-tab.webp';
import bgImgWeb from 'assets/img/forms/background-2553-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';


import form2553 from 'assets/forms/2553.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';

export const form255Service = (t: any): IFormService => {
  return {
    serviceType: EServiceType.EDITOR,
    pagesCount: 6,
    path: '/form-2553',
    filePath: form2553,
    fileName: '2553.pdf',
    from: 'PDF',
    to: 'PDF',
    meta: {
      title: t('meta.form_2553.title'),
      description: t('meta.form_2553.description'),
    },
    getForm: {
      headline1: 'IRS',
      headline2: 'Form',
      formName: '2553',
      subHeadline: '2017-2024 Printable Template',
      backgroundImageWeb: bgImgWeb,
      backgroundImageTab: bgImgWeb,
      backgroundImageMob: bgImgMob,
      buttonLabel: 'GET FORM',
    },
    easilyComplete: {
      headline: 'Elevate form filling with PDF Guru',
      description: [
        {
          icon: icon_1,
          //@ts-ignore
          iconMob: icon_mob_1,
          title: 'Access and fill out the form',
          text: 'Start filling out IRS Form 2553 easily on PDF Guru. Our intuitive PDF editor lets you complete the form seamlessly.',
        },
        {
          icon: icon_2,
          //@ts-ignore
          iconMob: icon_mob_2,
          title: 'Review and customize',
          text: "Use PDF Guru's tools to add text and ensure all information is accurate and complete. Remember, digital signatures are not permitted by the IRS, so leave signature fields blank for manual signing.",
        },
        {
          icon: icon_3,
          //@ts-ignore
          iconMob: icon_mob_3,
          title: 'Download and use',
          text: 'Securely download, print, and sign your completed Form 2553 with a pen. File and save it for future use, maintaining a personal copy in standard PDF format.',
        },
      ],
    },
    whatIsForm: {
      headline: 'What is form 2553?',
      text_1: [],
      text_2: [
        'IRS Form 2553 allows corporations to elect S corporation status for tax purposes, impacting taxation and potential benefits. The S Corp Form 2553 is a strategic business decision with significant financial implications. This election means profits, losses, deductions, and credits flow through to shareholders, potentially resulting in tax advantages under certain conditions.',
        'By electing S corporation status, businesses can avoid double taxation on the corporate income and ensure that shareholders are taxed only on their personal income. This setup is particularly advantageous for small businesses that meet the eligibility criteria, as it can lead to significant tax savings and a more streamlined tax reporting process.',
      ],
      text_3: [
        'To file Form 2553, you have two options. For online submission, visit the ElectSCorp website. Here, you can submit your manually signed form digitally, offering a convenient option for those eligible to use this service. Alternatively, if online submission through ElectSCorp is not feasible, you can file Form 2553 via mail or fax. To do this, check the latest Form 2553 Fax Number and mailing addresses on the Internal Revenue Service website.',
        "It's vital to ensure accurate submission, whether through ElectSCorp or via mail/fax, to avoid any delays in processing your S corporation status. Remember, business owners must manually sign IRS Form 2553, as digital signatures are not accepted by the IRS.",
      ],
      text_4: [],
      title_1: 'What is IRS Form 2553 used for?',
      title_2: 'Where to file form 2553?',
      subTitle: '',
      //@ts-ignore
      sectionImage: section_img,
      //@ts-ignore
      sectionImageTab: section_img_tab,
      description: [
        'Form 2553, also known as Election by a Small Business Corporation, is essential for businesses seeking S corporation status. This status allows a small business to be taxed similarly to a partnership or sole proprietorship, rather than like a regular C corporation. This means the business profits and losses are passed through to the personal tax returns of the shareholders, instead of being taxed at the corporate level.',
        'So, S corporation shareholders can save money overall on federal taxes compared to a regular corporation. Filing IRS Form 2553 streamlines the process of income tax for small Limited Liability Companies (LLC).',
        'Understanding how to fill out and file the IRS Form 2553 is crucial for strategic tax decisions and benefits. It\'s the gateway for small businesses to be taxed under Subchapter S of the Internal Revenue Code, which can offer more favorable tax conditions compared to other corporate tax structures.',
      ],            
      bullets: [], 
      orangeList: [],           
    },
    howToFill: {
      headline: 'How to fill out form 2553?',
      //@ts-ignore
      sectionImage: howToFillImage,
      bullets: [
        {
          icon: blue_number_icon_1,
          strong: 'Start on PDF Guru:',
          text: ' Access our platform and open the blank Form 2553 (Election by a Small Business Corporation).',
        },
        {
          icon: blue_number_icon_2,
          strong: 'Enter corporation details:',
          text: ' Fill in your corporation\'s name, address, Employer Identification Number (EIN), and the tax year for the S corporation election.',
        },
        {
          icon: blue_number_icon_3,
          strong: 'Complete shareholder consent:',
          text: ' Ensure every shareholder\'s name, address, and shares are listed, with their signatures and the dates they acquired the shares.',
        },
        {
          icon: blue_number_icon_4,
          strong: 'Review and download:',
          text: ' Carefully check all details for accuracy. Download and print the form, as digital signatures are not accepted by the IRS.',
        },
        {
          icon: blue_number_icon_5,
          strong: 'Manual signing:',
          text: ' Have all required parties, including shareholders, manually sign the printed form.',
        },
        {
          icon: blue_number_icon_6,
          strong: 'File the form:',
          text: ' Either submit the signed form online via ElectSCorp or send it to the IRS by mail or fax, using the current fax number and mailing address from the IRS website.',
        },
      ],
    },
    differenceBetween2: {
      headline: 'When to file form 2553?',
      text: 'Submit IRS Form 2553 within two months and 15 days after the start of the tax year for which the election is to take effect. The Internal Revenue Service may accept late elections under specific criteria outlined in their instructions. Understanding these nuances is key for a successful S corporation election.',
    },
    fillFormFree: {
      headline: 'Simplify Your S Corp Election',
      subtitle: 'Accurate and easy-to-use',
      buttonLabel: 'Fill Form',
    },
    bannerQuickNEasy: {
      headline: 'Form 2553 Filing Made Easy',
      subtitle: 'The smart way to file',
      format: InternalFileType.PDF,
      buttonLabel: 'Fill Form',
    },
    faqSection: {
      headline: 'Frequently asked questions',
      faqSectionDescription: [
        {
          question: 'Where to fax Form 2553?',
          answer: [
            'You can fax Form 2553 to the IRS at the designated number found on their website. Ensure you have the current Form 2553 Fax Number for accurate submission. The IRS frequently updates their contact information, so verifying the fax number before sending your form is a critical step in ensuring its successful delivery.',
          ],
        },
        {
          question: 'How to file Form 2553 online?',
          answer: [
            'Online submission of Form 2553 is possible exclusively through the ElectSCorp website. Prepare the form using PDF Guru, manually sign it, and then submit it via ElectSCorp.',
          ],
        },
        {
          question: 'How do I know if my Form 2553 was approved?',
          answer: [
            'You will receive a confirmation letter from the IRS after submitting Form 2553. If you don\'t receive it within 60 days, contact the IRS for an update. This letter is crucial as it is the official acknowledgment of your S corporation status.',
          ],
        },
        {
          question: 'How long does it take to process Form 2553?',
          answer: [
            'The IRS typically processes Form 2553 and sets the effective date within 60 days. During busy periods, this timeframe may be longer, so plan your submission accordingly.',
          ],
        },
        {
          question: 'Can I amend Form 2553 after submission?',
          answer: [
            'Yes, amendments to Form 2553 are possible. If you find errors, submit an amended form promptly to correct them. The process for amending the form is similar to the initial filing but should clearly indicate that it\'s an amended submission. This ensures that the IRS is aware of the changes and can re-evaluate your S corporation status accordingly.',
          ],
        },
      ],
    },                 
    fag: {
      question: 'How to fill out Form 1065?',
      answer:
        "1. Start on PDF Guru: Access our platform and open the blank Form 2553 (Election by a Small Business Corporation). 2. Enter corporation details: Fill in your corporation's name, address, Employer Identification Number (EIN), and the tax year for the S corporation election.  3. Complete shareholder consent: Ensure every shareholder's name, address, and shares are listed, with their signatures and the dates they acquired the shares. 4. Review and download: Carefully check all details for accuracy. Download and print the form, as digital signatures are not accepted by the IRS. 5. Manual signing: Have all required parties, including shareholders, manually sign the printed form. 6. File the form: Either submit the signed form online via ElectSCorp or send it to the IRS by mail or fax, using the current fax number and mailing address from the IRS website.",
    },
  };
}