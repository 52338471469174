import { isEmailValid } from 'utils/validation';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { createRef, FormEventHandler, useEffect, useState } from 'react';

export interface EmailInputResult {
  email: string;
  valid: boolean;
}

interface IProps {
  onInput: (result: EmailInputResult) => void;
  validate?: boolean;
  autofocus?: boolean;
  clear?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
}
export const EmailInput: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const inputRef = props.inputRef ? props.inputRef : createRef<HTMLInputElement>();
  const { onInput, validate = false, autofocus = false, clear = false } = props;
  const [value, setValue] = useState('');
  const [startInput, setStartInput] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const onEmailInput: FormEventHandler<HTMLInputElement> = (e) => {
    if (!startInput) {
      setStartInput(true);
    }

    setValue(e.currentTarget.value);
  };

  useEffect(() => {
    const valid = isEmailValid(value);
    const maxLength = value.length <= 50;
    const isInputValid = valid && maxLength;

    setIsValid(isInputValid);
    onInput({ email: value, valid: isInputValid });
  }, [onInput, value]);

  useEffect(() => {
    if (value.length > 50) {
      setError(t('input_email.max_length_error'));
      return;
    }

    if (value.length > 0 && !isEmailValid(value)) {
      setError(t('input_email.validation_error'));
      return;
    }

    setError(null);
  }, [t, value]);

  useEffect(() => {
    if (clear) {
      setValue('');
      setIsValid(false);
      setStartInput(false);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [clear, inputRef]);

  const classes = classNames({
    input: true,
    valid: startInput && isValid && validate,
    'no-valid': startInput && !isValid,
  });

  return (
    <div className={classes}>
      {error !== null && <div className="error">{error}</div>}
      <input
        ref={inputRef}
        id="email"
        type="email"
        inputMode="email"
        autoComplete="email"
        placeholder={t('global.enter_email')}
        onInput={onEmailInput}
        autoFocus={autofocus}
      />
      <label htmlFor="email">{t('global.email')}</label>
    </div>
  );
};
