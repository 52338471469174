import { PDFDocument } from 'pdf-lib';

export const countPdfPages = async (file: File): Promise<number> => {
  return new Promise(async (resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          const pdfDoc = await PDFDocument.load(e.target?.result as ArrayBuffer, {
            ignoreEncryption: true,
          });
          resolve(pdfDoc.getPageCount()); // Return the page count
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    } catch (error) {
      reject(error);
    }
  });
};
