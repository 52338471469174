import { IconButton } from 'components/buttons/icon-button-new-design';
import { FullwidthDropdown } from 'components/dropdown/fullwidth-dropdown';
import { DropdownTrigger } from 'components/dropdown/trigger';
import LocalizedLink from 'components/localized-link';
import { featureFlags } from 'feature-flags';
import { InternalFileType } from 'ts/types/file';
import { DropDownList } from 'utils/links';
import { useTranslation } from 'react-i18next';
import React from 'react';

import icon_black_hover from 'assets/img/icons/24px/tools-black-hover.svg';
import icon_black from 'assets/img/icons/24px/tools-black.svg';
import { IHeaderUploadSectionInteractor } from '../../interactor';
import s from './nav.module.scss';
import classNames from 'classnames';
import { useIsRtl } from 'hooks/isRtl';

interface IProps {
  interactor: IHeaderUploadSectionInteractor;
}

export const HeaderNav: React.FC<IProps> = ({ interactor }) => {
  const { t } = useTranslation();

  const renderList = React.useCallback(
    (list: DropDownList) => {
      return list.links.map((link, index) => {
        return (
          <LocalizedLink href={link.href} key={index} className={s.item}>
            <IconButton
              icon={link.icon}
              onClick={() => {
                interactor.convertDropDown.onClickHeaderFeature(
                  link.from,
                  link.to || InternalFileType.PDF,
                  link.href.slice(1)
                );

                interactor?.convertDropDown?.toggle();
              }}
            >
              {link.label}
            </IconButton>
          </LocalizedLink>
        );
      });
    },
    [interactor.convertDropDown]
  );

  return (
    <>
      <div className={classNames(s.dropDownTrigger, { [s.dropDownTriggerRtl]: useIsRtl() })}>
        <DropdownTrigger
          icon={icon_black}
          hoverIcon={icon_black_hover}
          label={t('header.dropdown.label')}
          isActive={interactor.convertDropDown.isOpen}
          onTrigger={interactor.convertDropDown.toggle}
          triggerRef={interactor.convertDropDown.triggerRef}
        />
      </div>
      <FullwidthDropdown
        isVisible={interactor.convertDropDown.isOpen}
        onClickOutside={interactor.convertDropDown.toggle}
        triggerRef={interactor.convertDropDown.triggerRef}
      >
        <div className={s.convertDropdown}>
          <div className="flex w-full max-w-[1140px] justify-between">
            <div className={s.list}>
              <span>{interactor.links.tools.label}</span>
              {renderList(interactor.links.tools)}
            </div>
            {featureFlags.featureLandings && (
              <div className={s.list}>
                <span>{interactor.links.mergeSplit.label}</span>
                {renderList(interactor.links.mergeSplit)}
              </div>
            )}
            <div className={s.list}>
              <span>{interactor.convertDropDown.from.label}</span>
              <div>{renderList(interactor.convertDropDown.from)}</div>
            </div>
            <div className={s.list}>
              <span>{interactor.convertDropDown.to.label}</span>
              <div>{renderList(interactor.convertDropDown.to)}</div>
            </div>
            {featureFlags.featureLandings && (
              <div className={s.list}>
                <span>{interactor.links.security.label}</span>
                {renderList(interactor.links.security)}
              </div>
            )}
            <div className={s.list}>
              <span>{interactor.links.forms.label}</span>
              <div>{renderList(interactor.links.forms)}</div>
            </div>
          </div>
        </div>
      </FullwidthDropdown>
    </>
  );
};
