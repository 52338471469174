import { TFunction } from 'i18next';
import secure from './assets/3d-secure.svg';
import error_processing from './assets/error-processing.svg';
import incorrect_cvv from './assets/incorrect-cvv-code.svg';
import insufficient from './assets/insufficient.svg';
import invalid_expiration_date from './assets/invalid-expiration-date.svg';
import no_support from './assets/no-support-card.svg';
import wrong_number from './assets/wrong-card-number.svg';

import { CardPaymentError } from 'ts/enums/paymentForm';

export const renderIconError = (code: CardPaymentError) => {
  switch (code) {
    case CardPaymentError.SUSPECTED_FRAUD:
    case CardPaymentError.DO_NOT_HONOR:
    case CardPaymentError.DECLINED_BY_ISSUER:
    case CardPaymentError.CARD_IS_BLOCKED:
    case CardPaymentError.GENERAL_DECLINE:
    case CardPaymentError.ANTIFRAUD_SERVICE:
    case CardPaymentError.INVALID_TRANSACTION:
    case CardPaymentError.STOLEN_CARD:
    case CardPaymentError.UNKNOWN_DECLINE_CODE:
    case CardPaymentError.ORDER_EXPIRED:
    case CardPaymentError.ILLEGAL_OPERATION:
    case CardPaymentError.AUTHENTICATION_FAILED:
    case CardPaymentError.INVALID_DATA:
    case CardPaymentError.INVALID_AMOUNT:
    case CardPaymentError.INVALID_CURRENCY:
    case CardPaymentError.ORDER_NOT_FOUND:
    case CardPaymentError.REQUEST_IS_EMPTY:
    case CardPaymentError.INVALID_THREE_DS_FLOW_MERCHANT_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW_BANK_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW:
    case CardPaymentError.INVALID_IP:
    case CardPaymentError.SUBSCRIPTION_ERROR:
    case CardPaymentError.SCA_REQUIRE_THREE_D_AUTHENTICATION:
    case CardPaymentError.SUBSCRIPTION_IS_LOCKED:
    case CardPaymentError.PAYMENT_AMOUNT_LIMIT_EXCESS:
    case CardPaymentError.DECLINED_BY_THE_ISSUER:
    case CardPaymentError.CALL_YOUR_BANK:
    case CardPaymentError.DEBIT_CARD_NOT_SUPPORTED:
    case CardPaymentError.THREE_D_SECURE_AUTH_REQUIRED:
    case CardPaymentError.RECURRING_PAYMENT_CANCELLED:
    case CardPaymentError.CARD_IS_IN_A_BANK_LIST:
    case CardPaymentError.LOST_CARD:
    case CardPaymentError.PSP_FRAUD:
    case CardPaymentError.BLOCKED_BY_COUNTRY_IP:
    case CardPaymentError.TRUSTED_ANTIFRAUD_SYSTEM:
    case CardPaymentError.AVS_MISMATCH:
    case CardPaymentError.INVALID_CARD_TOKEN:
    case CardPaymentError.APPLICATION_ERROR:
    case CardPaymentError.MERCH_IS_NOT_CONFIG_CORRECTLY:
    case CardPaymentError.MERCH_IS_NOT_ACTIVATED_YET:
    case CardPaymentError.DUPLICATE_ORDER:
    case CardPaymentError.EXCEEDED_API_CALLS_LIMITS:
    case CardPaymentError.MERCH_NOT_FOUND:
    case CardPaymentError.PROCESSOR_NOT_SUPPORT_API:
    case CardPaymentError.INVALID_ROUTING:
    case CardPaymentError.ACCOUNT_IS_BLOCKED:
    case CardPaymentError.CONNECTION_ERROR:
    case CardPaymentError.CARD_TOKEN_NOT_FOUND:
    case CardPaymentError.GOOGLE_PAYMENT_ERROR:
    case CardPaymentError.SMART_CASCADE_DECLINE:
    case CardPaymentError.THREE_D_CASCADE_TO_TWO_D:
    case CardPaymentError.APPLE_ONLINE_PAYMENT_ERROR:
    case CardPaymentError.TOKEN_GENERATION_ERROR:
    case CardPaymentError.SCA_ENGINE_ERROR:
      return (
        <img src={error_processing} alt="error_processing" className="w-[96px] tablet:w-[120px]" />
      );
    case CardPaymentError.THREE_D_SECURE:
      return <img src={secure} alt="secure" className="w-[96px] tablet:w-[120px]" />;
    case CardPaymentError.INSUFFICIENT_FUNDS:
      return <img src={insufficient} alt="insufficient" className="w-[96px] tablet:w-[120px]" />;
    case CardPaymentError.CARD_BRAND_NOT_SUPPORTED:
    case CardPaymentError.RESTRICTED_CARD:
      return <img src={no_support} alt="no_support" className="w-[96px] tablet:w-[120px]" />;
    case CardPaymentError.INVALID_CVV_CODE:
      return <img src={incorrect_cvv} alt="incorrect_cvv" className="w-[96px] tablet:w-[120px]" />;
    case CardPaymentError.INVALID_CARD_NUMBER:
      return <img src={wrong_number} alt="wrong_number" className="w-[96px] tablet:w-[120px]" />;
    case CardPaymentError.INVALID_EXPIRATION_DATE:
      return (
        <img
          src={invalid_expiration_date}
          alt="invalid_expiration_date"
          className="w-[96px] tablet:w-[120px]"
        />
      );
    default:
      return (
        <img src={error_processing} alt="error_processing" className="w-[96px] tablet:w-[120px]" />
      );
  }
};

export const renderTitleError = (code: CardPaymentError, t: TFunction) => {
  switch (code) {
    case CardPaymentError.SUSPECTED_FRAUD:
    case CardPaymentError.DO_NOT_HONOR:
    case CardPaymentError.DECLINED_BY_ISSUER:
    case CardPaymentError.CARD_IS_BLOCKED:
    case CardPaymentError.GENERAL_DECLINE:
    case CardPaymentError.ANTIFRAUD_SERVICE:
    case CardPaymentError.INVALID_TRANSACTION:
    case CardPaymentError.STOLEN_CARD:
    case CardPaymentError.UNKNOWN_DECLINE_CODE:
    case CardPaymentError.ORDER_EXPIRED:
    case CardPaymentError.ILLEGAL_OPERATION:
    case CardPaymentError.AUTHENTICATION_FAILED:
    case CardPaymentError.INVALID_DATA:
    case CardPaymentError.INVALID_AMOUNT:
    case CardPaymentError.INVALID_CURRENCY:
    case CardPaymentError.ORDER_NOT_FOUND:
    case CardPaymentError.REQUEST_IS_EMPTY:
    case CardPaymentError.INVALID_THREE_DS_FLOW_MERCHANT_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW_BANK_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW:
    case CardPaymentError.INVALID_IP:
    case CardPaymentError.SUBSCRIPTION_ERROR:
    case CardPaymentError.SCA_REQUIRE_THREE_D_AUTHENTICATION:
    case CardPaymentError.SUBSCRIPTION_IS_LOCKED:
    case CardPaymentError.PAYMENT_AMOUNT_LIMIT_EXCESS:
    case CardPaymentError.DECLINED_BY_THE_ISSUER:
    case CardPaymentError.CALL_YOUR_BANK:
    case CardPaymentError.DEBIT_CARD_NOT_SUPPORTED:
    case CardPaymentError.THREE_D_SECURE_AUTH_REQUIRED:
    case CardPaymentError.RECURRING_PAYMENT_CANCELLED:
    case CardPaymentError.CARD_IS_IN_A_BANK_LIST:
    case CardPaymentError.LOST_CARD:
    case CardPaymentError.PSP_FRAUD:
    case CardPaymentError.BLOCKED_BY_COUNTRY_IP:
    case CardPaymentError.TRUSTED_ANTIFRAUD_SYSTEM:
    case CardPaymentError.AVS_MISMATCH:
    case CardPaymentError.INVALID_CARD_TOKEN:
    case CardPaymentError.APPLICATION_ERROR:
    case CardPaymentError.MERCH_IS_NOT_CONFIG_CORRECTLY:
    case CardPaymentError.MERCH_IS_NOT_ACTIVATED_YET:
    case CardPaymentError.DUPLICATE_ORDER:
    case CardPaymentError.EXCEEDED_API_CALLS_LIMITS:
    case CardPaymentError.MERCH_NOT_FOUND:
    case CardPaymentError.PROCESSOR_NOT_SUPPORT_API:
    case CardPaymentError.INVALID_ROUTING:
    case CardPaymentError.ACCOUNT_IS_BLOCKED:
    case CardPaymentError.CONNECTION_ERROR:
    case CardPaymentError.CARD_TOKEN_NOT_FOUND:
    case CardPaymentError.GOOGLE_PAYMENT_ERROR:
    case CardPaymentError.SMART_CASCADE_DECLINE:
    case CardPaymentError.THREE_D_CASCADE_TO_TWO_D:
    case CardPaymentError.APPLE_ONLINE_PAYMENT_ERROR:
    case CardPaymentError.TOKEN_GENERATION_ERROR:
    case CardPaymentError.SCA_ENGINE_ERROR:
      return <h3>{t('card_error_popup.error_processing_order.title')}</h3>;
    case CardPaymentError.THREE_D_SECURE:
      return <h3>{t('card_error_popup.3d_secure.title')}</h3>;
    case CardPaymentError.INSUFFICIENT_FUNDS:
      return <h3>{t('card_error_popup.insufficient_funds_for_payment.title')}</h3>;
    case CardPaymentError.CARD_BRAND_NOT_SUPPORTED:
      return <h3>{t('card_error_popup.card_brand_not_supported.title')}</h3>;
    case CardPaymentError.RESTRICTED_CARD:
      return <h3>{t('card_error_popup.restricted_card.title')}</h3>;
    case CardPaymentError.INVALID_CVV_CODE:
      return <h3>{t('card_error_popup.incorrect_cvv_code.title')}</h3>;
    case CardPaymentError.INVALID_CARD_NUMBER:
      return <h3>{t('card_error_popup.wrong_card_number.title')}</h3>;
    case CardPaymentError.INVALID_EXPIRATION_DATE:
      return <h3>{t('card_error_popup.incorrect_expiration_date.title')}</h3>;
    default:
      return <h3>{t('card_error_popup.error_processing_order.title')}</h3>;
  }
};

export const renderTextError = (code: CardPaymentError, t: TFunction) => {
  switch (code) {
    case CardPaymentError.SUSPECTED_FRAUD:
    case CardPaymentError.DO_NOT_HONOR:
    case CardPaymentError.DECLINED_BY_ISSUER:
    case CardPaymentError.CARD_IS_BLOCKED:
    case CardPaymentError.GENERAL_DECLINE:
    case CardPaymentError.ANTIFRAUD_SERVICE:
    case CardPaymentError.INVALID_TRANSACTION:
    case CardPaymentError.STOLEN_CARD:
    case CardPaymentError.UNKNOWN_DECLINE_CODE:
    case CardPaymentError.ORDER_EXPIRED:
    case CardPaymentError.ILLEGAL_OPERATION:
    case CardPaymentError.AUTHENTICATION_FAILED:
    case CardPaymentError.INVALID_DATA:
    case CardPaymentError.INVALID_AMOUNT:
    case CardPaymentError.INVALID_CURRENCY:
    case CardPaymentError.ORDER_NOT_FOUND:
    case CardPaymentError.REQUEST_IS_EMPTY:
    case CardPaymentError.INVALID_THREE_DS_FLOW_MERCHANT_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW_BANK_SIDE:
    case CardPaymentError.INVALID_THREE_DS_FLOW:
    case CardPaymentError.INVALID_IP:
    case CardPaymentError.SUBSCRIPTION_ERROR:
    case CardPaymentError.SCA_REQUIRE_THREE_D_AUTHENTICATION:
    case CardPaymentError.SUBSCRIPTION_IS_LOCKED:
    case CardPaymentError.PAYMENT_AMOUNT_LIMIT_EXCESS:
    case CardPaymentError.DECLINED_BY_THE_ISSUER:
    case CardPaymentError.CALL_YOUR_BANK:
    case CardPaymentError.DEBIT_CARD_NOT_SUPPORTED:
    case CardPaymentError.THREE_D_SECURE_AUTH_REQUIRED:
    case CardPaymentError.RECURRING_PAYMENT_CANCELLED:
    case CardPaymentError.CARD_IS_IN_A_BANK_LIST:
    case CardPaymentError.LOST_CARD:
    case CardPaymentError.PSP_FRAUD:
    case CardPaymentError.BLOCKED_BY_COUNTRY_IP:
    case CardPaymentError.TRUSTED_ANTIFRAUD_SYSTEM:
    case CardPaymentError.AVS_MISMATCH:
    case CardPaymentError.INVALID_CARD_TOKEN:
    case CardPaymentError.APPLICATION_ERROR:
    case CardPaymentError.MERCH_IS_NOT_CONFIG_CORRECTLY:
    case CardPaymentError.MERCH_IS_NOT_ACTIVATED_YET:
    case CardPaymentError.DUPLICATE_ORDER:
    case CardPaymentError.EXCEEDED_API_CALLS_LIMITS:
    case CardPaymentError.MERCH_NOT_FOUND:
    case CardPaymentError.PROCESSOR_NOT_SUPPORT_API:
    case CardPaymentError.INVALID_ROUTING:
    case CardPaymentError.ACCOUNT_IS_BLOCKED:
    case CardPaymentError.CONNECTION_ERROR:
    case CardPaymentError.CARD_TOKEN_NOT_FOUND:
    case CardPaymentError.GOOGLE_PAYMENT_ERROR:
    case CardPaymentError.SMART_CASCADE_DECLINE:
    case CardPaymentError.THREE_D_CASCADE_TO_TWO_D:
    case CardPaymentError.APPLE_ONLINE_PAYMENT_ERROR:
    case CardPaymentError.TOKEN_GENERATION_ERROR:
    case CardPaymentError.SCA_ENGINE_ERROR:
      return <p>{t('card_error_popup.error_processing_order.text')}</p>;
    case CardPaymentError.THREE_D_SECURE:
      return <p>{t('card_error_popup.3d_secure.text')}</p>;
    case CardPaymentError.INSUFFICIENT_FUNDS:
      return <p>{t('card_error_popup.insufficient_funds_for_payment.text')}</p>;
    case CardPaymentError.CARD_BRAND_NOT_SUPPORTED:
      return <p>{t('card_error_popup.card_brand_not_supported.text')}</p>;
    case CardPaymentError.RESTRICTED_CARD:
      return <p>{t('card_error_popup.restricted_card.text')}</p>;
    case CardPaymentError.INVALID_CVV_CODE:
      return <p>{t('card_error_popup.incorrect_cvv_code.text')}</p>;
    case CardPaymentError.INVALID_CARD_NUMBER:
      return <p>{t('card_error_popup.wrong_card_number.text')}</p>;
    case CardPaymentError.INVALID_EXPIRATION_DATE:
      return <p>{t('card_error_popup.incorrect_expiration_date.text')}</p>;
    default:
      return <p>{t('card_error_popup.error_processing_order.text')}</p>;
  }
};
