import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DEFAULT_LANGUAGE, languages, SupportedLanguage } from '../../i18n';

interface PathLocaleProviderProps {
  children: ReactNode;
}

export const PathLocaleProvider = ({ children }: PathLocaleProviderProps) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const url = new URL(window.location.href);
    // Check if language segment is in the path
    const pathSegments = url.pathname.split('/');
    const langIndex = pathSegments.findIndex((segment) =>
      languages.includes(segment as SupportedLanguage)
    );

    i18n.changeLanguage(langIndex !== -1 ? pathSegments[langIndex] : DEFAULT_LANGUAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <>{children}</>;
};
