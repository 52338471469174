import bgImgMob from 'assets/img/forms/background-ds-11-mob.webp';
import bgImgTab from 'assets/img/forms/background-ds-11-tab.webp';
import bgImgWeb from 'assets/img/forms/background-ds-11-web.webp';
import icon_1 from 'assets/img/forms/how-it-works-image-1.svg';
import icon_2 from 'assets/img/forms/how-it-works-image-2.svg';
import icon_3 from 'assets/img/forms/how-it-works-image-3.svg';
import icon_mob_1 from 'assets/img/forms/how-it-works-image-mobile-1.webp';
import icon_mob_2 from 'assets/img/forms/how-it-works-image-mobile-2.webp';
import icon_mob_3 from 'assets/img/forms/how-it-works-image-mobile-3.webp';
import section_img_tab from 'assets/img/forms/how-to-easily-tablet-w-9.webp';
import section_img from 'assets/img/forms/how-to-easily-w-9.webp';
import howToFillImage from 'assets/img/forms/how-to-fill-section-image.webp';
import howToImage from 'assets/img/forms/how-to-ds11.png'
import backgroundNextStepsMob from 'assets/img/forms/next-steps-mob.png';
import backgroundNextStepsTab from 'assets/img/forms/next-steps-tab.png';
import backgroundNextStepsWeb from 'assets/img/forms/next-steps-web.png';
import blue_number_icon_1 from 'assets/img/forms/1-step-blue-icon.svg';
import blue_number_icon_2 from 'assets/img/forms/2-step-blue-icon.svg';
import blue_number_icon_3 from 'assets/img/forms/3-step-blue-icon.svg';
import blue_number_icon_4 from 'assets/img/forms/4-step-blue-icon.svg';
import blue_number_icon_5 from 'assets/img/forms/5-step-blue-icon.svg';
import blue_number_icon_6 from 'assets/img/forms/6-step-blue-icon.svg';

import formDS11 from 'assets/forms/ds11.pdf';
import { EServiceType, IFormService } from 'ts/interfaces/services/service';
import { InternalFileType } from 'ts/types/file';


export const formDS11Service = (t: any): IFormService => {
  return {
      serviceType: EServiceType.EDITOR,
      pagesCount: 6,
      path: '/form-ds11',
      filePath: formDS11,
      fileName: 'ds11.pdf',
      from: 'PDF',
      to: 'PDF',
      meta: {
        title: t('meta.form_ds11.title'),
        description: t('meta.form_ds11.description'),
      },
      getForm: {
        headline1: '',
        headline2: 'Form',
        formName: 'DS-11',
        subHeadline: '2022-2024 Printable Template',
        backgroundImageWeb: bgImgWeb,
        backgroundImageTab: bgImgTab,
        backgroundImageMob: bgImgMob,
        buttonLabel: 'GET FORM',
      },
      easilyComplete: {
        headline: 'PDF Guru makes passport application simple',
        description: [
          {
            icon: icon_1,
            iconMob: icon_mob_1,
            title: 'Fill out your form',
            text: 'Use our DS-11 form filler to add your personal and contact information, as well as other details in the required fields.',
          },
          {
            icon: icon_2,
            iconMob: icon_mob_2,
            title: 'Print it out',
            text: 'Download your completed US passport application form as a PDF file and print it out either in color or black and white.',
          },
          {
            icon: icon_3,
            iconMob: icon_mob_3,
            title: 'Submit the form',
            text: 'Visit a passport acceptance facility and present your completed DS-11 form, supporting documents, and payment before signing the document.',
          },
        ],
      },
      whatIsForm: {
        headline: 'What is form DS-11 and why do you need it?',
        text_1: [
          "You can use our PDF maker to add all these details with ease. We've made the DS-11 passport form fillable for your convenience.",
          'After completing the document, you must personally submit it, along with the necessary documents, at an authorized passport acceptance facility, such as a post office or a passport agency.',
        ],
        text_2: [],
        text_3: [],
        text_4: [],
        title_1:
          'Here are several cases in which you may be required to submit the DS-11 form:',
        title_2: '',
        subTitle: '',
        sectionImage: section_img,
        sectionImageTab: section_img_tab,
        description: [
          "The Form DS-11: Application for a U.S. Passport is required for all first-time passport applicants, for those who are under the age of 16, or for those who were under the age of 16 when they got their previous passport. DS-11 contains all the information required to initiate your application process, including your name, date of birth, social security number, and contact details."
        ],
        bullets: [
          {
            strong: "You're a first-time applicant.",
            text: " If you have never had a US passport before, the DS-11 form is the starting point for your application process."
          },
          {
            strong: "You're a minor.",
            text: " Children under age 16 are also required to submit a new passport application form. Parental consent is mandatory for minors, so this form includes sections for parents or guardians to provide their details and consent."
          },
          {
            strong: "Your passport has expired or been lost.",
            text: " If your current passport was issued when you were under the age of 16, or if it is expired, lost, or damaged, you are required to fill out the DS-11 passport application form to apply for a new one."
          },
          {
            strong: "You have changed your name.",
            text: " If your first or last name has legally changed since your previous passport was issued, you will need to request a new one by completing and submitting the DS-11 form to update your personal information."
          },
        ],
      },
      howToFill: {
        headline: 'How to edit a DS 11 form',
        //@ts-ignore
        sectionImage: howToFillImage,
        bullets: [
          {
            icon: blue_number_icon_1,
            text: 'Open our DS-11 form filler and use it to edit PDF',
          },
          {
            icon: blue_number_icon_2,
            text: 'Enter your personal and contact information in the fillable form DS-11',
          },
          {
            icon: blue_number_icon_3,
            text: 'Add optional details such as your emergency contact information',
          },
          {
            icon: blue_number_icon_4,
            text: 'Add optional details such as your emergency contact information',
          },
          {
            icon: blue_number_icon_5,
            text: 'Enter your travel plans and purpose for needing a U.S. passport',
          },
          {
            icon: blue_number_icon_6,
            text: 'Provide parent or guardian information if applying for a minor',
          },
        ],
      },
      fillFormFree: {
        headline: 'Fill out DS-11 Form in Seconds',
        subtitle: 'It has never been easier or faster',
        buttonLabel: 'Fill Form',
      },
      differenceBetween: {
        headline: 'Difference between DS-11 and DS-82 passport forms',
        text: 'The DS-11 and DS-82 forms are both used to apply for a US passport, but they are used in different circumstances. The DS-11 form is used for the cases listed above. On the other hand, the DS-82 form is used for passport renewal for those whose current passport was issued after they turned 16, is undamaged, and was issued within the last 15 years. Therefore, the primary difference between the two is that the US passport application form DS-11 is for first-time applicants or those who need to apply for a new passport due to various reasons, while the DS-82 is used for a renewal application.',
      },
      nextSteps: {
        headline: 'Next steps: Printing and submitting your DS-11 form',
        backgroundNextStepsWeb: backgroundNextStepsWeb,
        backgroundNextStepsTab: backgroundNextStepsTab,
        backgroundNextStepsMob: backgroundNextStepsMob,
        texts: [
          "Once you've filled out the DS-11 form online, download and print it on plain white paper, single-sided, using either black or color ink. You can personally glue or staple a 2x2 inch photo to the form or have it taken at a passport acceptance facility. It is recommended to make an appointment there in advance to ensure a smooth and efficient process.",
          "Finally, gather supporting documents such as proof of U.S. citizenship and proof of identity, and bring them with your passport form DS-11 to an acceptance facility. There, you'll sign and submit the form under the guidance of an authorized agent.",
        ],
      },
      howTo: {
        headline: 'How to fill out a DS 11 form online',
        sectionImage: howToImage,
        bullets: [
          {
            icon: blue_number_icon_1,
            text: "Click the Fill Form button under this section",
          },
          {
            icon: blue_number_icon_2,
            text: "In the Editor window that opens, add all the necessary details",
          },
          {
            icon: blue_number_icon_3,
            text: "Do not complete or sign specific sections of the DS-11 form that require in-person actions or signature",
          },
          {
            icon: blue_number_icon_4,
            text: "Download your DS-11 passport form as a PDF",
          },
        ],
      },
      bannerQuickNEasy: {
        headline: 'The Quickest Way to Fill Out a DS-11',
        subtitle: 'Create a passport application form now!',
        format: InternalFileType.PDF,
        buttonLabel: 'Fill Form',
      },
      faqSection: {
        headline: 'Frequently asked questions',
        faqSectionDescription: [
          {
            question: "Where can I get a DS-11 form?",
            answer: [
              "PDF Guru provides a fillable DS 11 form (2023 version). You don't need to upload anything — just click GET FORM, and it will open automatically. You can also download the DS 11 passport form from the official website of the US Department of State. Another option is to visit your local passport acceptance facility, where they usually have copies of the form available. Additionally, you can also request a fillable DS-11 by mail by contacting the National Passport Information Center."
            ]
          },
          {
            question: "Can I submit my DS-11 form by mail?",
            answer: [
              "No, the DS-11 form can't be submitted by mail. The US Department of State generally requires you to apply in person at a passport acceptance facility to submit it. Digital submission is also not possible. However, renewal forms like the DS-82 can be mailed in some cases."
            ]
          },
          {
            question: "Can I sign the DS-11 form electronically?",
            answer: [
              "No, for a new passport application, DS-11 form needs to be signed using a pen. Do not use our PDF filler to add an electronic signature to the PDF file. You need to print the passport application form PDF, apply in person, and only sign under the instructions of an authorized agent at an acceptance facility."
            ]
          },
          {
            question: "Can I print my passport application in black and white?",
            answer: [
              "Yes, you can print the form DS-11 in black and white. As long as the printed application is clear and legible, it will be accepted by the authorities. Whether you choose black and white or color printing, accuracy and attention to detail are crucial when you apply for a passport."
            ]
          },
        ],
      },
      fag: {
        question: 'How to edit a DS 11 form?',
        answer:
          '1. Open our DS-11 form filler and use it to edit PDF. 2. Enter your personal and contact information in the fillable form DS-11. 3. Add optional details such as your emergency contact information. 4. Add details of any previous passports issued to you. 5. Enter your travel plans and purpose for needing a U.S. passport. 6. Provide parent or guardian information if applying for a minor.',
      },
      fag1: {
        question: 'How to fill out a DS 11 form online?',
        answer:
          '1. Click the Fill Form button under this section. 2. In the Editor window that opens, add all the necessary details 3. Do not complete or sign specific sections of the DS-11 form that require in-person actions or signature. 4. Download your DS-11 passport form as a PDF',
      },
  };
};
